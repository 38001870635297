import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonStatefulModalProps,
  IToolbarButtonType,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { memo, useEffect, useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import {
  Button,
  Header,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { ToolbarItemId, ToolbarMode } from "../../../constants/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { useAppStreamApplications } from "../../../hooks/useAppStreamApplications";
import { AppStreamSdkProps } from "../../../types/Toolbar";

interface ApplicationButtonProps {
  appStreamSdk: AppStreamSDK;
  label: string;
  applicationName: string;
  iconUrl: string;
}
const ApplicationButton: React.FC<ApplicationButtonProps> = ({
  appStreamSdk,
  label,
  applicationName,
  iconUrl,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      key={applicationName}
      disabled={loading}
      onClick={() => {
        setLoading(true);
        appStreamSdk.launchApp(applicationName);
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }}
    >
      <SpaceBetween direction="horizontal" size="xxxs">
        {loading ? (
          <Spinner size="big" variant="disabled" />
        ) : (
          <img className="application icon" src={iconUrl} />
        )}
        <span className="application label">{label}</span>
      </SpaceBetween>
    </Button>
  );
};

const ApplicationsToolbarItemActiveContent = (
  toolbarState: string,
  { appStreamSdk }: AppStreamSdkProps
): JSX.Element => {
  const applications = useAppStreamApplications((store) => store.applications);
  const setApplications = useAppStreamApplications(
    (store) => store.setApplications
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    appStreamSdk
      .getApplications()
      .then(setApplications)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <SpaceBetween size={"s"}>
      {toolbarState === ToolbarMode.Floating && (
        <Header variant={"h2"}>
          <Trans i18nKey={"toolbar.item.application.content.header"} />
        </Header>
      )}
      {loading ? (
        <Spinner size="large" />
      ) : (
        <SpaceBetween direction={"horizontal"} size={"xl"}>
          {applications.map((application) => (
            <ApplicationButton
              label={application.displayName ?? application.name}
              applicationName={application.name}
              iconUrl={application.iconURL}
              appStreamSdk={appStreamSdk}
            />
          ))}
        </SpaceBetween>
      )}
    </SpaceBetween>
  );
};

const ApplicationIcon = (): JSX.Element => {
  return (
    <Icon
      svg={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
        >
          <rect width="6" height="6" x="4" y="4" rx="1" />
          <rect width="6" height="6" x="14" y="4" rx="1" />
          <rect width="6" height="6" x="4" y="14" rx="1" />
          <path d="M14.5 17h5M17 19.5v-5" />
        </svg>
      }
    />
  );
};

export const useApplications = (
  appStreamSdk: AppStreamSDK,
  toolbarState: ToolbarMode
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
  const { t } = useTranslation();

  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      activeStateContentType: IToolbarButtonActiveContentType.MODAL,
      label: t("toolbar.item.application.label"),
      icon: <ApplicationIcon data-testid={"application-icon"} />,
      onClick: () => {},
      ActiveStateContent: memo(() =>
        ApplicationsToolbarItemActiveContent(toolbarState, { appStreamSdk })
      ),
      ariaLabelCloseModal: t(
        "toolbar.item.application.content.close.ariaLabel"
      ),
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.APPLICATIONS,
    label: t("toolbar.item.application.label"),
    ariaLabel: t("toolbar.item.application.ariaLabel"),
    icon: <ApplicationIcon />,
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t(
          "toolbar.item.application.content.close.ariaLabel"
        )}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        {ApplicationsToolbarItemActiveContent(toolbarState, { appStreamSdk })}
      </ToolbarContainerItemContent>
    )),
  };
};
