import { WSW_DOMAIN_SUFFIX, WSW_FIPS_DOMAIN_SUFFIX } from "../constants/domain";

/**
 * Evaluates if the current domain is custom domain or follows our default domain
 * naming convention: `<uuid>.workspaces-web(-fips).com`
 * @returns true if custom domain, false otherwise, throws exception if hostname
 * is empty
 */
export function isCustomDomain(): boolean {
  const hostname: string = window.location.hostname;
  if (
    hostname.endsWith(WSW_DOMAIN_SUFFIX) ||
    hostname.endsWith(WSW_FIPS_DOMAIN_SUFFIX)
  ) {
    return false;
  } else {
    return true;
  }
}

/**
 * Checks the current web app domain to evaluate the portal parent domain. This
 * domain allows users to create cookies which can be accessed by different
 * portals.
 *
 * This functions by making use of WSW's `<uuid>.workspaces-web(-fips).com`
 * domain convention.
 * @returns domain for cross portal cookie or hostname if custom Domain
 */
export function getCrossPortalParentCookieDomain(): string {
  const hostname: string = window.location.hostname;
  if (hostname.endsWith(WSW_DOMAIN_SUFFIX)) {
    return WSW_DOMAIN_SUFFIX;
  } else if (hostname.endsWith(WSW_FIPS_DOMAIN_SUFFIX)) {
    return WSW_FIPS_DOMAIN_SUFFIX;
  } else {
    return hostname;
  }
}
