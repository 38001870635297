import { Box } from "@cloudscape-design/components";
import React from "react";

interface ValueWithLabelProps {
  label: React.ReactNode;
  children?: React.ReactNode;
  valueTestId?: string;
}

export const ValueWithLabel = ({
  label,
  children,
  valueTestId,
}: ValueWithLabelProps): JSX.Element => {
  return (
    <div>
      <Box variant="awsui-key-label">
        <label>{label}</label>
      </Box>
      <Box
        margin={{ left: "xxxs" }}
        color="text-body-secondary"
        fontWeight="light"
      >
        <div data-testid={valueTestId}>{children}</div>
      </Box>
    </div>
  );
};
