import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Header,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import { ValueWithLabel } from "../../ValueWithLabel";
import { getDeviceBrowser, isMacOS } from "../../../../utils/userAgentUtils";
import React from "react";
import { LinkToPreference } from "../../LinkTo";
import {
  ToolbarPreferenceTabId,
  USER_GUIDE_URL,
} from "../../../../constants/Toolbar";
import {
  FirefoxPermissionIcon,
  ChromiumPopupBlockedIcon,
  PermissionIcon,
} from "../../../icon";
import {
  useToolbarTutorialStore,
  useToolbarContentItemIdStore,
} from "@amzn/aws-euc-ui";

const LearnMoreLink = () => (
  <Link external href={USER_GUIDE_URL}>
    <Trans i18nKey={"learnMore.link"} />
  </Link>
);

interface OptionalFnqItem {
  condition?: boolean;
  question: React.ReactNode;
  answer: React.ReactNode;
}

/**
 * Profile - Help tab content
 */
export const HelpTabContent = (): JSX.Element => {
  const { t } = useTranslation();
  const { setShowTutorial, setTutorialCompleted } = useToolbarTutorialStore();
  const clearExpandedItemId = useToolbarContentItemIdStore(
    (store) => store.clearExpandedItemId
  );

  const fnQ: OptionalFnqItem[] = [
    {
      question: t("toolbar.item.user.content.tab.help.question.permission"),
      answer: (() => {
        switch (getDeviceBrowser()) {
          case "Chrome":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.permission.chrome"
                }
              >
                {[<PermissionIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Firefox":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.permission.firefox"
                }
              >
                {[<FirefoxPermissionIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Safari":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.permission.safari"
                }
              >
                {[<LearnMoreLink />]}
              </Trans>
            );
          case "Edge":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.permission.edge"
                }
              >
                {[<PermissionIcon />, <LearnMoreLink />]}
              </Trans>
            );
        }
      })(),
    },
    {
      question: t("toolbar.item.user.content.tab.help.question.dualMonitor"),
      answer: (() => {
        switch (getDeviceBrowser()) {
          case "Chrome":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.dualMonitor.chrome"
                }
              >
                {[<ChromiumPopupBlockedIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Firefox":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.dualMonitor.firefox"
                }
              >
                {[<FirefoxPermissionIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Safari":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.dualMonitor.safari"
                }
              >
                {[<LearnMoreLink />]}
              </Trans>
            );
          case "Edge":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.dualMonitor.edge"
                }
              >
                {[<ChromiumPopupBlockedIcon />, <LearnMoreLink />]}
              </Trans>
            );
        }
      })(),
    },
    {
      question: t("toolbar.item.user.content.tab.help.question.fileDownload"),
      answer: (() => {
        switch (getDeviceBrowser()) {
          case "Chrome":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.fileDownload.chrome"
                }
              >
                {[<ChromiumPopupBlockedIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Firefox":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.fileDownload.firefox"
                }
              >
                {[<FirefoxPermissionIcon />, <LearnMoreLink />]}
              </Trans>
            );
          case "Safari":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.fileDownload.safari"
                }
              >
                {[<LearnMoreLink />]}
              </Trans>
            );
          case "Edge":
            return (
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.answer.fileDownload.edge"
                }
              >
                {[<ChromiumPopupBlockedIcon />, <LearnMoreLink />]}
              </Trans>
            );
        }
      })(),
    },
    {
      condition: isMacOS(),
      question: t(
        "toolbar.item.user.content.tab.help.question.enableCopyAndPaste.macOs"
      ),
      answer: (
        <Trans
          i18nKey={
            "toolbar.item.user.content.tab.help.answer.enableCopyAndPaste.macOs"
          }
        >
          {[
            // command key preferences
            <LinkToPreference tabId={ToolbarPreferenceTabId.KEYBOARD} />,
          ]}
        </Trans>
      ),
    },
  ];

  return (
    <SpaceBetween size={"m"}>
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="s">
            <Button
              onClick={() => {
                // We should collapse the help section when user clicks “launch tutorial” button to make hotspot in the right position
                clearExpandedItemId();
                setShowTutorial(true);
                setTutorialCompleted(false);
              }}
            >
              <Trans
                i18nKey={
                  "toolbar.item.user.content.tab.help.button.launchTutorial"
                }
              />
            </Button>
            <Button
              href={USER_GUIDE_URL}
              iconAlign="right"
              iconName="external"
              target="_blank"
            >
              <Trans
                i18nKey={"toolbar.item.user.content.tab.help.button.userGuide"}
              />
            </Button>
          </SpaceBetween>
        }
      >
        <Trans i18nKey={"toolbar.item.user.content.tab.help.title"} />
      </Header>
      <SpaceBetween size={"l"}>
        {fnQ
          .filter(({ condition }) => condition !== false)
          .map(({ question, answer }) => (
            <ValueWithLabel label={question}>{answer}</ValueWithLabel>
          ))}
      </SpaceBetween>
    </SpaceBetween>
  );
};
