import { useEffect } from "react";
import { useSessionDataStore } from "./useSessionDataStore";
import { shallow } from "zustand/shallow";

export const useMicrophoneTimer = (microphoneEnabled: boolean): void => {
  const {
    addMicrophoneEnabledDuration,
    setMicrophoneLastEnabledTime,
    sessionStartTime,
  } = useSessionDataStore(
    (store) => ({
      addMicrophoneEnabledDuration: store.addMicrophoneEnabledDuration,
      setMicrophoneLastEnabledTime: store.setMicrophoneLastEnabledTime,
      sessionStartTime: store.sessionStartTime,
    }),
    shallow
  );

  useEffect(() => {
    if (sessionStartTime !== undefined) {
      if (microphoneEnabled) {
        const microphoneEnabledTimestamp = performance.now();
        setMicrophoneLastEnabledTime(microphoneEnabledTimestamp);
      } else {
        const microphoneDisabledTimestamp = performance.now();
        const microphoneLastEnabledTime = useSessionDataStore.getState()
          .microphoneLastEnabledTime;
        if (microphoneLastEnabledTime !== undefined) {
          addMicrophoneEnabledDuration(
            microphoneDisabledTimestamp - microphoneLastEnabledTime
          );
        }
      }
    }
  }, [microphoneEnabled, sessionStartTime]);
};
