import { useState, useEffect } from "react";
import {
  shouldDisplayWebcamWarning,
  setBannerDeliveryWebcamWarningFlag,
} from "../utils/bannerUtils";

const useDisplayWebcamWarningFlag = () => {
  const [displayWebcamWarningFlag, setDisplayWebcamWarningFlag] = useState(
    false
  );

  const getCookie = () => {
    return shouldDisplayWebcamWarning();
  };

  useEffect(() => {
    setDisplayWebcamWarningFlag(getCookie());
  });

  const updateDisplayWebcamWarningFlag = (value: boolean) => {
    setBannerDeliveryWebcamWarningFlag(value);
    setDisplayWebcamWarningFlag(getCookie());
  };

  return [displayWebcamWarningFlag, updateDisplayWebcamWarningFlag] as const;
};

export default useDisplayWebcamWarningFlag;
