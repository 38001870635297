import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { CopyButton } from "../../general/CopyButton";
import { Trans, useTranslation } from "react-i18next";

interface ErrorModalProp {
  title: string;
  message: string | JSX.Element;
  requestId?: string;
  onDismiss: () => void;
}

/**
 * Error message modal for pre session errors.
 */
const ErrorModal = (props: ErrorModalProp) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(true);

  return (
    <Modal
      onDismiss={() => {
        setVisible(false);
        props.onDismiss();
      }}
      visible={visible}
      footer={
        <Box float="right">
          <Button
            variant="link"
            onClick={() => {
              setVisible(false);
              props.onDismiss();
            }}
          >
            <Trans i18nKey={"euc.preSession.error.modal.close.button.label"} />
          </Button>
        </Box>
      }
      header={props.title}
    >
      <SpaceBetween size={"l"}>
        <Box>{props.message}</Box>
        {props.requestId && (
          <SpaceBetween size={"xxs"}>
            <Box variant="awsui-key-label">
              <Trans i18nKey={"euc.preSession.error.modal.requestID.title"} />
            </Box>
            <CopyButton
              copyText={props.requestId}
              successPopoverText={t(
                "euc.startStreaming.requestId.copy.popover.success.message"
              )}
              errorPopoverText={t(
                "euc.startStreaming.requestId.copy.popover.failed.message"
              )}
              copyIconAriaLabel={t(
                "euc.startStreaming.requestId.copy.button.ariaLabel"
              )}
            >
              {props.requestId}
            </CopyButton>
          </SpaceBetween>
        )}
      </SpaceBetween>
    </Modal>
  );
};

export default ErrorModal;
