// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import PropTypes from "prop-types";
import { Alert, AlertProps } from "@cloudscape-design/components";
import { ERROR_STATE } from "../../../../constants";
import "./style.css";

interface Props {
  type: ERROR_STATE;
  header?: string;
  message: string;
  dismissible?: boolean;
  onDismissErrorAlert: (event: CustomEvent) => void;
  visible?: boolean;
}

export const EUCErrorAlert = (props: Props): JSX.Element => {
  const {
    type,
    header,
    message,
    dismissible,
    onDismissErrorAlert,
    visible,
  } = props;

  return (
    visible && (
      <div className="euc-alert-container">
        <Alert
          data-id="errorAlert"
          type={type as AlertProps.Type}
          header={header}
          dismissible={dismissible ?? true}
          onDismiss={onDismissErrorAlert}
        >
          {message}
        </Alert>
      </div>
    )
  );
};

EUCErrorAlert.propTypes = {
  type: PropTypes.string.isRequired,
  header: PropTypes.string,
  message: PropTypes.string.isRequired,
  onDismissErrorAlert: PropTypes.func.isRequired,
};
