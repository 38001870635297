import { AWSCShortbread } from "@amzn/shortbread";
import { CookieCategory } from "../../enums/cookieCategory";
import log from "../../logging";
import Cookies from "js-cookie";

/*
 * Shortbread Integration for EU Cookie compliance: https://w.amazon.com/bin/view/Shortbread/usage
 *
 * It will only be invoked when shortbread's geo-ip location detects traffic from EU.
 * For testing, use AnyConnect VPN and connect to an EU endpoint such as 'Frankfurt, DE', and
 */

class CookieConsentManager {
  private shortbread: AWSCShortbread;
  private categoryMap: Map<string, CookieCategory> = new Map([
    ["essential", CookieCategory.Essential],
    ["performance", CookieCategory.Performance],
    ["functional", CookieCategory.Functional],
    ["advertising", CookieCategory.Advertising],
  ]);

  /** PRIVATE INTERFACES */

  private getConsentCookieList = () => {
    const consentCookie = this.shortbread.getConsentCookie();
    if (!consentCookie) {
      return [];
    }
    return Object.keys(consentCookie)
      .filter((category) => consentCookie[category])
      .map((c) => this.categoryMap.get(c));
  };

  /** PUBLIC INTERFACES */

  public constructor(shortbreadInstance: AWSCShortbread) {
    this.shortbread = shortbreadInstance;
  }

  public checkForCookieConsent = () => {
    this.shortbread.checkForCookieConsent();
  };

  public customizeCookies = () => {
    this.shortbread.customizeCookies();
  };

  public hasCookieNameConsent = (cookieKey: string) => {
    return this.shortbread.hasConsent(cookieKey);
  };

  public hasCookieCategoryConsent = (cookieCategory: CookieCategory) => {
    if (!this.isCookiesSet()) {
      return false;
    }
    return this.getConsentCookieList().includes(cookieCategory);
  };

  public isCookiesSet = () => {
    const consentCookie = this.shortbread.getConsentCookie();
    return !!consentCookie;
  };

  /************ LOCAL STORAGE WRAPPERS ****************/

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * sets the cookie value in the local storage.
   * @param {string} key - Cookie name
   * @param {string} value - cookie value
   */
  public setLocalStorage(key: string, value: string) {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        localStorage.setItem(key, value);
      } else {
        log.warn(`Set cookie consent denied for key: ${key}`);
      }
    } catch (e) {
      log.warn(`Set local storage failed for key: ${key}`);
    }
  }

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * gets the cookie value from local storage.
   * @param  {string} key - Local storage key to get
   * @returns {string} - cookie value | undefined if cookie does not exist or is inaccessible
   */
  public getLocalStorage(key: string): string | undefined {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        return localStorage.getItem(key);
      } else {
        log.warn(`Get cookie consent denied for key: ${key}`);
        return undefined;
      }
    } catch (e) {
      log.warn(`Get local storage failed for key: ${key}`);
      return undefined;
    }
  }

  /**
   * Remove the provided cookie from local storage. The provided key must be
   * managed by shortbread.
   * @param key local storage cookie to remove
   */
  public removeLocalStorage = (key: string) => {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        localStorage.removeItem(key);
        return true;
      } else {
        log.warn(`Remove cookie consent denied for key: ${key}`);
        return false;
      }
    } catch (e) {
      log.warn(`Exception while deleting key: ${key}`);
      return false;
    }
  };

  /************ COOKIE STORAGE WRAPPERS ****************/

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * sets the cookie value in the cookie store.
   * @param {string} key - Cookie name
   * @param {string} value - cookie value
   * @param options
   */
  public setCookieStorage(
    key: string,
    value: string,
    options?: Cookies.CookieAttributes
  ) {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        Cookies.set(key, value, options);
      } else {
        log.warn(`Set cookie consent denied for key: ${key}`);
      }
    } catch (e) {
      log.warn(`Set cookie storage failed for key: ${key}`);
    }
  }

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * gets the cookie value from cookie storage.
   * @param  {string} key - cookie storage key to get
   * @returns {string} - cookie value | undefined if cookie does not exist or is inaccessible
   */
  public getCookieStorage(key: string): string | undefined {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        return Cookies.get(key);
      } else {
        log.warn(`Get cookie consent denied for key: ${key}`);
        return undefined;
      }
    } catch (e) {
      log.warn(`Get cookie storage failed for key: ${key}`);
      return undefined;
    }
  }

  /**
   * Remove the provided cookie from cookie storage. The provided key must be
   * managed by shortbread.
   * Cookie cleanup depends on correct options tied to the cookie to be passed.
   * If incorrect options are passed, cookie will not be removed.
   * @param key cookie to remove
   * @param options
   */
  public removeCookieStorage = (
    key: string,
    options?: Cookies.CookieAttributes
  ) => {
    const hasConsent = this.hasCookieNameConsent(key);
    if (hasConsent) {
      // Try to cleanup cookie, this will fail if the cookie was created
      // with custom domain
      Cookies.remove(key, options);
    } else {
      log.warn(`Remove cookie consent denied for key: ${key}`);
    }
  };

  /************ SESSION STORAGE WRAPPERS ****************/

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * sets the cookie value in the session storage.
   * @param {string} key - Cookie name
   * @param {string} value - cookie value
   */
  public setSessionStorage(key: string, value: string) {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        sessionStorage.setItem(key, value);
      } else {
        log.warn(`Set cookie consent denied for key: ${key}`);
      }
    } catch (e) {
      log.warn(`Set session storage failed for key: ${key}`);
    }
  }

  /**
   * Checks if the exact cookie name is validated based of the type from the
   * registry. If the cookie type is active and the name is present in registry,
   * gets the cookie value from session storage.
   * @param  {string} key - Session storage key to get
   * @returns {string} - cookie value | undefined if cookie does not exist or is inaccessible
   */
  public getSessionStorage(key: string): string | undefined {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        return sessionStorage.getItem(key);
      } else {
        log.warn(`Get cookie consent denied for key: ${key}`);
        return undefined;
      }
    } catch (e) {
      log.warn(`Get session storage failed for key: ${key}`);
      return undefined;
    }
  }

  /**
   * Remove the provided cookie from session storage. The provided key must be
   * managed by shortbread.
   * @param key session storage cookie to remove
   */
  public removeSessionStorage = (key: string) => {
    try {
      const hasConsent = this.hasCookieNameConsent(key);
      if (hasConsent) {
        sessionStorage.removeItem(key);
        return true;
      } else {
        log.warn(`Remove cookie consent denied for key: ${key}`);
        return false;
      }
    } catch (e) {
      log.warn(`Exception while deleting key: ${key}`);
      return false;
    }
  };
}

export default CookieConsentManager;
