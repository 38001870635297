// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import { Auth } from "aws-amplify";

import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import { Button } from "@cloudscape-design/components";
import "./style.css";
import log from "../../../../logging";

interface Props {
  hasIdentityProvider: boolean;
}

const EUCAuthButton = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { hasIdentityProvider } = props;
  const onSignIn = () => {
    log.logMessage("SignIn button clicked.");
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  };

  return (
    <Button
      data-testid={"euc-sign-in-button"}
      onClick={onSignIn}
      disabled={!hasIdentityProvider}
      variant="primary"
    >
      <span className="euc-auth-button-span-style" id="span-text">
        {t("login.button.label")}
      </span>
    </Button>
  );
};

export default EUCAuthButton;
