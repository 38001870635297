import create from "zustand";
import { AppStreamEmbedConstant } from "../constants";
import {
  DEFAULT_VISUAL_MODE,
  DEFAULT_TOOLBAR_MODE,
  ToolbarMode,
} from "../constants/Toolbar";
import { Mode } from "@cloudscape-design/global-styles";
import {
  getDisplayResolutionFromCookie,
  getShowFunctionKeysFromCookie,
  getStreamingModeFromCookie,
  getToolbarModeFromCookie,
  getUseCommandAsControlKeyFromCookie,
  getUseOptionKeyAsAltKeyFromCookie,
  getVisualModeFromCookie,
  setDisplayResolutionForCookie,
  setShowFunctionKeysForCookie,
  setStreamingModeForCookie,
  setToolbarModeForCookie,
  setUseCommandAsControlKeyForCookie,
  setUseOptionKeyAsAltKeyForCookie,
  setVisualModeForCookie,
} from "../utils/toolbarSettingsUtils";

interface UseToolbarPreferenceStoreState {
  visualMode: Mode;
  toolbarMode: ToolbarMode;
  streamingMode: AppStreamEmbedConstant.StreamingMode;
  displayResolution: AppStreamEmbedConstant.Resolutions;
  showFunctionKeysToolbarItem: boolean;
  useOptionKeyAsAltKey: boolean;
  useCommandAsControlKey: boolean;
  microphoneEnabled: boolean;
  webcamEnabled: boolean;
  setVisualMode: (mode: Mode) => void;
  setToolbarMode: (toolbarMode: ToolbarMode) => void;
  setStreamingMode: (
    streamingMode: AppStreamEmbedConstant.StreamingMode
  ) => void;
  setDisplayResolution: (
    displayResolution: AppStreamEmbedConstant.Resolutions
  ) => void;
  setUseOptionKeyAsAltKey: (value: boolean) => void;
  setUseCommandAsControlKey: (value: boolean) => void;
  setShowFunctionKeysToolbarItem: (value: boolean) => void;
  setMicrophoneEnabled: (value: boolean) => void;
  setWebcamEnabled: (value: boolean) => void;
}

/**
 * Store for managing the preference values. Set value from cookie if cookie exists, else, set default values
 */
export const useToolbarPreferenceStore = create<UseToolbarPreferenceStoreState>(
  (set) => ({
    visualMode: getVisualModeFromCookie() || DEFAULT_VISUAL_MODE,
    toolbarMode: getToolbarModeFromCookie() || DEFAULT_TOOLBAR_MODE,
    streamingMode:
      getStreamingModeFromCookie() ||
      AppStreamEmbedConstant.StreamingMode.BEST_RESPONSIVENESS,
    displayResolution:
      getDisplayResolutionFromCookie() ||
      AppStreamEmbedConstant.Resolutions.MATCH_LOCAL_DISPLAY,
    showFunctionKeysToolbarItem: getShowFunctionKeysFromCookie() === true,
    useOptionKeyAsAltKey: getUseOptionKeyAsAltKeyFromCookie() === true,
    useCommandAsControlKey: getUseCommandAsControlKeyFromCookie() === true,
    microphoneEnabled: false,
    webcamEnabled: false,
    setVisualMode: (visualMode) => {
      set((state) => ({
        ...state,
        visualMode,
      }));
      setVisualModeForCookie(visualMode);
    },
    setToolbarMode: (toolbarMode) => {
      set((state) => ({
        ...state,
        toolbarMode,
      }));
      setToolbarModeForCookie(toolbarMode);
    },
    setStreamingMode: (streamingMode) => {
      set((state) => ({
        ...state,
        streamingMode,
      }));
      setStreamingModeForCookie(streamingMode);
    },
    setDisplayResolution: (displayResolution) => {
      set((state) => ({
        ...state,
        displayResolution,
      }));
      setDisplayResolutionForCookie(displayResolution);
    },
    setUseOptionKeyAsAltKey: (value) => {
      set((state) => ({
        ...state,
        useOptionKeyAsAltKey: value,
      }));
      setUseOptionKeyAsAltKeyForCookie(value);
    },
    setUseCommandAsControlKey: (value) => {
      set((state) => ({
        ...state,
        useCommandAsControlKey: value,
      }));
      setUseCommandAsControlKeyForCookie(value);
    },
    setShowFunctionKeysToolbarItem: (value) => {
      set((state) => ({
        ...state,
        showFunctionKeysToolbarItem: value,
      }));
      setShowFunctionKeysForCookie(value);
    },
    setMicrophoneEnabled: (value) =>
      set((state) => ({
        ...state,
        microphoneEnabled: value,
      })),
    setWebcamEnabled: (value) =>
      set((state) => ({
        ...state,
        webcamEnabled: value,
      })),
  })
);
