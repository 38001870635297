import { Mode } from "@cloudscape-design/global-styles";

export const DEFAULT_VISUAL_MODE: Mode = Mode.Dark;

export enum ToolbarMode {
  Floating = "floating",
  Docked = "docked",
}

export const DEFAULT_TOOLBAR_MODE: ToolbarMode = ToolbarMode.Floating;

/**
 * We can't force the toolbar items type. So it would be
 * better to use enum than union types to use the values
 */
export enum ToolbarItemId {
  APPLICATIONS = "_wsw_toolbar_applications_item",
  WINDOWS = "_wsw_toolbar_windows_item",
  FILES = "_wsw_toolbar_files_item",
  CLIPBOARD = "_wsw_toolbar_clipboard_item",
  FUNCTIONS = "_wsw_toolbar_functions_item",
  PREFERENCES = "_wsw_toolbar_preferences_item",
  PROFILE = "_wsw_toolbar_profile_item",
  NOTIFICATIONS = "_wsw_toolbar_notifications_item",
}

/**
 * We can't force the type of Tab items id. So it would be
 * better to use enum than union types to use the values
 */
export enum ToolbarPreferenceTabId {
  GENERAL = "_wsw_preference_tab_general",
  KEYBOARD = "_wsw_preference_tab_keyboard",
  AUDIO_VIDEO = "_wsw_preference_tab_audio_video",
}
export enum ToolbarProfileTabId {
  SESSION_PERFORMANCE = "_wsw_profile_tab_session_performance",
  FEEDBACK = "_wsw_profile_tab_feedback",
  HELP = "_wsw_profile_tab_help",
  ABOUT = "_wsw_profile_tab_about",
}

export const INITIAL_FILE_EXPLORER_PATH = "/TemporaryFiles";

/**
 * We can't force the function keys id type. So it would be
 * better to use enum than union types to use the values
 */
export enum AdditionalKeyId {
  SELECT_ALL = "_fn_key_select_all",
  COPY = "_fn_key_copy",
  PASTE = "_fn_key_paste",
  CUT = "_fn_key_cut",
}

// 0 <= seconds <= 60
export const MAX_METRIC_COUNT = 61;

export const SESSION_STATE_STARTED_TIMEOUT = 60;

export const MAC_OS_COMMAND_KEY_ICON = "⌘";
export const MAC_OS_CONTROL_KEY_ICON = "^";

export const DUAL_MONITOR_PERMISSION_BLOCKED_ERROR_MESSAGE =
  "Multi monitor window failed to open";

export const FEEDBACK_EMAIL_ADDRESS = "workspaces-web-feedback@amazon.com";
export const REBRANDED_FEEDBACK_EMAIL_ADDRESS =
  "workspaces-secure-browser-feedback@amazon.com";

export const USER_GUIDE_URL =
  "https://docs.aws.amazon.com/workspaces-web/latest/adminguide/user-guide";

export enum NotificationId {
  DUAL_MONITOR_ERROR = "_wsw_notification_dual_monitor_error",
  DUAL_MONITOR_PERMISSION_ERROR = "_wsw_notification_dual_monitor_permission_error",
  EXIT_FULL_SCREEN_ERROR = "_wsw_notification_exit_full_screen_error",
  ENTER_FULL_SCREEN_ERROR = "_wsw_notification_enter_full_screen_error",
  MICROPHONE_NOT_ENABLED_ERROR = "_wsw_notification_microphone_not_enabled_error",
  MICROPHONE_NOT_DISABLED_ERROR = "_wsw_notification_microphone_not_disabled_error",
  COOKIE_SYNC_SUCCESS = "_wsw_notification_cookie_sync_success",
  COOKIE_SYNC_EXTENSION_ERROR = "_wsw_notification_cookie_sync_extension_error",
  COOKIE_SYNC_CONFIG_ERROR = "_wsw_notification_cookie_sync_config_error",
  COOKIE_SYNC_BROWSER_INCOMPATIBLE = "_wsw_notification_cookie_sync_browser_incompatible",
  DEEP_LINK_ACCESS_DENIED_ERROR = "_wsw_notification_deep_link_access_denied_error",
  DEEP_LINK_INTERNAL_SERVER_ERROR = "_wsw_notification_deep_link_internal_server_error",
  DEEP_LINK_LIMIT_EXCEEDED_ERROR = "_wsw_notification_deep_link_limit_exceeded_error",
  DEEP_LINK_INVALID_URL_ERROR = "_wsw_notification_deep_link_invalid_url_error",
  CAMERA_NOT_ENABLED_ERROR = "_wsw_notification_camera_not_enabled_error",
  CAMERA_NOT_DISABLED_ERROR = "_wsw_notification_camera_not_disabled_error",
}

export const DUAL_MONITOR_KEYBOARD_SHORTCUT_DELAY_MS = 2000;
