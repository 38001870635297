// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import { Registry } from "@amzn/shortbread/dist/shortbread";
import { ShortbreadCookieMetadata } from "../types/shortbreadCookie";
import { getCrossPortalParentCookieDomain } from "../utils/domainUtils";
import Cookies from "js-cookie";
import {
  DEEP_LINK_URL_LENGTH_MAX,
  DEEP_LINK_LIST_LENGTH_MAX,
  CLIENT_MESSAGE_COOKIE_NAMES,
} from "../constants/clientMessage";

/**
 * All Cookie Identifiers. Each cookie needs to be approved by legal. Please
 * see https://w.amazon.com/bin/view/CookieJar/Onboarding.
 *
 * @note Any updates to descriptions, names or sizes need to go through the
 * above mentioned validation process.
 */
export class CustomCookieIdentifier {
  /**
   * This cookie is a functional cookie and is used to make sure user only sees
   * the new user tutorial the first time they open the web app, and then set
   * the cookie to true when they finish the tutorial.
   */
  public static readonly TUTORIAL: ShortbreadCookieMetadata = {
    name: "wsw-tutorial-finish",
    sizeInBytes: 512,
  };
  /**
   * This cookie is an essential cookie which enables a specific language to be
   * used for our webapp which could be different from their default browser
   * language.
   */
  public static readonly WSW_LANGUAGE: ShortbreadCookieMetadata = {
    name: "wsw-language",
    sizeInBytes: 512,
  };
  /**
   * This cookie is a functional cookie and is used to store which warning /
   * non-critical error banners have been acknowledged
   */
  public static readonly BANNER_STATE: ShortbreadCookieMetadata = {
    name: "wsw-banner-state",
    sizeInBytes: 512,
  };
  /**
   * This cookie is an essential cookie and is used to store information
   * to manage experimental features for the portal.
   */
  public static readonly EXPERIMENTAL: ShortbreadCookieMetadata = {
    name: "wsw-experimental",
    sizeInBytes: 300,
  };
  /**
   * This cookie is an essential cookie and should be exclusively used
   * for maintaining session related state like streamingURL cache or session
   * specific essential information.
   */
  public static readonly SESSION_STATE: ShortbreadCookieMetadata = {
    name: "wsw-session-state",
    sizeInBytes: 4096,
  };
  /**
   * This cookie is a functional cookie and is used to store user preferred toolbar settings
   * if they have changed any from previous session.
   */
  public static readonly TOOLBAR_SETTINGS: ShortbreadCookieMetadata = {
    name: "wsw-toolbar-settings",
    sizeInBytes: 4096,
  };
  /**
   * This cookie is an essential cookie and is used to store the
   * DeepLink string in sessionStorage until it is passed to back-end
   */
  public static readonly DEEPLINK: ShortbreadCookieMetadata = {
    name: "wsw-deeplink",
    sizeInBytes: 2048,
  };
  /**
   * This cookie is an essential cookie and is used to store the list of deep
   * links which will be sent to back-end.
   */
  public static readonly DEEP_LINKS: ShortbreadCookieMetadata = {
    name: CLIENT_MESSAGE_COOKIE_NAMES.WSW_DEEP_LINKS,
    // Character in a valid URL should be ASCII which encoded size is one byte per character.
    // The length of array characters '[","]' needs to be counted.
    sizeInBytes:
      DEEP_LINK_URL_LENGTH_MAX * DEEP_LINK_LIST_LENGTH_MAX +
      DEEP_LINK_LIST_LENGTH_MAX * 4,
  };
}

/**
 * Cookie categorized by Legal Team. Only `essential` cookies are guaranteed
 * to be always accessible via shortbread wrappers.
 * Read: https://w.amazon.com/bin/view/Shortbread
 */
export const SHORTBREAD_COOKIE_REGISTRY: Registry = {
  "wsw-banner-state": { category: "functional" },
  "wsw-session-state": { category: "essential" },
  "wsw-experimental": { category: "essential" },
  "wsw-tutorial-finish": { category: "functional" },
  "wsw-language": { category: "essential" },
  "wsw-toolbar-settings": { category: "functional" },
  "wsw-deeplink": { category: "essential" },
  [CustomCookieIdentifier.DEEP_LINKS.name]: { category: "essential" },
};

/**
 * Returns Cookie options which sets the cookie scope to the current
 * domain.
 * - default expiration of 365 days
 * - Cookie is secure
 * - sameSite
 * is configured to be strict.
 */
export const DEFAULT_PORTAL_COOKIE_OPTIONS: Cookies.CookieAttributes = {
  domain: window.location.hostname,
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  secure: true,
  sameSite: "strict",
  path: "/",
};

/**
 * Returns Cookie options which sets the cookie scope to support cross portal
 * configuration access.
 * - The cookie domain will be set to `.workspaces-web.com` or
 * `.workspaces-web-fips.com` when cross domain capability is available. If
 * custom domain is in use, this is default to DEFAULT_PORTAL_COOKIE_OPTIONS.
 * - default expiration of 365 days
 * - Cookie is secure
 * - sameSite is configured to be strict.
 */
export const DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS: Cookies.CookieAttributes = {
  domain: getCrossPortalParentCookieDomain(),
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  secure: true,
  sameSite: "strict",
  path: "/",
};
