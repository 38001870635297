// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import { Button } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "react";
import { getPortalEndpoint, isLocalhost } from "../../../../configurations";

const EUCAuthButton = (): JSX.Element => {
  const { t } = useTranslation();

  const onClick = () => {
    const portalEndpoint = getPortalEndpoint();

    if (isLocalhost()) {
      window.location.href = `${window.location.origin}/authenticate${window.location.search}`;
    } else {
      window.location.href = `${portalEndpoint}/authenticate${window.location.search}`;
    }
  };

  const spanStyle: CSSProperties = {
    display: "inline-block",
    width: "220px",
    textAlign: "center",
  };

  return (
    <Button
      data-testid={"euc-sign-in-button"}
      onClick={onClick}
      variant="primary"
    >
      <span style={spanStyle} id="span-text">
        {t("login.button.label")}
      </span>
    </Button>
  );
};

export default EUCAuthButton;
