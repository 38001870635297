import { AppStreamSDK } from "../../../../utils/AppStreamSDK";
import {
  CustomHotspotIds,
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonStatefulModalProps,
  IToolbarButtonType,
  NotificationList,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
} from "@amzn/aws-euc-ui";
import React, { memo } from "react";
import { Header, Icon, Tabs } from "@cloudscape-design/components";
import { GeneralTabContent } from "./GeneralTabContent";
import {
  ToolbarItemId,
  ToolbarMode,
  ToolbarPreferenceTabId,
} from "../../../../constants/Toolbar";
import { KeyboardTabContent } from "./KeyboardTabContent";
import { useToolbarPreferenceTabIdStore } from "../../../../hooks/useToolbarPreferenceTabIdStore";
import { Trans, useTranslation } from "react-i18next";
import { usePreferenceNotificationStore } from "../../../../hooks/useNotificationStore";
import { AudioVideoTabContent } from "./AudioVideoTabContent";
import { isFeatureEnabled } from "../../../../configurations";

export const usePreferencesToolbarItem = (
  appStreamSdk: AppStreamSDK,
  toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
  const { t } = useTranslation();

  const activeTabId = useToolbarPreferenceTabIdStore((store) => store.activeId);
  const setActiveTabId = useToolbarPreferenceTabIdStore(
    (store) => store.setActiveId
  );
  const notificationList = usePreferenceNotificationStore(
    (store) => store.notificationList
  );
  const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
    "mediaDeviceSelector"
  );

  const PreferencesContent = () => {
    return (
      <>
        <NotificationList
          items={notificationList}
          ariaLabel={t("notification.list.ariaLabel")}
        />
        <Tabs
          onChange={({ detail }) => {
            setActiveTabId(detail.activeTabId as ToolbarPreferenceTabId);
          }}
          activeTabId={activeTabId}
          tabs={[
            {
              label: t("toolbar.item.preference.content.tab.general.label"),
              id: ToolbarPreferenceTabId.GENERAL,
              content: <GeneralTabContent appStreamSdk={appStreamSdk} />,
            },
            ...(isMediaDeviceSelectorFeatureEnabled
              ? [
                  {
                    label: t(
                      "toolbar.item.preference.content.tab.audioVideo.label"
                    ),
                    id: ToolbarPreferenceTabId.AUDIO_VIDEO,
                    content: (
                      <AudioVideoTabContent appStreamSdk={appStreamSdk} />
                    ),
                  },
                ]
              : []),
            {
              label: t("toolbar.item.preference.content.tab.keyboard.label"),
              id: ToolbarPreferenceTabId.KEYBOARD,
              content: <KeyboardTabContent appStreamSdk={appStreamSdk} />,
            },
          ]}
        />
      </>
    );
  };
  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      activeStateContentType: IToolbarButtonActiveContentType.MODAL,
      id: ToolbarItemId.PREFERENCES,
      label: t("toolbar.item.preference.label"),
      icon: <Icon name="settings" />,
      ActiveStateContent: memo(() => <PreferencesContent />),
      ariaLabelCloseModal: t("toolbar.item.preference.close.ariaLabel"),
      hotspotId: CustomHotspotIds.PREFERENCES,
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.PREFERENCES,
    label: t("toolbar.item.preference.label"),
    ariaLabel: t("toolbar.item.preference.ariaLabel"),
    icon: <Icon name="settings" />,
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t("toolbar.item.preference.close.ariaLabel")}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        <Header>
          <Trans i18nKey={"toolbar.item.preference.label"} />
        </Header>
        <PreferencesContent />
      </ToolbarContainerItemContent>
    )),
  };
};
