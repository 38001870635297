import { NotificationItem, NotificationType } from "@amzn/aws-euc-ui";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../../hooks/useNotificationStore";

interface useNotificationProps {
  removeNotification: (notificationId: string) => void;
  header: React.ReactNode;
  content?: React.ReactNode;
  type?: NotificationType;
  notificationId?: string;
}

/**
 * Create a notification item with the given header, content and type.
 * If the notificationId is not provided, a random uuid will be generated.
 */
export const useNotification = ({
  removeNotification,
  type = "error",
  header,
  content,
  notificationId,
}: useNotificationProps): NotificationItem => {
  const { t } = useTranslation();
  const id = notificationId ?? uuid();
  const baseError: NotificationItem = {
    statusIconAriaLabel: t("notification.error.statusIconAriaLabel"),
    dismissible: true,
    dismissAriaLabel: t("notification.dismissButton.ariaLabel"),
    id: id,
    onDismiss: () => removeNotification(id),
  };
  const notificationError: NotificationItem = {
    ...baseError,
    type: type,
    header: header,
    content: content,
  };
  return notificationError;
};

interface FloatingAndToolbarNotificationProps {
  header: React.ReactNode;
  content?: React.ReactNode;
  floatingContent?: React.ReactNode;
  toolbarContent?: React.ReactNode;
  removeNotification?: (notificationId: string) => void;
  type?: NotificationType;
  notificationId?: string;
}

/**
 * Create notifications for floating notification under the toolbar and
 * notification toolbar panel. The notifications would have the same header
 * and content if you don't specify the floatingContent or toolbarContent.
 * Use a unique notificationId to dedupe the notifications.
 */
export const useFloatingAndToolbarNotification = (
  props: FloatingAndToolbarNotificationProps
) => {
  const removeFloatingNotification = useNotificationStore(
    (store) => store.removeNotification
  );
  const removeToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.removeNotification
  );

  const floatingToolbarNotification = useNotification({
    header: props.header,
    content: props.floatingContent ?? props.content,
    removeNotification: (notificationId) => {
      props.removeNotification && props.removeNotification(notificationId);
      removeFloatingNotification(notificationId);
    },
    type: props.type,
    notificationId: props.notificationId,
  });

  const toolbarNotification = useNotification({
    header: props.header,
    content: props.toolbarContent ?? props.content,
    removeNotification: (notificationId) => {
      props.removeNotification && props.removeNotification(notificationId);
      removeToolbarNotification(notificationId);
      // Dismiss notification from tray will dismiss floating notification as well
      removeFloatingNotification(notificationId);
    },
    type: props.type,
    notificationId: props.notificationId,
  });

  return {
    floatingToolbarNotification,
    toolbarNotification,
  };
};
