import { useSessionMetricStore } from "./useSessionMetricStore";
import { useEffect, useMemo, useCallback } from "react";
import log, { METRIC_NAME } from "../logging";
import { getPortalConfig } from "../configurations";
import { useSessionDataStore } from "./useSessionDataStore";

const METRIC_PUBLISH_INTERVAL = 60000;

export const useEmitSessionPerformanceMetrics = (): void => {
  const portalArn = useMemo(() => getPortalConfig().portalArn, []);

  const emitMetricsCallback = useCallback(() => {
    const latestSessionMetric = useSessionMetricStore.getState().latestMetric;
    const sessionId = useSessionDataStore.getState().sessionID;

    if (!latestSessionMetric) {
      console.debug("No session performance metric");
      return;
    }
    log.publishNumericMetric(METRIC_NAME.SESSION_FPS, latestSessionMetric.fps, {
      PortalArn: portalArn,
      SessionId: sessionId,
    });
    log.publishNumericMetric(
      METRIC_NAME.SESSION_LATENCY,
      latestSessionMetric.latency,
      {
        PortalArn: portalArn,
        SessionId: sessionId,
      }
    );
  }, []);

  useEffect(() => {
    emitMetricsCallback(); // Emit the first metric immediately.
    const intervalId = setInterval(() => {
      emitMetricsCallback();
    }, METRIC_PUBLISH_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);
};
