import { useTranslation } from "react-i18next";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { IToolbarItemGroup } from "@amzn/aws-euc-ui";
import { useCamera } from "./useCamera";
import { useCameraDropdown } from "./useCameraDropdown";

export const useCameraWithDropdown = (
  appStreamEmbed: AppStreamSDK
): IToolbarItemGroup => {
  const { t } = useTranslation();
  const cameraButton = useCamera(appStreamEmbed);
  const cameraDropdownButton = useCameraDropdown(appStreamEmbed);
  return {
    groupItems: [cameraButton, cameraDropdownButton],
    label: t("toolbar.item.camera.label"),
  };
};
