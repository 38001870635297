// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { ErmineServerSideRenderingService } from "@amzn/ermine-ssr-sdk";
import { useEffect } from "react";
import browser from "webextension-polyfill";
import {
  DEFAULT_CLIENT_CONFIG,
  getEnvInfo,
  getPortalConfig,
} from "../../../../configurations";
import {
  GetCookieSynchronizationConfigurationRequest,
  GetCookieSynchronizationConfigurationResponse,
} from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import { AWSError } from "aws-sdk";
import log, { METRIC_NAME } from "../../../../logging";

interface Props {
  onCookieSyncConfigFetch: (
    allowlistFilter: browser.Cookies.GetAllDetailsType[],
    blocklistFilter: browser.Cookies.GetAllDetailsType[],
    metricName: METRIC_NAME
  ) => void;
  onCookieSyncConfigFetchError: (
    err: AWSError,
    metricName: METRIC_NAME
  ) => void;
}

const OidcCookieSyncConfigFetcher = (props: Props): JSX.Element | null => {
  const { onCookieSyncConfigFetch, onCookieSyncConfigFetchError } = props;
  const portalConfig = getPortalConfig();
  const portalArn = portalConfig.portalArn;
  const { region } = getEnvInfo();
  const endpoint = `${window.location.origin}`;

  useEffect(() => {
    const ermineService = new ErmineServerSideRenderingService({
      ...DEFAULT_CLIENT_CONFIG,
      region: region,
      endpoint: `${window.location.origin}`,
    });

    ermineService.makeUnauthenticatedRequest(
      "getCookieSynchronizationConfiguration",
      {
        portalArn,
      } as GetCookieSynchronizationConfigurationRequest,
      (err: AWSError, data: GetCookieSynchronizationConfigurationResponse) => {
        if (err) {
          log.error(
            `OidcCookieSyncConfigFetcher ${err.statusCode} | ${err.code}: ${err.message}`
          );
          onCookieSyncConfigFetchError(
            err,
            METRIC_NAME.OIDC_GET_COOKIE_SYNC_CONFIG
          );
        } else {
          const { cookieSynchronizationConfiguration } = data;
          onCookieSyncConfigFetch(
            cookieSynchronizationConfiguration?.allowlist,
            cookieSynchronizationConfiguration?.blocklist,
            METRIC_NAME.OIDC_GET_COOKIE_SYNC_CONFIG
          );
        }
      }
    );
  }, [
    endpoint,
    onCookieSyncConfigFetch,
    onCookieSyncConfigFetchError,
    portalArn,
    region,
  ]);

  return null;
};

export default OidcCookieSyncConfigFetcher;
