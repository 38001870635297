// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";

interface Props {
  onConfigured: () => void;
}

const Configurator = ({ onConfigured }: Props): JSX.Element | null => {
  useEffect(() => {
    onConfigured();
  }, [onConfigured]);

  return null;
};

export default Configurator;
