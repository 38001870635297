import {
  CopyAndPaste,
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonStatefulModalProps,
  IToolbarButtonType,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
} from "@amzn/aws-euc-ui";
import React, { memo, useEffect, useMemo, useState } from "react";
import { Icon, SpaceBetween } from "@cloudscape-design/components";
import { AppStreamEmbedConstant } from "../../../constants";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import {
  MAC_OS_COMMAND_KEY_ICON,
  MAC_OS_CONTROL_KEY_ICON,
  ToolbarItemId,
  ToolbarMode,
  ToolbarPreferenceTabId,
  ToolbarProfileTabId,
} from "../../../constants/Toolbar";
import { AppStreamSdkProps } from "../../../types/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { getDeviceBrowser, isMacOS } from "../../../utils/userAgentUtils";
import { LinkToPreference, LinkToProfile } from "../LinkTo";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import { useSessionDataStore } from "../../../hooks/useSessionDataStore";

const ClipboardContentDescription = (): JSX.Element => {
  const useCommandAsControlKey = useToolbarPreferenceStore(
    (store) => store.useCommandAsControlKey
  );
  const macOsCopyShortcut = useMemo(
    () =>
      useCommandAsControlKey
        ? `${MAC_OS_COMMAND_KEY_ICON}+ c`
        : `${MAC_OS_CONTROL_KEY_ICON}+ c`,
    [useCommandAsControlKey]
  );
  const macOsPasteShortcut = useMemo(
    () =>
      useCommandAsControlKey
        ? `${MAC_OS_COMMAND_KEY_ICON}+ v`
        : `${MAC_OS_CONTROL_KEY_ICON}+ v`,
    [useCommandAsControlKey]
  );

  switch (getDeviceBrowser()) {
    case "Chrome":
    case "Edge":
      return (
        <SpaceBetween size={"m"}>
          {isMacOS() ? (
            <Trans
              i18nKey={"toolbar.item.clipboard.content.description.macOS.main"}
            >
              {[
                // browser permission
                <LinkToProfile tabId={ToolbarProfileTabId.HELP} />,
                // command (⌘) key preferences
                <LinkToPreference tabId={ToolbarPreferenceTabId.KEYBOARD} />,
              ]}
            </Trans>
          ) : (
            <Trans
              i18nKey={
                "toolbar.item.clipboard.content.description.non.macOS.main"
              }
            >
              {[
                // browser permission
                <LinkToProfile tabId={ToolbarProfileTabId.HELP} />,
              ]}
            </Trans>
          )}
          <SpaceBetween size={"xxs"}>
            <Trans
              i18nKey={"toolbar.item.clipboard.content.description.copy"}
              values={{
                shortCut: isMacOS() ? macOsCopyShortcut : "CTRL+ c",
              }}
            >
              {[<strong />]}
            </Trans>
            <Trans
              i18nKey={"toolbar.item.clipboard.content.description.paste"}
              values={{
                shortCut: isMacOS() ? macOsPasteShortcut : "CTRL+ v",
              }}
            >
              {[<strong />]}
            </Trans>
          </SpaceBetween>
        </SpaceBetween>
      );
    default:
      return (
        <SpaceBetween size={"m"}>
          {isMacOS() && (
            <Trans
              i18nKey={
                "toolbar.item.clipboard.content.non-chromium.description.macOS"
              }
            >
              {[
                // Command (⌘) key preferences
                <LinkToPreference tabId={ToolbarPreferenceTabId.KEYBOARD} />,
              ]}
            </Trans>
          )}
          <Trans
            i18nKey={"toolbar.item.clipboard.content.non-chromium.step.main"}
          />
          <SpaceBetween size={"xxs"}>
            <ol>
              <li>
                <Trans
                  i18nKey={
                    "toolbar.item.clipboard.content.non-chromium.step.first"
                  }
                  values={{
                    shortCut: isMacOS()
                      ? `${MAC_OS_COMMAND_KEY_ICON}+ c`
                      : "CTRL+ c", // Local device shortcut
                  }}
                />
              </li>
              <li>
                <Trans
                  i18nKey={
                    "toolbar.item.clipboard.content.non-chromium.step.second"
                  }
                  values={{
                    shortCut: isMacOS()
                      ? `${MAC_OS_COMMAND_KEY_ICON}+ v`
                      : "CTRL+ v", // Local device shortcut
                  }}
                />
              </li>
              <li>
                <Trans
                  i18nKey={
                    "toolbar.item.clipboard.content.non-chromium.step.third"
                  }
                  values={{
                    shortCut: isMacOS() ? macOsPasteShortcut : "CTRL+ v",
                  }}
                />
              </li>
            </ol>
          </SpaceBetween>
        </SpaceBetween>
      );
  }
};

/**
 * To avoid unexpected and unnecessary renders, create and pass a
 * component to the ActiveStateContent property
 */
const ClipboardContent = (
  { close, appStreamSdk }: ToolbarActiveStateContentProps & AppStreamSdkProps,
  toolbarState: string
): JSX.Element => {
  const { t } = useTranslation();

  const copiedTextFromRemote = useSessionDataStore(
    (store) => store.copiedTextFromRemote
  );
  const [preview, setPreview] = useState("");
  useEffect(() => {
    setPreview(copiedTextFromRemote ?? "");
  }, [copiedTextFromRemote]);

  const {
    isCopyAllowed,
    isPasteAllowed,
  } = appStreamSdk.getUserInterfaceState();

  return (
    <CopyAndPaste
      title={
        toolbarState === ToolbarMode.Floating
          ? t("toolbar.item.clipboard.content.title")
          : undefined
      }
      description={<ClipboardContentDescription />}
      label={t("toolbar.item.clipboard.content.preview.label")}
      content={preview}
      placeholder={t("toolbar.item.clipboard.content.preview.placeholder")}
      disableCopyFromRemote={!isCopyAllowed}
      disablePasteToRemote={!isPasteAllowed}
      i18nStrings={{
        copyFromRemoteSuccess: t(
          "toolbar.item.clipboard.content.popover.copyFromRemote.success"
        ),
        copyFromRemoteFailed: t(
          "toolbar.item.clipboard.content.popover.copyFromRemote.failed"
        ),
        pasteToRemoteSuccess: t(
          "toolbar.item.clipboard.content.popover.pasteToRemote.success"
        ),
        pasteToRemoteFailed: t(
          "toolbar.item.clipboard.content.popover.pasteToRemote.failed"
        ),
        copyFromRemoteDisabled: t(
          "toolbar.item.clipboard.content.popover.copyFromRemote.disabled"
        ),
        pasteToRemoteDisabled: t(
          "toolbar.item.clipboard.content.popover.pasteToRemote.disabled"
        ),
        copyAndPasteDisabled: t(
          "toolbar.item.clipboard.content.popover.copyAndPaste.disabled"
        ),
      }}
      onCopyFromRemote={() => {
        return Promise.resolve(preview);
      }}
      onPasteToRemote={async (event) => {
        const clipboardText = event.clipboardData?.getData("text/plain");
        try {
          await appStreamSdk.performActionPromise(
            AppStreamEmbedConstant.METHOD_PASTE_TO_REMOTE,
            {
              clipboardText,
            }
          );
        } catch {
          return false;
        }

        setPreview(clipboardText ?? "");
        return true;
      }}
    />
  );
};

export const useClipboardToolbarItem = (
  appStreamSDK: AppStreamSDK,
  toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
  const { t } = useTranslation();

  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      label: t("toolbar.item.clipboard.label"),
      id: ToolbarItemId.CLIPBOARD,
      icon: <Icon data-testid="clipboard-icon" name="copy" />,
      activeStateContentType: IToolbarButtonActiveContentType.MODAL,
      ActiveStateContent: memo((props: ToolbarActiveStateContentProps) =>
        ClipboardContent(
          { close: props.close, appStreamSdk: appStreamSDK },
          toolbarState
        )
      ),
      ariaLabelCloseModal: t("toolbar.item.clipboard.content.close.ariaLabel"),
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.CLIPBOARD,
    label: t("toolbar.item.clipboard.label"),
    ariaLabel: t("toolbar.item.clipboard.ariaLabel"),
    icon: <Icon data-testid="clipboard-icon" name="copy" />,
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t(
          "toolbar.item.clipboard.content.close.ariaLabel"
        )}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        {ClipboardContent(
          { close: props.close, appStreamSdk: appStreamSDK },
          toolbarState
        )}
      </ToolbarContainerItemContent>
    )),
  };
};
