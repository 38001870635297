/* eslint-disable prettier/prettier */
import React, { useState } from "react";
import {
  Box,
  Button,
  ExpandableSection,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import "./ErrorModal.css";
import { Trans, useTranslation } from "react-i18next";
import { AppStreamSDK } from "../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../constants";
import { SessionErrorType } from "../../types/appStream";
import { useSessionDataStore } from "../../hooks/useSessionDataStore";
import { ValueWithLabel } from "../toolbar/ValueWithLabel";
import { CopyButton } from "../general/CopyButton";

interface ErrorModalProps {
  errorTitle: string;
  errorMessage: string | JSX.Element;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  dataTestId?: string;
  onDismiss?: () => void;
}

export const ErrorModal = ({
  errorTitle,
  errorMessage,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  dataTestId,
  onDismiss,
}: ErrorModalProps): JSX.Element => {
  const [visible, setVisible] = useState(true);
  const sessionId = useSessionDataStore((store) => store.sessionID);
  const { t } = useTranslation();

  return (
    <div className="error-modal">
      <Modal
        onDismiss={onDismiss ? onDismiss : () => setVisible(false)}
        visible={visible}
        data-testid={dataTestId}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={secondaryButtonOnClick}>
                {secondaryButtonText}
              </Button>
              <Button variant="primary" onClick={primaryButtonOnClick}>
                {primaryButtonText}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={errorTitle}
      >
        <SpaceBetween size={"s"}>
          {errorMessage}
          {sessionId && (
            <ExpandableSection
              variant="footer"
              data-testid={"expandable-section-sessionId"}
              headerAriaLabel={t(
                "appstream.embed.errorModal.expandableSection.header.ariaLabel"
              )}
              headerText={
                <Trans
                  i18nKey={
                    "appstream.embed.errorModal.expandableSection.header.label"
                  }
                />
              }
            >
              <SpaceBetween size={"s"}>
                <Box color="text-body-secondary">
                  {
                    <Trans
                      i18nKey={"appstream.embed.errorModal.sessionId.message"}
                    />
                  }
                </Box>
                <ValueWithLabel
                  label={t("appstream.embed.errorModal.sessionId.label")}
                >
                  <>
                    <CopyButton
                      copyText={sessionId}
                      successPopoverText={
                        <Trans
                          i18nKey={
                            "appstream.embed.errorModal.sessionId.copy.popover.success"
                          }
                        />
                      }
                      errorPopoverText={
                        <Trans
                          i18nKey={
                            "appstream.embed.errorModal.sessionId.copy.popover.failed"
                          }
                        />
                      }
                      copyIconAriaLabel={t(
                        "appstream.embed.errorModal.sessionId.copy.button.ariaLabel"
                      )}
                    />
                    {sessionId}
                  </>
                </ValueWithLabel>
              </SpaceBetween>
            </ExpandableSection>
          )}
        </SpaceBetween>
      </Modal>
    </div>
  );
};

interface appStreamSDKProps {
  appStreamSDK?: AppStreamSDK;
  onRedirectToLogin?: (redirectToLogin: boolean) => void;
}

export const ReserveSessionErrorModal = ({
  appStreamSDK,
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.reserveInstanceError.title")}
      errorMessage={t("appstream.embed.reserveInstanceError.message")}
      primaryButtonText={t("appstream.embed.reserveInstanceError.button")}
      primaryButtonOnClick={async () => {
        await appStreamSDK.performActionPromise(
          AppStreamEmbedConstant.METHOD_REFRESH_SESSION
        );
      }}
      secondaryButtonText={t("presession.returnToLogin.button")}
      secondaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="reserve-session-error-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

export const NoApplicationErrorModal = ({
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.noApplicationAvailable.title")}
      errorMessage={t("appstream.embed.noApplicationAvailable.message")}
      primaryButtonText={t("presession.returnToLogin.button")}
      primaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="no-application-error-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

export const DeployApplicationErrorModal = ({
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.deployAppError.title")}
      errorMessage={t("appstream.embed.deployAppError.message")}
      primaryButtonText={t("presession.returnToLogin.button")}
      primaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="deploy-application-error-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

export const DisconnectWarningModal = ({
  appStreamSDK,
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.disconnectAlert.title")}
      errorMessage={
        <>
        <Trans
          i18nKey={"appstream.embed.disconnectAlert.message1"}
        ></Trans>
        <li> <Trans i18nKey={"appstream.embed.disconnectReason1"}/></li>
        <li> <Trans i18nKey={"appstream.embed.disconnectReason2"}/></li>
        <Trans
          i18nKey={"appstream.embed.disconnectAlert.message2"}
          components={{ b: <strong /> }}
        ></Trans>
        </>
      }
      primaryButtonText={t("appstream.embed.disconnectAlert.button")}
      primaryButtonOnClick={async () => {
        await appStreamSDK.performActionPromise(
          AppStreamEmbedConstant.METHOD_REFRESH_SESSION
        );
      }}
      secondaryButtonText={t("presession.returnToLogin.button")}
      secondaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="disconnect-warning-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

export const ConnectionErrorModal = ({
  appStreamSDK,
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.connectionError.title")}
      errorMessage={t("appstream.embed.connectionError.message")}
      primaryButtonText={t("appstream.embed.connectionError.button")}
      primaryButtonOnClick={async () => {
        await appStreamSDK.performActionPromise(
          AppStreamEmbedConstant.METHOD_REFRESH_SESSION
        );
      }}
      secondaryButtonText={t("presession.returnToLogin.button")}
      secondaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="connection-error-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

export const InstanceUnavailableErrorModal = ({
  appStreamSDK,
  onRedirectToLogin,
}: appStreamSDKProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <ErrorModal
      errorTitle={t("appstream.embed.instancesUnavailable.title")}
      errorMessage={t("appstream.embed.instancesUnavailable.message")}
      primaryButtonText={t("appstream.embed.instancesUnavailable.button")}
      primaryButtonOnClick={async () => {
        await appStreamSDK.performActionPromise(
          AppStreamEmbedConstant.METHOD_REFRESH_SESSION
        );
      }}
      secondaryButtonText={t("presession.returnToLogin.button")}
      secondaryButtonOnClick={() => onRedirectToLogin(true)}
      dataTestId="instanceUnavailable-error-modal"
      onDismiss={() => onRedirectToLogin(true)}
    />
  );
};

interface ConditionalRenderErrorModalProps {
  visible: boolean;
  sessionErrorType: SessionErrorType;
  appStreamSDK: AppStreamSDK;
  onRedirectToLogin: (redirectToLogin: boolean) => void;
}

export const ConditionalRenderErrorModal = ({
  visible,
  sessionErrorType,
  appStreamSDK,
  onRedirectToLogin,
}: ConditionalRenderErrorModalProps): JSX.Element => {

  if (visible) {
    switch (sessionErrorType) {
      case "DisconnectWarning":
        return (
          <DisconnectWarningModal
            appStreamSDK={appStreamSDK}
            onRedirectToLogin={onRedirectToLogin}
          />
        );
      case "ReserveSessionError":
        return (
          <ReserveSessionErrorModal
            appStreamSDK={appStreamSDK}
            onRedirectToLogin={onRedirectToLogin}
          />
        );
      case "NoApplicationError":
        return (
          <NoApplicationErrorModal onRedirectToLogin={onRedirectToLogin} />
        );
      case "DeployApplicationError":
        return (
          <DeployApplicationErrorModal onRedirectToLogin={onRedirectToLogin} />
        );
      case "ConnectionError":
        return (
          <ConnectionErrorModal
            appStreamSDK={appStreamSDK}
            onRedirectToLogin={onRedirectToLogin}
          />
        );
      case "InstancesUnavailableError":
        return (
          <InstanceUnavailableErrorModal
            appStreamSDK={appStreamSDK}
            onRedirectToLogin={onRedirectToLogin}
          />
        );
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
};
