// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import { Auth } from "aws-amplify";
import { Button } from "@amzn/awsui-components-react/polaris";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { useTranslation } from "react-i18next";
import log from "../../../../logging";

interface Props {
  hasIdentityProvider: boolean;
}

const AuthButton = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const { hasIdentityProvider } = props;
  const onSignIn = () => {
    log.logMessage("SignIn button clicked.");
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  };

  return (
    <Button
      className="Button-form"
      onClick={onSignIn}
      disabled={!hasIdentityProvider}
    >
      <span id="span-text">{t("login.button.label")}</span>
    </Button>
  );
};

export default AuthButton;
