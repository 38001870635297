// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./styles/App.css";
import { App } from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { initalizeLogger } from "./logging";

//Enable hot reload of the module
// eslint-disable-next-line
if ((module as any).hot) {
  // eslint-disable-next-line
  (module as any).hot.accept();
}

initalizeLogger();
ReactDOM.render(
  <div>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
