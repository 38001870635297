// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React from "react";
import { Link, ButtonDropdown } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import "./style.css";
import useI18nLanguage from "../../../hooks/useI18nLanguage";
import { LANGUAGE_DISPLAY_NAMES } from "../../../i18n";

const Header = () => {
  const { t } = useTranslation();
  const { language, changeLanguage } = useI18nLanguage();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.detail.id;
    changeLanguage(selectedLanguage);
  };

  return (
    <div className="euc-header-container-style">
      <Link
        href="https://aws.amazon.com/contact-us"
        target="_blank"
        rel="noreferrer"
        variant="info"
      >
        <span className="euc-header-span-style">
          {t("euc.signIn.header.contactUs.label")}
        </span>
      </Link>

      <ButtonDropdown
        items={Object.entries(LANGUAGE_DISPLAY_NAMES).map(
          ([lng_code, lng_display_name]) => ({
            id: lng_code,
            text: lng_display_name,
          })
        )}
        onItemClick={handleLanguageChange}
      >
        {LANGUAGE_DISPLAY_NAMES[language]}
      </ButtonDropdown>
    </div>
  );
};

export default Header;
