import { useEffect, useState } from "react";
import { useMediaDeviceStore } from "./useMediaDeviceStore";
import { AppStreamSDK } from "../utils/AppStreamSDK";
import log, { TOOLBAR_METRIC_NAME } from "../logging";

const useCameraDevices = (appStreamEmbed: AppStreamSDK) => {
  const [cameraDevices, setCameraDevices] = useState([]);

  const setWebcams = useMediaDeviceStore((store) => store.setWebcams);

  const selectedWebcam = useMediaDeviceStore((store) => store.selectedWebcam);
  const setSelectedWebcam = useMediaDeviceStore(
    (store) => store.setSelectedWebcam
  );

  const isWebcamPermissionAllowed = useMediaDeviceStore(
    (state) => state.isWebcamPermissionAllowed
  );

  const fetchCameraDevices = async () => {
    try {
      const connectedWebcams = await appStreamEmbed.getConnectedWebcams();
      log.publishCounterMetric(
        TOOLBAR_METRIC_NAME.GET_CONNECTED_WEBCAMS_SUCCESS
      );
      setWebcams(connectedWebcams);
      setCameraDevices(connectedWebcams);
    } catch (error) {
      console.error("Error getting connected webcams from AppStream:", error);
      log.publishCounterMetric(TOOLBAR_METRIC_NAME.GET_CONNECTED_WEBCAMS_ERROR);
    }
  };

  useEffect(() => {
    fetchCameraDevices();

    const handleDeviceChange = async () => {
      await fetchCameraDevices();
    };

    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange
      );
    };
  }, [isWebcamPermissionAllowed]);

  useEffect(() => {
    if (
      !selectedWebcam &&
      cameraDevices.length > 0 &&
      cameraDevices[0].deviceId !== ""
    ) {
      setSelectedWebcam(cameraDevices[0]);
    }
  }, [cameraDevices]);

  return cameraDevices;
};

export default useCameraDevices;
