// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import UXDefinitions from "@amzn/shortbread/dist/definitions/ux-definitions";
import log from "../logging";
import { getBrowserLanguage } from "../utils/localizationUtils";
import { DEFAULT_FALLBACK_LANGUAGE, getTargetLanguage } from "../i18n";
import CookieConsentManager from "./CookieConsentManager";
import { SHORTBREAD_COOKIE_REGISTRY } from "../constants/ShortbreadConfig";
import { AWSCShortbread } from "@amzn/shortbread";
import { getCrossPortalParentCookieDomain } from "../utils/domainUtils";

/**
 * Figures out the target language for the shortbread notification. Uses the
 * i18n module to make sure we only enable the languages for which we have full
 * translation.
 * @returns
 */
export function getShortbreadLanguage(): UXDefinitions.LanguageCodeOptions {
  log.warn(
    `Current Language "${getTargetLanguage(
      getBrowserLanguage()
    )}" is Shortbread compatible? ${
      getBrowserLanguage() as UXDefinitions.LanguageCodeOptions
    }`
  );
  return getTargetLanguage(
    (getBrowserLanguage() as UXDefinitions.LanguageCodeOptions) ||
      DEFAULT_FALLBACK_LANGUAGE
  );
}

/**
 * This function will be called whenever consent is updated. Please add any
 * special hooks which need to be triggered here.
 */
function wswOnConsentChanged() {
  log.info("Consent change reported.");
}

/**
 * Initiate Shortbread Cookie Manager
 */
function initCookieManager(): CookieConsentManager {
  const portalDomain = window.location.hostname;
  const configOptions = {
    domain: getCrossPortalParentCookieDomain(),
    hasConsoleNavFooter: false,
    language: getShortbreadLanguage(),
    registry: SHORTBREAD_COOKIE_REGISTRY,
    onConsentChanged: wswOnConsentChanged,
  };
  const shortbreadInstance = AWSCShortbread(configOptions);
  // Initialize Cookie Manager
  shortbreadInstance.checkForCookieConsent();
  return new CookieConsentManager(shortbreadInstance);
}

export const cookieManagerInstance: CookieConsentManager = initCookieManager();

export * from "./CookieConsentManager";
