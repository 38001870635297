export const DEEP_LINK_URL_LENGTH_MIN = 3;
export const DEEP_LINK_URL_LENGTH_MAX = 4096;
export const DEEP_LINK_LIST_LENGTH_MAX = 10;

export enum CLIENT_MESSAGE_TYPE {
  OPEN_DEEP_LINKS = "OpenDeepLinks",
}

export const CLIENT_MESSAGE_COOKIE_NAMES = {
  WSW_DEEP_LINKS: "wsw-deep-links",
};
