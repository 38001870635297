import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
  KeyboardFunctions,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { memo, useMemo } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { Button } from "@cloudscape-design/components";
import { convertToolbarKeyIdIntoAppStreamKey } from "../../../utils/toolbarItemUtils";
import {
  AdditionalKeyId,
  ToolbarItemId,
  ToolbarMode,
} from "../../../constants/Toolbar";
import { useTranslation } from "react-i18next";
import { getDeviceType } from "../../../utils/userAgentUtils";
import { UserAgentDeviceType } from "../../../enums/userAgentDeviceType";

export const useFunctionKeysToolbarItem = (
  appStreamSdk: AppStreamSDK,
  toolbarState: ToolbarMode
): IToolbarButtonProps => {
  const { t } = useTranslation();
  const deviceType = useMemo(() => getDeviceType(), []);

  const FunctionKeysContent = () => {
    return (
      <KeyboardFunctions
        header={t("toolbar.item.functionKey.content.header")}
        description={t("toolbar.item.functionKey.content.description")}
        i18nAriaLabels={{
          controlKey: t("toolbar.item.functionKey.key.controlKey.ariaLabel"),
          altKey: t("toolbar.item.functionKey.key.altKey.ariaLabel"),
          shiftKey: t("toolbar.item.functionKey.key.shiftKey.ariaLabel"),
          deleteKey: t("toolbar.item.functionKey.key.deleteKey.ariaLabel"),
          escapeKey: t("toolbar.item.functionKey.key.escapeKey.ariaLabel"),
          tabKey: t("toolbar.item.functionKey.key.tabKey.ariaLabel"),
          homeKey: t("toolbar.item.functionKey.key.homeKey.ariaLabel"),
          endKey: t("toolbar.item.functionKey.key.endKey.ariaLabel"),
          leftArrowKey: t(
            "toolbar.item.functionKey.key.leftArrowKey.ariaLabel"
          ),
          rightArrowKey: t(
            "toolbar.item.functionKey.key.rightArrowKey.ariaLabel"
          ),
          upArrowKey: t("toolbar.item.functionKey.key.upArrowKey.ariaLabel"),
          downArrowKey: t(
            "toolbar.item.functionKey.key.downArrowKey.ariaLabel"
          ),
          enterKey: t("toolbar.item.functionKey.key.enterKey.ariaLabel"),
          f1Key: t("toolbar.item.functionKey.key.f1Key.ariaLabel"),
          f2Key: t("toolbar.item.functionKey.key.f2Key.ariaLabel"),
          f3Key: t("toolbar.item.functionKey.key.f3Key.ariaLabel"),
          f4Key: t("toolbar.item.functionKey.key.f4Key.ariaLabel"),
          f5Key: t("toolbar.item.functionKey.key.f5Key.ariaLabel"),
          f6Key: t("toolbar.item.functionKey.key.f6Key.ariaLabel"),
          f7Key: t("toolbar.item.functionKey.key.f7Key.ariaLabel"),
          f8Key: t("toolbar.item.functionKey.key.f8Key.ariaLabel"),
          f9Key: t("toolbar.item.functionKey.key.f9Key.ariaLabel"),
          f10Key: t("toolbar.item.functionKey.key.f10Key.ariaLabel"),
          f11Key: t("toolbar.item.functionKey.key.f11Key.ariaLabel"),
          f12Key: t("toolbar.item.functionKey.key.f12Key.ariaLabel"),
        }}
        additionalKeys={[
          {
            label: t("toolbar.item.functionKey.key.selectAll.label"),
            ariaLabel: t("toolbar.item.functionKey.key.selectAll.ariaLabel"),
            id: AdditionalKeyId.SELECT_ALL,
          },
          {
            label: t("toolbar.item.functionKey.key.copy.label"),
            ariaLabel: t("toolbar.item.functionKey.key.copy.ariaLabel"),
            id: AdditionalKeyId.COPY,
          },
          {
            label: t("toolbar.item.functionKey.key.paste.label"),
            ariaLabel: t("toolbar.item.functionKey.key.paste.ariaLabel"),
            id: AdditionalKeyId.PASTE,
          },
          {
            label: t("toolbar.item.functionKey.key.cut.label"),
            ariaLabel: t("toolbar.item.functionKey.key.cut.ariaLabel"),
            id: AdditionalKeyId.CUT,
          },
        ]}
        onClickKey={(event) => {
          const keys = convertToolbarKeyIdIntoAppStreamKey(event);
          appStreamSdk.sendKeys(keys);
        }}
        additionalButtons={
          deviceType === UserAgentDeviceType.MOBILE ||
          deviceType === UserAgentDeviceType.TABLET
            ? [
                <Button
                  key={"on-screen-keyboard"}
                  onClick={() => {
                    appStreamSdk.showSoftKeyboard();
                  }}
                >
                  {t("toolbar.item.functionKey.button.onScreenKeyboard.label")}
                </Button>,
              ]
            : []
        }
      />
    );
  };

  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      activeStateContentType: IToolbarButtonActiveContentType.HORIZONTAL_MENU,
      label: t("toolbar.item.functionKey.label"),
      ariaLabel: t("toolbar.item.functionKey.ariaLabel"),
      icon: <Icon name="keyboard" />,
      ActiveStateContent: memo(() => <FunctionKeysContent />),
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.FUNCTIONS,
    label: t("toolbar.item.functionKey.label"),
    ariaLabel: t("toolbar.item.functionKey.ariaLabel"),
    icon: <Icon name="keyboard" />,
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t(
          "toolbar.item.functionKey.content.close.ariaLabel"
        )}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        <FunctionKeysContent />
      </ToolbarContainerItemContent>
    )),
  };
};
