import { URL_PARAMETER } from "../constants";
import { useEffect, useMemo, useState } from "react";
import { cookieManagerInstance } from "../cookies";
import { CustomCookieIdentifier } from "../constants/ShortbreadConfig";
import log from "../logging";
import { validateDeepLink } from "../utils";

type DeepLinkFlagFromUrlParamStatus = "in-progress" | "completed";

export const useSetDeepLinkFromUrlParam = () => {
  const [status, setStatus] = useState<DeepLinkFlagFromUrlParamStatus>(
    "in-progress"
  );
  useEffect(() => {
    const urlParams = new URLSearchParams(document.location.search);
    const deepLinkInput = urlParams.get(URL_PARAMETER.DEEPLINK);
    setStatus("in-progress");
    if (deepLinkInput != null) {
      let decodedDeepLinkInput = decodeURIComponent(deepLinkInput);
      if (!validateDeepLink(decodedDeepLinkInput)) {
        decodedDeepLinkInput = null;
        log.error("Invalid DeepLink input");
      }
      cookieManagerInstance.setSessionStorage(
        CustomCookieIdentifier.DEEPLINK.name,
        decodedDeepLinkInput
      );
    }
    setStatus("completed");
  }, []);
  return status;
};
