import { z } from "zod";

/**
 * Schema for our Banner State Cookie.
 * We will validate against this schema on ingestion and
 * invalidate the cookie if it does not have valid schema.
 *
 * If you ever need custom validation of fields:
 * https://github.com/colinhacks/zod/issues/37
 */
export const BannerStateCookieSchema = z.object({
  isWebcamWarningDelivered: z.boolean().optional(),
  hideExtensionModal: z.boolean().optional(),
});

/**
 * Derived type for the wsw-banner-state cookie
 */
export type BannerStateCookie = z.infer<typeof BannerStateCookieSchema>;
