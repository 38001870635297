import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import {
  FileExplorer,
  IFileExplorerApi,
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonStatefulModalProps,
  IToolbarButtonType,
  NotificationItem,
  NotificationList,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
  useToolbarCollapseStore,
} from "@amzn/aws-euc-ui";
import React, { memo, useMemo } from "react";
import { Icon } from "@cloudscape-design/components";
import {
  INITIAL_FILE_EXPLORER_PATH,
  ToolbarItemId,
  ToolbarMode,
} from "../../../constants/Toolbar";
import { EmbedConstants } from "@amzn/photon-portal-embed-sdk";
import { useTranslation } from "react-i18next";
import { useFilesNotificationStore } from "../../../hooks/useNotificationStore";
import { useNotification } from "./notification/useNotification";

export const useFileToolbarItem = (
  appStreamSDK: AppStreamSDK,
  toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
  const { t } = useTranslation();
  const i18nStrings = useGetI18nStrings();
  const api = useGetApis(appStreamSDK);

  const notificationList = useFilesNotificationStore(
    (store) => store.notificationList
  );

  const { setCollapsed } = useToolbarCollapseStore();

  const {
    isFileDownloadAllowed,
    isFileUploadAllowed,
  } = appStreamSDK.getUserInterfaceState();

  const FileToolbarItemContent = () => {
    return (
      <>
        <NotificationList
          items={notificationList}
          ariaLabel={t("notification.list.ariaLabel")}
        />
        <FileExplorer
          api={api}
          i18nStrings={i18nStrings}
          rootPath={INITIAL_FILE_EXPLORER_PATH}
          tableVariant={"embedded"}
          contentDensity={"compact"}
          textFilterProps={{
            filteringPlaceholder: t(
              "toolbar.item.files.content.table.filtering.placeholder"
            ),
          }}
          allowedActions={{
            download: isFileDownloadAllowed,
            upload: isFileUploadAllowed,
          }}
        />
      </>
    );
  };

  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      label: t("toolbar.item.files.label"),
      id: ToolbarItemId.FILES,
      icon: <Icon data-testid={"file-explorer-icon"} name="folder" />,
      onClick: () => {
        setCollapsed(false);
      },
      activeStateContentType: IToolbarButtonActiveContentType.MODAL,
      ActiveStateContent: memo(() => <FileToolbarItemContent />),
      ariaLabelCloseModal: t("toolbar.item.files.content.close.ariaLabel"),
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.FILES,
    label: t("toolbar.item.files.label"),
    icon: <Icon data-testid={"file-explorer-icon"} name="folder" />,
    onClick: () => {
      setCollapsed(false);
    },
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t("toolbar.item.files.content.close.ariaLabel")}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        <FileToolbarItemContent />
      </ToolbarContainerItemContent>
    )),
  };
};

const useGetI18nStrings = () => {
  const { t } = useTranslation();

  return {
    paginationAriaLabels: {
      nextPageLabel: t(
        "toolbar.item.files.content.table.pagination.nextPage.label"
      ),
      previousPageLabel: t(
        "toolbar.item.files.content.table.pagination.previousPage.label"
      ),
      pageLabel: (pageNumber: number) =>
        t("toolbar.item.files.content.table.pagination.page.label", {
          pageNumber,
        }),
    },
    tableAriaLabels: {
      selectionGroupLabel: t(
        "toolbar.item.files.content.table.selectionGroup.label"
      ),
      allItemsSelectionLabel: ({ selectedItems }) => {
        if (selectedItems.length === 1) {
          return t(
            "toolbar.item.files.content.table.allItemsSelection.single.label"
          );
        } else {
          return t(
            "toolbar.item.files.content.table.allItemsSelection.multiple.label",
            { count: selectedItems.length }
          );
        }
      },
      itemSelectionLabel: ({ selectedItems }, item) => {
        const isItemSelected = selectedItems.some(
          (i) => i.displayName === item.displayName
        );
        if (isItemSelected) {
          return t(
            "toolbar.item.files.content.table.itemsSelection.selected.label",
            { name: item.displayName }
          );
        } else {
          return t(
            "toolbar.item.files.content.table.itemsSelection.unselected.label",
            { name: item.displayName }
          );
        }
      },
    },
    filteringAriaLabel: t(
      "toolbar.item.files.content.table.filtering.ariaLabel"
    ),
    filteringClearAriaLabel: t(
      "toolbar.item.files.content.table.filteringClear.ariaLabel"
    ),
    breadCrumbAriaLabel: t(
      "toolbar.item.files.content.table.breadCrumb.ariaLabel"
    ),
    breadCrumbExpandAriaLabel: t(
      "toolbar.item.files.content.table.breadCrumbExpand.ariaLabel"
    ),
    entry: t("toolbar.item.files.content.table.entry"),
    entries: t("toolbar.item.files.content.table.entries"),
    pageSize: t("toolbar.item.files.content.table.pageSize"),
    selectPageSize: t("toolbar.item.files.content.table.selectPageSize"),
    wrapLinesLabel: t("toolbar.item.files.content.table.wrapLines.label"),
    wrapLinesDescription: t(
      "toolbar.item.files.content.table.wrapLines.description"
    ),
    cancelLabel: t("toolbar.item.files.content.table.cancel.label"),
    confirmLabel: t("toolbar.item.files.content.table.confirm.label"),
    preferencesTitle: t("toolbar.item.files.content.table.preferences.title"),
    rename: t("toolbar.item.files.content.table.rename"),
    fileExplorerTitle: t("toolbar.item.files.content.table.fileExplorer.title"),
    download: t("toolbar.item.files.content.table.download"),
    uploading: t("toolbar.item.files.content.table.uploading"),
    uploadFiles: t("toolbar.item.files.content.table.uploadFiles"),
    noMatchesTitle: t("toolbar.item.files.content.table.noMatches.title"),
    noMatchesSubtitle: t("toolbar.item.files.content.table.noMatches.subtitle"),
    noFilesTitle: t("toolbar.item.files.content.table.noFiles.title"),
    noFilesSubtitle: t("toolbar.item.files.content.table.noFiles.subtitle"),
    dropZoneTitle: t("toolbar.item.files.content.table.dropZone.title"),
    dropZoneSubtitle: t("toolbar.item.files.content.table.dropZone.subtitle"),
    createFolder: t("toolbar.item.files.content.table.createFolder"),
    fileName: t("toolbar.item.files.content.table.fileName"),
    fileLastModified: t("toolbar.item.files.content.table.fileLastModified"),
    fileSize: t("toolbar.item.files.content.table.fileSize"),
    delete: t("toolbar.item.files.content.table.delete"),
    actions: t("toolbar.item.files.content.table.actions"),
    uploadFolder: t("toolbar.item.files.content.table.uploadFolder"),
    openFolder: t("toolbar.item.files.content.table.openFolder"),
    newFolderName: t("toolbar.item.files.content.table.newFolderName"),
    saveChanges: t("toolbar.item.files.content.table.saveChanges"),
    ignoreChanges: t("toolbar.item.files.content.table.ignoreChanges"),
    newFileName: t("toolbar.item.files.content.table.newFileName"),
    folder: t("toolbar.item.files.content.table.folder"),
    file: t("toolbar.item.files.content.table.file"),
    clearFilter: t("toolbar.item.files.content.table.clearFilter"),
  };
};

const useGetApis = (appStreamSDK: AppStreamSDK) => {
  const { t } = useTranslation();

  const notificationList = useFilesNotificationStore(
    (store) => store.notificationList
  );

  const addNotification = useFilesNotificationStore(
    (store) => store.addNotification
  );

  const removeNotification = useFilesNotificationStore(
    (store) => store.removeNotification
  );

  const DELETE_FILE_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.deleteFile.content"),
    removeNotification: removeNotification,
  });

  const LIST_FILE_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.listFile.content"),
    removeNotification: removeNotification,
  });

  const RENAME_DIRECTORY_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.renameDirectory.content"),
    removeNotification: removeNotification,
  });

  const DOWNLOAD_FILE_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.downloadFile.content"),
    removeNotification: removeNotification,
  });

  const UPLOAD_FILE_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.uploadFile.content"),
    removeNotification: removeNotification,
  });

  return useMemo<IFileExplorerApi>(
    () => ({
      // CreateDirectory error handled internally: https://tiny.amazon.com/107hpu5e4/codeamazpackAWSEblob865dsrc
      createDirectory: (path) =>
        appStreamSDK.performActionPromise(EmbedConstants.METHOD_CREATE_FOLDER, {
          remotePath: path,
        }),
      deleteFile: (path) =>
        appStreamSDK
          .performActionPromise(EmbedConstants.METHOD_DELETE_FILE, {
            remotePath: path,
          })
          .catch(() => addNotification(DELETE_FILE_ERROR)),
      list: (path) =>
        appStreamSDK.listFiles(path).catch((error) => {
          // https://sim.amazon.com/issues/LOWA-9174
          if (error.errorCode !== 400) {
            // Only show ListFile API error once since we are using 3 retries with useQuery (to avoid multiple errors on screen from one fetch)
            if (
              !notificationList.find(
                (item) => item.header === LIST_FILE_ERROR.header
              )
            ) {
              addNotification(LIST_FILE_ERROR);
            }
          }
          return [];
        }),
      renameDirectory: (src, dest) =>
        appStreamSDK
          .performActionPromise(EmbedConstants.METHOD_RENAME_FILE, {
            remotePath: src,
            newPath: dest,
          })
          .catch(() => addNotification(RENAME_DIRECTORY_ERROR)),
      // RenameFile error handled internally: https://tiny.amazon.com/oxaj7lvc/codeamazpackAWSEblob865dsrc
      renameFile: (originalPath, newPath) =>
        appStreamSDK.performActionPromise(EmbedConstants.METHOD_RENAME_FILE, {
          remotePath: originalPath,
          newPath: newPath,
        }),
      retrieveFile: (path) =>
        appStreamSDK
          .performActionPromise(EmbedConstants.METHOD_DOWNLOAD_FILE, {
            remotePath: path,
          })
          .catch(() => addNotification(DOWNLOAD_FILE_ERROR)),
      storeFile: async (file, dir, onProgress) => {
        onProgress(0);

        try {
          await appStreamSDK.performActionPromise(
            EmbedConstants.METHOD_UPLOAD_FILE,
            {
              file: file,
              remotePath: dir,
            }
          );
        } catch {
          addNotification(UPLOAD_FILE_ERROR);
        }

        onProgress(file.size);
      },
    }),
    [appStreamSDK, JSON.stringify(notificationList)]
  );
};
