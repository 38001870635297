import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { useCallback, useEffect, useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { useTranslation } from "react-i18next";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import { NotificationId } from "../../../constants/Toolbar";

export const useFullScreenToolbarItem = (
  appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
  const { t } = useTranslation();

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fullScreenChangeEventListener = useCallback(() => {
    setIsFullScreen(!!document.fullscreenElement);
  }, []);
  useEffect(() => {
    // Set the initial value based on the current full screen element.
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    }

    document.addEventListener(
      "fullscreenchange",
      fullScreenChangeEventListener
    );

    return () => {
      document.removeEventListener(
        "fullscreenchange",
        fullScreenChangeEventListener
      );
    };
  }, []);

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const exitFullScreenError = useFloatingAndToolbarNotification({
    header: t("notification.fail.exitFullscreen.content"),
    notificationId: NotificationId.EXIT_FULL_SCREEN_ERROR,
  });

  const enterFullScreenError = useFloatingAndToolbarNotification({
    header: t("notification.fail.enterFullscreen.content"),
    notificationId: NotificationId.ENTER_FULL_SCREEN_ERROR,
  });

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.HEADLESS,
    isSelected: isFullScreen,
    isLoading: isLoading,
    label: t("toolbar.item.fullScreen.label"),
    ariaLabel: t("toolbar.item.fullScreen.ariaLabel"),
    icon: <Icon data-testid={"enter-fullscreen-icon"} name="expand" />,
    activeStateIcon: (
      <Icon data-testid={"exit-fullscreen-icon"} name={"shrink"} />
    ),
    onClick: async () => {
      setIsLoading(true);
      if (isFullScreen) {
        try {
          await appStreamEmbed.exitFullscreen();
        } catch {
          addFloatingNotification(
            exitFullScreenError.floatingToolbarNotification
          );
          addToolbarNotification(exitFullScreenError.toolbarNotification);
        }
      } else {
        try {
          await appStreamEmbed.enterFullscreen(document.body);
        } catch {
          addFloatingNotification(
            enterFullScreenError.floatingToolbarNotification
          );
          addToolbarNotification(enterFullScreenError.toolbarNotification);
        }
      }
      setIsFullScreen((value) => !value);
      setIsLoading(false);
    },
  };
};
