import { create } from "zustand";
import { SessionStatus } from "../types/appStream";

interface SessionDataStore {
  userEndSession: boolean;
  sessionStatus: SessionStatus;
  sessionID: string | undefined;
  copiedTextFromRemote: string | undefined;
  sessionStartTime: number | undefined;
  microphoneEnabledDuration: number;
  microphoneLastEnabledTime: number | undefined;
  floatingToolbarEnabledDuration: number;
  floatingToolbarLastEnabledTime: number | undefined;
  setUserEndSession: (flag: boolean) => void;
  setSessionStatus: (value: SessionStatus) => void;
  setSessionID: (value: string | undefined) => void;
  setCopiedTextFromRemote: (value: string | undefined) => void;
  setSessionStartTime: (timestamp: number | undefined) => void;
  addMicrophoneEnabledDuration: (duration: number) => void;
  setMicrophoneLastEnabledTime: (timestamp: number | undefined) => void;
  resetMicrophoneEnabledDuration: () => void;
  addFloatingToolbarEnabledDuration: (duration: number) => void;
  setFloatingToolbarLastEnabledTime: (timestamp: number | undefined) => void;
  resetFloatingToolbarEnabledDuration: () => void;
}

/**
 * Store for data from remote session
 */
export const useSessionDataStore = create<SessionDataStore>((set) => ({
  userEndSession: false,
  sessionStatus: "Unknown",
  copiedTextFromRemote: undefined,
  sessionID: undefined,
  sessionStartTime: undefined,
  microphoneEnabledDuration: 0,
  microphoneLastEnabledTime: undefined,
  floatingToolbarEnabledDuration: 0,
  floatingToolbarLastEnabledTime: undefined,
  setUserEndSession: (flag) =>
    set((state) => ({
      ...state,
      userEndSession: flag,
    })),
  setSessionStatus: (value) =>
    set((state) => ({
      ...state,
      sessionStatus: value,
    })),
  setSessionID: (value) =>
    set((state) => ({
      ...state,
      sessionID: value,
    })),
  setCopiedTextFromRemote: (value) =>
    set((state) => ({
      ...state,
      copiedTextFromRemote: value,
    })),
  setSessionStartTime: (timestamp: number) =>
    set((state) => ({
      ...state,
      sessionStartTime: timestamp,
    })),
  addMicrophoneEnabledDuration: (duration: number) =>
    set((state) => ({
      ...state,
      microphoneEnabledDuration: state.microphoneEnabledDuration + duration,
    })),
  setMicrophoneLastEnabledTime: (timestamp: number) =>
    set((state) => ({
      ...state,
      microphoneLastEnabledTime: timestamp,
    })),
  resetMicrophoneEnabledDuration: () =>
    set((state) => ({
      ...state,
      microphoneEnabledDuration: 0,
    })),
  addFloatingToolbarEnabledDuration: (duration: number) =>
    set((state) => ({
      ...state,
      floatingToolbarEnabledDuration:
        state.floatingToolbarEnabledDuration + duration,
    })),
  setFloatingToolbarLastEnabledTime: (timestamp: number) =>
    set((state) => ({
      ...state,
      floatingToolbarLastEnabledTime: timestamp,
    })),
  resetFloatingToolbarEnabledDuration: () =>
    set((state) => ({
      ...state,
      floatingToolbarEnabledDuration: 0,
    })),
}));
