import log from "../logging";

export const DEFAULT_LANGUAGE = "en-US";
export const DEFAULT_TIME_ZONE = "Etc/UTC";

// Export this object to use it in test cases
// Data from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones#List
export const DEPRECATED_TIME_ZONES = {
  "Africa/Accra": "Africa/Abidjan",
  "Africa/Addis_Ababa": "Africa/Nairobi",
  "Africa/Asmara": "Africa/Nairobi",
  "Africa/Asmera": "Africa/Nairobi",
  "Africa/Bamako": "Africa/Abidjan",
  "Africa/Bangui": "Africa/Lagos",
  "Africa/Banjul": "Africa/Abidjan",
  "Africa/Blantyre": "Africa/Maputo",
  "Africa/Brazzaville": "Africa/Lagos",
  "Africa/Bujumbura": "Africa/Maputo",
  "Africa/Conakry": "Africa/Abidjan",
  "Africa/Dakar": "Africa/Abidjan",
  "Africa/Dar_es_Salaam": "Africa/Nairobi",
  "Africa/Djibouti": "Africa/Nairobi",
  "Africa/Douala": "Africa/Lagos",
  "Africa/Freetown": "Africa/Abidjan",
  "Africa/Gaborone": "Africa/Maputo",
  "Africa/Harare": "Africa/Maputo",
  "Africa/Kampala": "Africa/Nairobi",
  "Africa/Kigali": "Africa/Maputo",
  "Africa/Kinshasa": "Africa/Lagos",
  "Africa/Libreville": "Africa/Lagos",
  "Africa/Lome": "Africa/Abidjan",
  "Africa/Luanda": "Africa/Lagos",
  "Africa/Lubumbashi": "Africa/Maputo",
  "Africa/Lusaka": "Africa/Maputo",
  "Africa/Malabo": "Africa/Lagos",
  "Africa/Maseru": "Africa/Johannesburg",
  "Africa/Mbabane": "Africa/Johannesburg",
  "Africa/Mogadishu": "Africa/Nairobi",
  "Africa/Niamey": "Africa/Lagos",
  "Africa/Nouakchott": "Africa/Abidjan",
  "Africa/Ouagadougou": "Africa/Abidjan",
  "Africa/Porto-Novo": "Africa/Lagos",
  "Africa/Timbuktu": "Africa/Abidjan",
  "America/Anguilla": "America/Puerto_Rico",
  "America/Antigua": "America/Puerto_Rico",
  "America/Argentina/ComodRivadavia": "America/Argentina/Catamarca",
  "America/Aruba": "America/Puerto_Rico",
  "America/Atikokan": "America/Panama",
  "America/Blanc-Sablon": "America/Puerto_Rico",
  "America/Buenos_Aires": "America/Argentina/Buenos_Aires",
  "America/Catamarca": "America/Argentina/Catamarca",
  "America/Cayman": "America/Panama",
  "America/Coral_Harbour": "America/Panama",
  "America/Cordoba": "America/Argentina/Cordoba",
  "America/Creston": "America/Phoenix",
  "America/Curacao": "America/Puerto_Rico",
  "America/Dominica": "America/Puerto_Rico",
  "America/Grenada": "America/Puerto_Rico",
  "America/Guadeloupe": "America/Puerto_Rico",
  "America/Jujuy": "America/Argentina/Jujuy",
  "America/Kralendijk": "America/Puerto_Rico",
  "America/Lower_Princes": "America/Puerto_Rico",
  "America/Marigot": "America/Puerto_Rico",
  "America/Mendoza": "America/Argentina/Mendoza",
  "America/Montserrat": "America/Puerto_Rico",
  "America/Port_of_Spain": "America/Puerto_Rico",
  "America/Porto_Acre": "America/Rio_Branco",
  "America/Rosario": "America/Argentina/Cordoba",
  "America/St_Barthelemy": "America/Puerto_Rico",
  "America/St_Kitts": "America/Puerto_Rico",
  "America/St_Lucia": "America/Puerto_Rico",
  "America/St_Thomas": "America/Puerto_Rico",
  "America/St_Vincent": "America/Puerto_Rico",
  "America/Tortola": "America/Puerto_Rico",
  "America/Virgin": "America/Puerto_Rico",
  "Antarctica/DumontDUrville": "Pacific/Port_Moresby",
  "Antarctica/Syowa": "Asia/Riyadh",
  "Asia/Aden": "Asia/Riyadh",
  "Asia/Ashkhabad": "Asia/Ashgabat",
  "Asia/Bahrain": "Asia/Qatar",
  "Asia/Brunei": "Asia/Kuching",
  "Asia/Calcutta": "Asia/Kolkata",
  "Asia/Choibalsan": "Asia/Ulaanbaatar",
  "Asia/Chongqing": "Asia/Shanghai",
  "Asia/Chungking": "Asia/Shanghai",
  "Asia/Dacca": "Asia/Dhaka",
  "Asia/Harbin": "Asia/Shanghai",
  "Asia/Istanbul": "Europe/Istanbul",
  "Asia/Kashgar": "Asia/Urumqi",
  "Asia/Katmandu": "Asia/Kathmandu",
  "Asia/Kuala_Lumpur": "Asia/Singapore",
  "Asia/Kuwait": "Asia/Riyadh",
  "Asia/Macao": "Asia/Macau",
  "Asia/Muscat": "Asia/Dubai",
  "Asia/Phnom_Penh": "Asia/Bangkok",
  "Asia/Rangoon": "Asia/Yangon",
  "Asia/Saigon": "Asia/Ho_Chi_Minh",
  "Asia/Thimbu": "Asia/Thimphu",
  "Asia/Ujung_Pandang": "Asia/Makassar",
  "Asia/Ulan_Bator": "Asia/Ulaanbaatar",
  "Asia/Vientiane": "Asia/Bangkok",
  "Atlantic/Reykjavik": "Africa/Abidjan",
  "Atlantic/St_Helena": "Africa/Abidjan",
  "Australia/North": "Australia/Darwin",
  "Australia/Queensland": "Australia/Brisbane",
  "Australia/West": "Australia/Perth",
  "Brazil/Acre": "America/Rio_Branco",
  "Brazil/DeNoronha": "America/Noronha",
  "Brazil/East": "America/Sao_Paulo",
  "Brazil/West": "America/Manaus",
  "Canada/Saskatchewan": "America/Regina",
  "Canada/Yukon": "America/Whitehorse",
  EST: "America/Panama",
  "Etc/GMT+0": "Etc/GMT",
  "Etc/GMT-0": "Etc/GMT",
  "Etc/GMT0": "Etc/GMT",
  "Etc/Greenwich": "Etc/GMT",
  "Etc/UCT": "Etc/UTC",
  "Etc/Universal": "Etc/UTC",
  "Etc/Zulu": "Etc/UTC",
  "GMT+0": "Etc/GMT",
  "GMT-0": "Etc/GMT",
  GMT0: "Etc/GMT",
  Greenwich: "Etc/GMT",
  Hongkong: "Asia/Hong_Kong",
  HST: "Pacific/Honolulu",
  Iceland: "Africa/Abidjan",
  "Indian/Antananarivo": "Africa/Nairobi",
  "Indian/Christmas": "Asia/Bangkok",
  "Indian/Cocos": "Asia/Yangon",
  "Indian/Comoro": "Africa/Nairobi",
  "Indian/Kerguelen": "Indian/Maldives",
  "Indian/Mahe": "Asia/Dubai",
  "Indian/Mayotte": "Africa/Nairobi",
  "Indian/Reunion": "Asia/Dubai",
  Iran: "Asia/Tehran",
  Jamaica: "America/Jamaica",
  Japan: "Asia/Tokyo",
  Kwajalein: "Pacific/Kwajalein",
  Libya: "Africa/Tripoli",
  "Mexico/BajaSur": "America/Mazatlan",
  "Mexico/General": "America/Mexico_City",
  MST: "America/Phoenix",
  "Pacific/Chuuk": "Pacific/Port_Moresby",
  "Pacific/Enderbury": "Pacific/Kanton",
  "Pacific/Funafuti": "Pacific/Tarawa",
  "Pacific/Johnston": "Pacific/Honolulu",
  "Pacific/Majuro": "Pacific/Tarawa",
  "Pacific/Midway": "Pacific/Pago_Pago",
  "Pacific/Pohnpei": "Pacific/Guadalcanal",
  "Pacific/Ponape": "Pacific/Guadalcanal",
  "Pacific/Saipan": "Pacific/Guam",
  "Pacific/Samoa": "Pacific/Pago_Pago",
  "Pacific/Truk": "Pacific/Port_Moresby",
  "Pacific/Wake": "Pacific/Tarawa",
  "Pacific/Wallis": "Pacific/Tarawa",
  "Pacific/Yap": "Pacific/Port_Moresby",
  PRC: "Asia/Shanghai",
  ROC: "Asia/Taipei",
  ROK: "Asia/Seoul",
  Singapore: "Asia/Singapore",
  Turkey: "Europe/Istanbul",
  UCT: "Etc/UTC",
  Universal: "Etc/UTC",
  "US/Arizona": "America/Phoenix",
  "US/Hawaii": "Pacific/Honolulu",
  "US/Samoa": "Pacific/Pago_Pago",
  UTC: "Etc/UTC",
  "W-SU": "Europe/Moscow",
  Zulu: "Etc/UTC",
};

export function getBrowserLanguage(): string {
  let language: string = DEFAULT_LANGUAGE;
  const WSW_LANGUAGE_COOKIE_NAME = "wsw-language";
  const userSelectedLanguage = document.cookie
    .match(`(^|;)\\s*${WSW_LANGUAGE_COOKIE_NAME}\\s*=\\s*([^;]+)`)
    ?.pop();
  try {
    language =
      userSelectedLanguage ??
      window.navigator.languages?.[0] ??
      window.navigator.language ?? // For Firefox, Opera, Google Chrome and Safari
      // @ts-expect-error: browserLanguage is only for older version of IE (not exist type error)
      window.navigator.browserLanguage ??
      // @ts-expect-error: userLanguage is only for IE (not exist type error)
      window.navigator.userLanguage ??
      DEFAULT_LANGUAGE;
  } catch (e) {
    log.warn("Cannot read language setting from browser", e);
  }

  // To avoid API call failed, follow Ermine CP model restriction
  if (!/^[a-zA-Z0-9_-]+$/.test(language)) {
    return DEFAULT_LANGUAGE;
  }
  // Most of the language codes should be within the range
  if (language.length < 2) {
    return DEFAULT_LANGUAGE;
  }
  if (language.length > 32) {
    return DEFAULT_LANGUAGE;
  }
  return language;
}

export function getBrowserTimeZone(): string {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions
  let timeZone: string = DEFAULT_TIME_ZONE;
  try {
    timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone ?? DEFAULT_TIME_ZONE;
  } catch (e) {
    log.warn("Cannot read time zone setting from browser", e);
  }
  if (timeZone in DEPRECATED_TIME_ZONES) {
    timeZone = DEPRECATED_TIME_ZONES[timeZone];
  }
  // To avoid API call failed, follow Ermine CP model restriction
  if (!/^[a-zA-Z_\-/]+$/.test(timeZone)) {
    return DEFAULT_TIME_ZONE;
  }
  // Most of the time zone codes should be within the range.
  if (timeZone.length < 5) {
    return DEFAULT_TIME_ZONE;
  }
  if (timeZone.length > 64) {
    return DEFAULT_TIME_ZONE;
  }
  return timeZone;
}
