import React from "react";

interface SvgWrapperProps {
  children?: React.ReactNode;
}

export const SvgWrapper = ({ children }: SvgWrapperProps) => (
  <div className={"svg-wrapper"}>{children}</div>
);

export const PermissionIcon = () => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.0625 5.25C4.0625 3.80025 5.15381 2.625 6.5 2.625C7.84619 2.625 8.9375 3.80025 8.9375 5.25H4.0625ZM11.375 5.25H10.5625C10.5625 2.83375 8.74366 0.875 6.5 0.875C4.25634 0.875 2.4375 2.83375 2.4375 5.25H1.625C1.17627 5.25 0.8125 5.64175 0.8125 6.125V12.25C0.8125 12.7332 1.17627 13.125 1.625 13.125H11.375C11.8237 13.125 12.1875 12.7332 12.1875 12.25V6.125C12.1875 5.64175 11.8237 5.25 11.375 5.25ZM2.4375 11.375V7H10.5625V11.375H2.4375Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ChromiumPopupBlockedIcon = () => (
  <SvgWrapper>
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="14"
        height="10"
        rx="0.5"
        stroke="currentColor"
      />
      <rect x="1" y="1" width="13" height="2" fill="currentColor" />
      <path d="M1 5H6.5V3" stroke="currentColor" />
      <rect x="11" y="7" width="5" height="5" fill="#D13212" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.6932 8.57215L14.4281 8.30698L13.5 9.23506L12.5719 8.30698L12.3068 8.57215L13.2348 9.50023L12.3068 10.4283L12.5719 10.6935L13.5 9.76539L14.4281 10.6935L14.6932 10.4283L13.7652 9.50023L14.6932 8.57215Z"
        fill="#FFF7F7"
      />
    </svg>
  </SvgWrapper>
);

export const ArrowUpIcon = () => (
  <SvgWrapper>
    <svg
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 28 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        transform="rotate(-90 7.5 19.5)"
        x="7.5"
        y="19.5"
        width="16"
        height="16"
        rx="8"
        stroke="currentColor"
      />
      <path
        d="m10.687 12.465.883.883L15.5 9.42l3.929 3.93.883-.884-4.813-4.813-4.812 4.813z"
        clip-rule="evenodd"
        fill="currentColor"
        fill-rule="evenodd"
      />
    </svg>
  </SvgWrapper>
);

export const FirefoxPermissionIcon = () => (
  <SvgWrapper>
    <svg
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 2.92969H10" stroke="currentColor" />
      <circle cx="13.8573" cy="2.92857" r="1.92857" stroke="currentColor" />
      <path d="M15.7856 8.07031L6.78565 8.07031" stroke="currentColor" />
      <circle
        cx="2.92861"
        cy="8.07143"
        r="1.92857"
        transform="rotate(180 2.92861 8.07143)"
        stroke="currentColor"
      />
    </svg>
  </SvgWrapper>
);

export const CameraIcon = () => (
  <SvgWrapper>
    <svg
      width="90"
      height="75"
      viewBox="0 0 128 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="114.926"
        y1="13.597"
        x2="102.935"
        y2="25.5877"
        stroke="#000716"
        stroke-width="2"
      />
      <line
        x1="124.423"
        y1="29.6903"
        x2="108.043"
        y2="34.0792"
        stroke="#000716"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="16.9574"
        y2="-1"
        transform="matrix(-0.258819 0.965926 0.965926 0.258819 98.384 2.94434)"
        stroke="#000716"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="16.9574"
        y2="-1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 12.89 12.8899)"
        stroke="#000716"
        stroke-width="2"
      />
      <line
        y1="-1"
        x2="16.9574"
        y2="-1"
        transform="matrix(0.965926 0.258819 0.258819 -0.965926 2.94446 28.7244)"
        stroke="#000716"
        stroke-width="2"
      />
      <line
        x1="29.6904"
        y1="2.68552"
        x2="34.0793"
        y2="19.0651"
        stroke="#000716"
        stroke-width="2"
      />
      <rect
        x="36.9999"
        y="32.9998"
        width="53.0191"
        height="52.2361"
        rx="1"
        fill="#F2F8FD"
        stroke="#000716"
        stroke-width="2"
      />
      <path
        d="M89.7294 58.3347V52.0231L102.795 44.9745V72.5076L89.7294 65.459V58.3347Z"
        fill="#0972D3"
        stroke="#000716"
        stroke-width="2"
      />
    </svg>
  </SvgWrapper>
);
