import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
  useToolbarCollapseStore,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import { NotificationId } from "../../../constants/Toolbar";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";
import { isFeatureEnabled } from "../../../configurations";
import { useMediaDeviceStore } from "../../../hooks/useMediaDeviceStore";

export const useMicrophone = (
  appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
  const { t } = useTranslation();
  const microphoneEnabled = useToolbarPreferenceStore(
    (store) => store.microphoneEnabled
  );
  const setMicrophoneEnabled = useToolbarPreferenceStore(
    (store) => store.setMicrophoneEnabled
  );

  const selectedMicrophone = useMediaDeviceStore(
    (store) => store.selectedMicrophone
  );
  const setSelectedMicrophone = useMediaDeviceStore(
    (store) => store.setSelectedMicrophone
  );

  const [loading, setLoading] = useState(false);

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const collapsed = useToolbarCollapseStore((store) => store.collapsed);

  const microphoneNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotEnabled.header"),
    content: t("notification.microphoneNotEnabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_ENABLED_ERROR,
  });

  const microphoneNotDisabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotDisabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_DISABLED_ERROR,
  });

  const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
    "mediaDeviceSelector"
  );

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.HEADLESS,
    ...((!isMediaDeviceSelectorFeatureEnabled && {
      label: t("toolbar.item.microphone.label"),
    }) ||
      {}),
    ariaLabel: t("toolbar.item.microphone.ariaLabel"),
    isSelected: microphoneEnabled,
    isLoading: loading,
    icon: <Icon data-testid={"microphone-activated"} name="microphone-off" />,
    activeStateIcon: (
      <Icon data-testid={"microphone-deactivated"} name="microphone" />
    ),
    onClick: async () => {
      setLoading(true);
      try {
        await appStreamEmbed.performActionPromise(
          AppStreamEmbedConstant.METHOD_SET_MICROPHONE,
          selectedMicrophone
            ? {
                microphoneEnabled: !microphoneEnabled,
                microphoneDeviceId: selectedMicrophone.deviceId,
              }
            : {
                microphoneEnabled: !microphoneEnabled,
              }
        );
        setMicrophoneEnabled(!microphoneEnabled);
        if (collapsed) {
          if (!microphoneEnabled) {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_MINI_VIEW_SUCCESS
            );
          } else {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.DISABLE_MICROPHONE_MINI_VIEW_SUCCESS
            );
          }
        } else {
          if (!microphoneEnabled) {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS
            );
          } else {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.DISABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS
            );
          }
        }
      } catch {
        if (microphoneEnabled === false) {
          addFloatingNotification(
            microphoneNotEnabledError.floatingToolbarNotification
          );
          addToolbarNotification(microphoneNotEnabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_ERROR);
        } else {
          addFloatingNotification(
            microphoneNotDisabledError.floatingToolbarNotification
          );
          addToolbarNotification(
            microphoneNotDisabledError.toolbarNotification
          );
          log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.DISABLE_MICROPHONE_ERROR
          );
        }
      }
      /**
       * The actual microphone setting in the remote session and our state in local browser
       * can be different when a user clicks the button multiple times in a short period.
       * To prevent any unexpected result, explicitly add some delay after clicking the button.
       * TODO https://issues.amazon.com/issues/LOWA-9859
       */
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  };
};
