import { cookieManagerInstance } from "../cookies";
import {
  isWebcamSupported,
  isDeviceOnExperimentalMode,
} from "./userAgentUtils";
import {
  CustomCookieIdentifier,
  DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS,
} from "../constants/ShortbreadConfig";
import {
  BannerStateCookie,
  BannerStateCookieSchema,
} from "../types/bannerState";

/**
 * Get the Banner State cookie.
 * @returns the state cookie if a valid cookie is found,
 * else empty BannerStateCookie
 */
const getBannerStateCookie = (): BannerStateCookie => {
  const tempCookie = JSON.parse(
    cookieManagerInstance.getCookieStorage(
      CustomCookieIdentifier.BANNER_STATE.name
    ) ?? "{}"
  );
  // Parse the cookie to make sure all data is valid before returning
  const parsedCookie = BannerStateCookieSchema.safeParse(tempCookie);
  if (parsedCookie.success) {
    return parsedCookie.data;
  } else {
    return {} as BannerStateCookie;
  }
};

/**
 * Validates the sets the banner state cookie. Throws and error if
 * the cookie is invalid
 * @param cookie cookie maintaining banner state
 * @returns true if successful, false otherwise
 */
const setBannerStateCookie = (cookie: BannerStateCookie): boolean => {
  let isSuccess = false;
  const parsedCookie = BannerStateCookieSchema.safeParse(cookie);
  if (parsedCookie.success) {
    isSuccess = true;
    cookieManagerInstance.setCookieStorage(
      CustomCookieIdentifier.BANNER_STATE.name,
      JSON.stringify(cookie),
      DEFAULT_CROSS_PORTAL_COOKIE_OPTIONS
    );
  }
  return isSuccess;
};

/**
 * Getter function for webcam banner state
 * @returns true if webcam banner has been delivered, false otherwise
 */
export const getBannerDeliveryWebcamWarningFlag = (): boolean => {
  try {
    const bannerStateCookie = getBannerStateCookie();
    return bannerStateCookie.isWebcamWarningDelivered ?? false;
  } catch {
    return false;
  }
};

/**
 * Setter function for webcam banner state
 * @param true if webcam banner has been delivered, false otherwise
 * @note Logs a warning error if unable to set the value
 */
export const setBannerDeliveryWebcamWarningFlag = (
  isWebcamWarningDelivered: boolean
): boolean => {
  let bannerStateCookie: BannerStateCookie;
  try {
    bannerStateCookie = getBannerStateCookie();
    bannerStateCookie.isWebcamWarningDelivered = isWebcamWarningDelivered;
    return setBannerStateCookie(bannerStateCookie);
  } catch {
    return false;
  }
};

/**
 * returns if the webcam warning needs to be delivered
 * @returns true if warning needs to be displayed, false otherwise
 */
export const shouldDisplayWebcamWarning = (): boolean => {
  let shouldDisplay = false;
  shouldDisplay =
    !isWebcamSupported() &&
    !getBannerDeliveryWebcamWarningFlag() &&
    !isDeviceOnExperimentalMode();
  return shouldDisplay;
};

/**
 * Getter function for hiding extension modal cookie state
 * @returns boolean or undefined
 */
export const getHideExtensionModalCookieState = (): boolean => {
  try {
    const bannerStateCookie = getBannerStateCookie();
    return bannerStateCookie.hideExtensionModal ?? false;
  } catch {
    return undefined;
  }
};

/**
 * Setter function for hiding extension modal cookie state
 * @param boolean
 */
export const setHideExtensionModalCookieState = (
  hideExtensionModal: boolean
): boolean => {
  let bannerStateCookie: BannerStateCookie;
  try {
    bannerStateCookie = getBannerStateCookie();
    bannerStateCookie.hideExtensionModal = hideExtensionModal;
    return setBannerStateCookie(bannerStateCookie);
  } catch {
    return false;
  }
};
