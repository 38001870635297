import { create } from "zustand";

interface UseSkipTutorialStoreState {
  isTutorialSkipped: boolean;
  setTutorialSkipped: (skipTutorial: boolean) => void;
}

export const useSkipTutorialStore = create<UseSkipTutorialStoreState>(
  (set) => ({
    isTutorialSkipped: false,
    setTutorialSkipped: (isTutorialSkipped: boolean) =>
      set(() => ({
        isTutorialSkipped: isTutorialSkipped,
      })),
  })
);
