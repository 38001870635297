import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFloatingAndToolbarNotification } from "../components/toolbar/items/notification/useNotification";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "./useNotificationStore";
import { NotificationItem } from "@amzn/aws-euc-ui";
import { CookieResult, CookieSyncSupportedBrowserType } from "../types/cookies";
import { NotificationId } from "../constants/Toolbar";
import {
  LearnMoreLink,
  getExtensionLink,
} from "../components/presession/ExtensionModal";
import { getDeviceBrowser } from "../utils/userAgentUtils";
import Link from "@cloudscape-design/components/link";
import { isBrowserSupportedCookieSync } from "../utils/cookieSyncUtils";
import { getRebrandedMessageIdIfExists } from "../i18n";

export const useCookieResultForNotification = (cookieResult: CookieResult) => {
  const { t } = useTranslation();
  const browserType = getDeviceBrowser() as CookieSyncSupportedBrowserType;
  const extensionLink = getExtensionLink(browserType);
  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );
  const removeToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.removeNotification
  );
  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const COOKIE_SYNC_EXTENSION_ERROR = useFloatingAndToolbarNotification({
    header: (
      <Trans
        i18nKey={getRebrandedMessageIdIfExists(
          "notification.fail.cookieSync.extensionError.content"
        )}
        components={[
          <Link
            href={extensionLink}
            target={browserType === "Firefox" ? "_self" : "_blank"}
          />,
        ]}
      />
    ),
    notificationId: NotificationId.COOKIE_SYNC_EXTENSION_ERROR,
  });

  const COOKIE_SYNC_CONFIG_ERROR = useFloatingAndToolbarNotification({
    header: t("notification.fail.cookieSync.internalError.content"),
    notificationId: NotificationId.COOKIE_SYNC_CONFIG_ERROR,
  });

  const COOKIE_SYNC_BROWSER_INCOMPATIBLE: NotificationItem = {
    onDismiss: () =>
      removeToolbarNotification(
        NotificationId.COOKIE_SYNC_BROWSER_INCOMPATIBLE
      ),
    header: (
      <Trans
        i18nKey={"notification.info.cookieSync.browserIncompatible.content"}
      >
        {[<LearnMoreLink />]}
      </Trans>
    ),
    type: "info",
    statusIconAriaLabel: t("notification.info.statusIconAriaLabel"),
    dismissible: true,
    dismissAriaLabel: t("notification.dismissButton.ariaLabel"),
    id: NotificationId.COOKIE_SYNC_BROWSER_INCOMPATIBLE,
  };
  useEffect(() => {
    if (cookieResult !== null) {
      const error = cookieResult.error;
      const cookies = cookieResult.cookies;

      // Cookie Sync is disabled or no cookies are synced
      if (error === null && cookies?.length === 0) {
        return;
      }

      // Cookie Sync is enabled but browser is not supported
      if (!isBrowserSupportedCookieSync()) {
        addToolbarNotification(COOKIE_SYNC_BROWSER_INCOMPATIBLE);
        return;
      }

      // Cookie Sync is enabled and cookies are synced successfully
      if (error === null && cookies?.length > 0) {
        const cookieCount = cookies?.length;
        const COOKIE_SYNC_SUCCESS: NotificationItem = {
          onDismiss: () =>
            removeToolbarNotification(NotificationId.COOKIE_SYNC_SUCCESS),
          header: (
            <Trans
              i18nKey={"notification.success.cookieSync.content"}
              values={{ cookieCount: cookieCount }}
            >
              {[<LearnMoreLink />]}
            </Trans>
          ),
          type: "success",
          statusIconAriaLabel: t("notification.success.statusIconAriaLabel"),
          dismissible: true,
          dismissAriaLabel: t("notification.dismissButton.ariaLabel"),
          id: NotificationId.COOKIE_SYNC_SUCCESS,
        };

        addToolbarNotification(COOKIE_SYNC_SUCCESS);
      }
      // Cookie Sync is enabled but fails to sync due to extension error
      else if (error === "EXTENSION_REQUEST_ERROR") {
        addToolbarNotification(COOKIE_SYNC_EXTENSION_ERROR.toolbarNotification);
        addFloatingNotification(
          COOKIE_SYNC_EXTENSION_ERROR.floatingToolbarNotification
        );
      }
      // Cookie Sync is enabled but fails to sync due to config error
      else if (error === "CONFIG_FETCH_ERROR") {
        addToolbarNotification(COOKIE_SYNC_CONFIG_ERROR.toolbarNotification);
        addFloatingNotification(
          COOKIE_SYNC_CONFIG_ERROR.floatingToolbarNotification
        );
      }
    }
  }, [cookieResult]);
};
