import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
  useToolbarCollapseStore,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import { NotificationId } from "../../../constants/Toolbar";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";
import { isFeatureEnabled } from "../../../configurations";
import { useMediaDeviceStore } from "../../../hooks/useMediaDeviceStore";

export const useCamera = (
  appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
  const { t } = useTranslation();
  const webcamEnabled = useToolbarPreferenceStore(
    (store) => store.webcamEnabled
  );

  const setWebcamEnabled = useToolbarPreferenceStore(
    (store) => store.setWebcamEnabled
  );

  const selectedWebcam = useMediaDeviceStore((store) => store.selectedWebcam);

  const setSelectedWebcam = useMediaDeviceStore(
    (store) => store.setSelectedWebcam
  );

  const [loading, setLoading] = useState(false);

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const collapsed = useToolbarCollapseStore((store) => store.collapsed);

  const cameraNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.cameraNotEnabled.header"),
    content: t("notification.cameraNotEnabled.content"),
    notificationId: NotificationId.CAMERA_NOT_ENABLED_ERROR,
  });

  const cameraNotDisabledError = useFloatingAndToolbarNotification({
    header: t("notification.cameraNotDisabled.content"),
    notificationId: NotificationId.CAMERA_NOT_DISABLED_ERROR,
  });

  const isMediaDeviceSelectorFeatureEnabled = isFeatureEnabled(
    "mediaDeviceSelector"
  );

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.HEADLESS,
    ariaLabel: t("toolbar.item.camera.ariaLabel"),
    isSelected: webcamEnabled,
    isLoading: loading,
    icon: <Icon data-testid="camera-deactivated" name="video-off" />,
    activeStateIcon: <Icon data-testid="camera-activated" name="video-on" />,
    onClick: async () => {
      setLoading(true);
      try {
        await appStreamEmbed.performActionPromise(
          AppStreamEmbedConstant.METHOD_SET_WEBCAM,
          selectedWebcam
            ? {
                webcamEnabled: !webcamEnabled,
                webcamDeviceId: selectedWebcam.deviceId,
              }
            : {
                webcamEnabled: !webcamEnabled,
              }
        );
        setWebcamEnabled(!webcamEnabled);
        if (collapsed) {
          if (!webcamEnabled) {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_MINI_VIEW_SUCCESS
            );
          } else {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.DISABLE_WEBCAM_MINI_VIEW_SUCCESS
            );
          }
        } else {
          if (!webcamEnabled) {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_EXPANDED_VIEW_SUCCESS
            );
          } else {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.DISABLE_WEBCAM_EXPANDED_VIEW_SUCCESS
            );
          }
        }
      } catch {
        if (webcamEnabled === false) {
          addFloatingNotification(
            cameraNotEnabledError.floatingToolbarNotification
          );
          addToolbarNotification(cameraNotEnabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_ERROR);
        } else {
          addFloatingNotification(
            cameraNotDisabledError.floatingToolbarNotification
          );
          addToolbarNotification(cameraNotDisabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.DISABLE_WEBCAM_ERROR);
        }
      }
      /**
       * The actual camera setting in the remote session and our state in local browser
       * can be different when a user clicks the button multiple times in a short period.
       * To prevent any unexpected result, explicitly add some delay after clicking the button.
       */
      setTimeout(() => {
        setLoading(false);
      }, 500);
    },
  };
};
