import React, { useState, useEffect } from "react";
import logo from "../../resources/WSW_logo.png";
import wsbLogo from "../../resources/WSB_logo_white.png";
import { ProgressIndicator } from "@amzn/aws-euc-ui";
import "./style.css";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { isFeatureEnabled } from "../../configurations";

// Checked the `SessionStartedResponseTime` metric which represent the time between rendering initial session page and actual starting a session.
// We excluded metrics less than 20 seconds or longer than 10 minutes which might be re-visiting a session or some exceptional situations.
// Based on we found from logs, we can choose p70 (40 seconds) for AS2 session loading time, and add 25 seconds to render components and load
// resources from server.
// Analysis: https://monitorportal.amazon.com/snap/load/snap.241007191234.HGTmur7v.png
const SESSION_START_TIME = 65000;

const Loader = ({ message }) => {
  const { t } = useTranslation();
  const progress = useSpinnerProgress(SESSION_START_TIME);
  const isDeterminateProgressBar =
    (message === "Connecting..." ||
      message === "Preparing your applications...") &&
    progress < 100;

  // Enum object mapping English messages from AS2 to localized keys
  const MessageEnum = {
    "Connecting...": t("euc.loading.screen.connecting.message"),
    "Resizing...": t("euc.loading.screen.resizing.message"),
    "Reserving your session...": t("euc.loading.screen.reserving.message"),
    "Preparing your applications...": t(
      "euc.loading.screen.connecting.message"
    ),
  };
  const localizedMessage = MessageEnum[message] ?? message;
  const logoToUse = isFeatureEnabled("rebrand") ? wsbLogo : logo;
  return (
    <div className={"euc-loader-container-style On-top"}>
      <ProgressIndicator
        header={
          <div className="euc-loader-header-container">
            <img className="euc-loader-header-image" src={logoToUse} />
          </div>
        }
        indicatorStyle={{
          width: "260px",
        }}
        determinate={isDeterminateProgressBar}
        progress={progress}
        size="cover"
        footer={
          <div className="euc-loader-footer-container">
            {localizedMessage && (
              <span className="euc-loader-footer-span">{localizedMessage}</span>
            )}
          </div>
        }
      />
    </div>
  );
};

const useSpinnerProgress = (time = 0) => {
  const [progress, setProgress] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevNumber) => {
        if (prevNumber === 100) {
          clearInterval(interval);
          return prevNumber;
        }
        return prevNumber + 1;
      });
    }, time / 100);

    return () => clearInterval(interval);
  }, []);

  return progress;
};

Loader.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Loader;
