import { URL_PARAMETER } from "../constants";
import { cookieManagerInstance } from "../cookies";
import { CustomCookieIdentifier } from "../constants/ShortbreadConfig";
import { useEffect, useState } from "react";
import { ExperimentalFlags } from "../types";

type SetCookiesFromUrlParamsStatus = "in-progress" | "completed";

export const useSetCookiesFromUrlParams = (): SetCookiesFromUrlParamsStatus => {
  const [status, setStatus] = useState<SetCookiesFromUrlParamsStatus>(
    "in-progress"
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(document.location.search);
    setStatus("in-progress");
    setExperimentalFlagsFromUrlParams(urlParams);
    setStatus("completed");
  }, []);

  return status;
};

/**
 * If the experimentalToolbar URL parameter is empty, we will use the previous setting.
 * Flag value will be converted into boolean type and the value will be `true` if it is
 * case-insensitively equal to "true"
 */
const setExperimentalFlag = (
  ...flags: {
    flagName: URL_PARAMETER;
    flagValue: string | undefined;
  }[]
) => {
  let experimentalFlags: ExperimentalFlags;
  try {
    experimentalFlags = JSON.parse(
      cookieManagerInstance.getSessionStorage(
        CustomCookieIdentifier.EXPERIMENTAL.name
      ) ?? "{}"
    );
  } catch {
    experimentalFlags = {};
  }

  const updateExperimentalFlags = flags
    .filter(({ flagValue }) => flagValue != null)
    .reduce(
      (result, { flagName, flagValue }) => ({
        ...result,
        [flagName]: /true/i.test(flagValue),
      }),
      experimentalFlags
    );

  cookieManagerInstance.setSessionStorage(
    CustomCookieIdentifier.EXPERIMENTAL.name,
    JSON.stringify(updateExperimentalFlags)
  );
};

const setExperimentalFlagsFromUrlParams = (urlParams: URLSearchParams) => {
  const flags = [
    URL_PARAMETER.LEGACY,
    URL_PARAMETER.APPS,
    URL_PARAMETER.DIRECT_IMPORT,
    URL_PARAMETER.MOBILE_DEVICE,
  ].map((param) => ({ flagName: param, flagValue: urlParams.get(param) }));
  setExperimentalFlag(...flags);
};
