import React, { useState } from "react";
import {
  Box,
  Button,
  Popover,
  SpaceBetween,
  StatusIndicator,
  StatusIndicatorProps,
} from "@cloudscape-design/components";

interface CopyButtonProps {
  copyText: string;
  successPopoverText: React.ReactNode;
  errorPopoverText: React.ReactNode;
  copyIconAriaLabel: string;
  children?: React.ReactNode;
}

/**
 * Add a copy icon to the right side of the text. When a user clicks the icon, this component copies the text to the
 * clipboard and shows a popover with a success icon and "copied" message.
 */
export const CopyButton = ({
  copyText,
  successPopoverText,
  errorPopoverText,
  copyIconAriaLabel,
  children,
}: CopyButtonProps): JSX.Element => {
  const [statusType, setStatusType] = useState<StatusIndicatorProps.Type>(
    "success"
  );
  const [popoverText, setPopoverText] = useState(successPopoverText);

  return (
    <Box display={"inline-block"}>
      <Popover
        size="small"
        position="top"
        triggerType="custom"
        dismissButton={false}
        content={
          <StatusIndicator type={statusType}>{popoverText}</StatusIndicator>
        }
      >
        <Button
          iconName="copy"
          variant="inline-icon"
          ariaLabel={copyIconAriaLabel}
          onClick={() => {
            navigator.clipboard
              .writeText(copyText)
              .then(() => {
                setStatusType("success");
                setPopoverText(successPopoverText);
              })
              .catch(() => {
                setStatusType("error");
                setPopoverText(errorPopoverText);
                console.warn(`Cannot copy the content: ${copyText}`);
              });
          }}
        />
      </Popover>
      {children}
    </Box>
  );
};
