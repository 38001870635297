import React, { useState } from "react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import { useTranslation } from "react-i18next";

const IncompletePortalAlert = (): JSX.Element => {
  const [visible, setVisible] = useState(true);
  const { t } = useTranslation();

  return (
    <Alert
      onDismiss={() => setVisible(false)}
      visible={visible}
      dismissLabel="Close alert"
      type="warning"
    >
      {t("portalStatus.alert.incomplete")}
    </Alert>
  );
};
export default IncompletePortalAlert;
