// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import log from "../../../../logging";
import { ErmineServerSideRenderingService } from "@amzn/ermine-ssr-sdk";
import { DEFAULT_CLIENT_CONFIG, getEnvInfo } from "../../../../configurations";
import {
  SignOutRequest,
  SignOutResponse,
} from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import { AWSError } from "aws-sdk";

const SignOut = (): JSX.Element | null => {
  const { region } = getEnvInfo();

  const ermineService = new ErmineServerSideRenderingService({
    ...DEFAULT_CLIENT_CONFIG,
    region: region,
    endpoint: `${window.location.origin}`,
    maxRetries: 3,
  });

  ermineService.makeUnauthenticatedRequest(
    "signOut",
    {} as SignOutRequest,
    (err: AWSError, data: SignOutResponse) => {
      if (err) {
        log.error(`SSOSignOut ${err.statusCode} | ${err.code}: ${err.message}`);
      } else {
        window.location.href = data.Location;
      }
    }
  );

  return null;
};

export default SignOut;
