// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { Auth } from "aws-amplify";
import log from "../../../../logging";

const SignOut = (): JSX.Element | null => {
  Auth.signOut()
    .then(() => {})
    .catch((e) => {
      log.error("Error occurred when signing out", e);
    });

  return null;
};

export default SignOut;
