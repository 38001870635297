import { create } from "zustand";
import { ToolbarProfileTabId } from "../constants/Toolbar";

interface ToolbarProfileTabIdStore {
  activeId: ToolbarProfileTabId;
  setActiveId: (id: ToolbarProfileTabId) => void;
}

/**
 * Store for managing opened tab in the Profile toolbar item
 */
export const useToolbarProfileTabIdStore = create<ToolbarProfileTabIdStore>(
  (store) => ({
    activeId: ToolbarProfileTabId.SESSION_PERFORMANCE,
    setActiveId: (id) =>
      store((state) => ({
        ...state,
        activeId: id,
      })),
  })
);
