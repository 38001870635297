// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { getAuthType, isFeatureEnabled } from "../../../configurations";
import { AuthType } from "../../../enums";
import { AuthStrategy } from "../../../types";
import { CognitoAuthStrategy } from "../cognito/CognitoAuthStrategy";
import { SSOAuthStrategy } from "../sso/SSOAuthStrategy";

const SignOut = (): JSX.Element | null => {
  const authType = getAuthType();
  const isUnificationEnabled = isFeatureEnabled("unification");

  let authStrategy: AuthStrategy;
  switch (authType) {
    case AuthType.STANDARD: {
      authStrategy = isUnificationEnabled
        ? new SSOAuthStrategy()
        : new CognitoAuthStrategy();
      break;
    }
    case AuthType.IAM_IDENTITY_CENTER: {
      authStrategy = new SSOAuthStrategy();
      break;
    }
  }

  return authStrategy.signOut();
};

export default SignOut;
