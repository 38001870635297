import React from "react";
import { getCognitoConfig } from "../../../configurations";
import { AuthStatus } from "../../../enums";
import { AuthStrategy } from "../../../types";
import { ICredentials } from "@aws-amplify/core";
import IncompletePortalAlert from "./IncompletePortalAlert";
import CognitoConfigurator from "./Configurator";
import CognitoAuthChecker from "./AuthChecker";
import CognitoAuthButton from "./AuthButton";
import EUCCognitoAuthButton from "./EUCAuthButton";
import CognitoSignOut from "./SignOut";
import { getLegacyFlag } from "../../../utils/toolbarItemUtils";

export class CognitoAuthStrategy implements AuthStrategy {
  public configure(onConfigured: () => void): JSX.Element {
    return <CognitoConfigurator onConfigured={onConfigured} />;
  }

  public checkAuth(
    onAuthCheck: (
      currentAuthStatus: AuthStatus,
      credentials?: ICredentials
    ) => void
  ): JSX.Element {
    return <CognitoAuthChecker onAuthCheck={onAuthCheck} />;
  }

  public incomplete(): JSX.Element | null {
    const { hasIdentityProvider } = getCognitoConfig();
    return !hasIdentityProvider ? <IncompletePortalAlert /> : null;
  }

  public authButton(): JSX.Element {
    const { hasIdentityProvider } = getCognitoConfig();
    return getLegacyFlag() ? (
      <CognitoAuthButton hasIdentityProvider={hasIdentityProvider} />
    ) : (
      <EUCCognitoAuthButton hasIdentityProvider={hasIdentityProvider} />
    );
  }

  public signOut(): JSX.Element | null {
    return <CognitoSignOut />;
  }
}

export default CognitoAuthStrategy;
