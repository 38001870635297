import {
  Alert,
  ColumnLayout,
  FormField,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect } from "react";
import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import useMicrophoneDevices from "../../../../hooks/useMicrophoneDevices";
import { useMediaDeviceStore } from "../../../../hooks/useMediaDeviceStore";
import useCameraDevices from "../../../../hooks/useCameraDevices";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import { AppStreamEmbedConstant } from "../../../../constants";
import log, { TOOLBAR_METRIC_NAME } from "../../../../logging";
import { useFloatingAndToolbarNotification } from "../notification/useNotification";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../../hooks/useNotificationStore";
import { NotificationId } from "../../../../constants/Toolbar";
import { isFeatureEnabled } from "../../../../configurations";

export const MicrophoneSelector = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  const { t } = useTranslation();

  const isMicrophonePermissionAllowed = useMediaDeviceStore(
    (state) => state.isMicrophonePermissionAllowed
  );
  const setIsMicrophonePermissionAllowed = useMediaDeviceStore(
    (state) => state.setIsMicrophonePermissionAllowed
  );

  useEffect(() => {
    checkMicrophonePermission();
  }, []);

  const checkMicrophonePermission = async () => {
    try {
      const result = await navigator.permissions.query({
        name: "microphone" as PermissionName,
      });
      log.publishCounterMetric(
        TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_SUCCESS
      );
      setIsMicrophonePermissionAllowed(result.state === "granted");

      // Listen for changes in permission state
      result.onchange = () => {
        setIsMicrophonePermissionAllowed(result.state === "granted");
      };
    } catch (error) {
      console.error("Error checking microphone permission:", error);
      log.publishCounterMetric(
        TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_ERROR
      );
    }
  };

  const microphonePermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="microphonePermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.microphone.permissionAlert.content"
        }
      />
    </Alert>
  );

  const microphones = useMicrophoneDevices(appStreamSdk);
  const microphoneOptions = microphones.map((option) => {
    return { label: option.label, value: option.deviceId };
  });

  const selectedMicrophone = useMediaDeviceStore(
    (state) => state.selectedMicrophone
  );
  const setSelectedMicrophone = useMediaDeviceStore(
    (state) => state.setSelectedMicrophone
  );

  const microphoneEnabled = useToolbarPreferenceStore(
    (store) => store.microphoneEnabled
  );

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const microphoneNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotEnabled.header"),
    content: t("notification.microphoneNotEnabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_ENABLED_ERROR,
  });

  const microphoneNotDisabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotDisabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_DISABLED_ERROR,
  });

  const [selectedOption, setSelectedOption] = React.useState({
    label: selectedMicrophone ? selectedMicrophone.label : undefined,
    value: selectedMicrophone ? selectedMicrophone.deviceId : undefined,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.label"
      )}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.microphone.description"
      )}
      data-testid="microphoneFormField"
    >
      {isMicrophonePermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={async ({ detail }) => {
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            });
            if (microphoneEnabled) {
              try {
                await appStreamSdk.performAction(
                  AppStreamEmbedConstant.METHOD_SET_MICROPHONE,
                  {
                    microphoneEnabled: microphoneEnabled,
                    microphoneDeviceId: detail.selectedOption.value,
                  }
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.SELECT_MICROPHONE_DEVICE_SUCCESS
                );
                setSelectedMicrophone({
                  label: detail.selectedOption.label,
                  deviceId: detail.selectedOption.value,
                });
              } catch {
                addFloatingNotification(
                  microphoneNotEnabledError.floatingToolbarNotification
                );
                addToolbarNotification(
                  microphoneNotEnabledError.toolbarNotification
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_ERROR
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.SELECT_MICROPHONE_DEVICE_ERROR
                );
              }
            } else {
              setSelectedMicrophone({
                label: detail.selectedOption.label,
                deviceId: detail.selectedOption.value,
              });
            }
          }}
          options={microphoneOptions}
          placeholder={t(
            "toolbar.item.preference.content.tab.audioVideo.microphone.placeholder"
          )}
          data-testid="microphoneSelectMenu"
        />
      ) : (
        microphonePermissionNotAllowedNotification
      )}
    </FormField>
  );
};

export const CameraSelector = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  const { t } = useTranslation();

  const isWebcamPermissionAllowed = useMediaDeviceStore(
    (state) => state.isWebcamPermissionAllowed
  );
  const setIsWebcamPermissionAllowed = useMediaDeviceStore(
    (state) => state.setIsWebcamPermissionAllowed
  );

  useEffect(() => {
    checkCameraPermission();
  }, []);

  const checkCameraPermission = async () => {
    try {
      const result = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      setIsWebcamPermissionAllowed(result.state === "granted");
      log.publishCounterMetric(
        TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_SUCCESS
      );

      // Listen for changes in permission state
      result.onchange = () => {
        setIsWebcamPermissionAllowed(result.state === "granted");
      };
    } catch (error) {
      console.error("Error checking camera permission:", error);
      log.publishCounterMetric(
        TOOLBAR_METRIC_NAME.GET_BROWSER_PERMISSIONS_ERROR
      );
    }
  };

  const cameraPermissionNotAllowedNotification = (
    <Alert
      statusIconAriaLabel="Warning"
      type="warning"
      data-testId="cameraPermissionAlert"
      header={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.header"
      )}
    >
      <Trans
        i18nKey={
          "toolbar.item.preference.content.tab.audioVideo.camera.permissionAlert.content"
        }
      />
    </Alert>
  );

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const cameraNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.cameraNotEnabled.header"),
    content: t("notification.cameraNotEnabled.content"),
    notificationId: NotificationId.CAMERA_NOT_ENABLED_ERROR,
  });

  const cameraDevices = useCameraDevices(appStreamSdk);
  const cameraOptions = (cameraDevices || []).map((option) => {
    return { label: option.label, value: option.deviceId };
  });

  const selectedWebcam = useMediaDeviceStore((state) => state.selectedWebcam);
  const setSelectedWebcam = useMediaDeviceStore(
    (state) => state.setSelectedWebcam
  );
  const webcamEnabled = useToolbarPreferenceStore(
    (store) => store.webcamEnabled
  );

  const [selectedOption, setSelectedOption] = React.useState({
    label: selectedWebcam ? selectedWebcam.label : undefined,
    value: selectedWebcam ? selectedWebcam.deviceId : undefined,
  });

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.audioVideo.camera.label")}
      description={t(
        "toolbar.item.preference.content.tab.audioVideo.camera.description"
      )}
      data-testid="cameraFormField"
    >
      {isWebcamPermissionAllowed ? (
        <Select
          selectedOption={selectedOption}
          onChange={async ({ detail }) => {
            setSelectedOption({
              label: detail.selectedOption.label,
              value: detail.selectedOption.value,
            });
            if (webcamEnabled) {
              try {
                // first turn off the previous selected camera
                await appStreamSdk.performAction(
                  AppStreamEmbedConstant.METHOD_SET_WEBCAM,
                  {
                    webcamEnabled: false,
                  }
                );
                // then enable the selected camera
                await appStreamSdk.performAction(
                  AppStreamEmbedConstant.METHOD_SET_WEBCAM,
                  {
                    webcamEnabled: true,
                    webcamDeviceId: detail.selectedOption.value,
                  }
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_EXPANDED_VIEW_SUCCESS
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.SELECT_WEBCAM_DEVICE_SUCCESS
                );
                setSelectedWebcam({
                  label: detail.selectedOption.label,
                  deviceId: detail.selectedOption.value,
                });
              } catch {
                addFloatingNotification(
                  cameraNotEnabledError.floatingToolbarNotification
                );
                addToolbarNotification(
                  cameraNotEnabledError.toolbarNotification
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_ERROR
                );
                log.publishCounterMetric(
                  TOOLBAR_METRIC_NAME.SELECT_WEBCAM_DEVICE_ERROR
                );
              }
            } else {
              setSelectedWebcam({
                label: detail.selectedOption.label,
                deviceId: detail.selectedOption.value,
              });
            }
          }}
          options={cameraOptions}
          placeholder={t(
            "toolbar.item.preference.content.tab.audioVideo.camera.placeholder"
          )}
          data-testid="cameraSelectMenu"
        />
      ) : (
        cameraPermissionNotAllowedNotification
      )}
    </FormField>
  );
};

/**
 * Preferences - Audio & Video tab content
 */
export const AudioVideoTabContent = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  const isDisableWebcamFeatureEnabled = isFeatureEnabled("disableWebcam");

  return (
    <ColumnLayout columns={2} data-testId="audio-video-tab-content">
      <SpaceBetween direction="vertical" size="m">
        <MicrophoneSelector
          appStreamSdk={appStreamSdk}
          data-testId="microphone-selector-audioVideoTab"
        />
      </SpaceBetween>
      {!isDisableWebcamFeatureEnabled && (
        <SpaceBetween direction="vertical" size="m">
          <CameraSelector
            appStreamSdk={appStreamSdk}
            data-testId="camera-selector-audioVideoTab"
          />
        </SpaceBetween>
      )}
    </ColumnLayout>
  );
};
