import {
  Button,
  ColumnLayout,
  FormField,
  Header,
  RadioGroup,
  SpaceBetween,
  Toggle,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { AppStreamSdkProps } from "../../../../types/Toolbar";
import { useToolbarPreferenceStore } from "../../../../hooks/useToolbarPreferenceStore";
import { Trans, useTranslation } from "react-i18next";
import { Mode } from "@cloudscape-design/global-styles";
import { AppStreamEmbedConstant } from "../../../../constants";
import { usePreferenceNotificationStore } from "../../../../hooks/useNotificationStore";
import {
  NotificationItem,
  resetToolbarTutorialStore,
  useToolbarContentItemIdStore,
} from "@amzn/aws-euc-ui";
import { useNotification } from "../notification/useNotification";
import { useAppStreamApplications } from "../../../../hooks/useAppStreamApplications";
import log, { TOOLBAR_METRIC_NAME } from "../../../../logging";
import { ToolbarMode } from "../../../../constants/Toolbar";
import { isFeatureEnabled } from "../../../../configurations";
import { useToolbarIconLabelStore } from "@amzn/aws-euc-ui/dist/toolbar/hooks/useToolbarIconLabelStore";
import { setToolbarIconLabelsEnabledForCookie } from "../../../../utils/toolbarSettingsUtils";
import { getMobileDeviceFlag } from "../../../../utils/toolbarItemUtils";

const VisualModeFormField = (): JSX.Element => {
  const { t } = useTranslation();

  const visualMode = useToolbarPreferenceStore((store) => store.visualMode);

  const setVisualMode = useToolbarPreferenceStore(
    (store) => store.setVisualMode
  );

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.general.appearance.label")}
    >
      <RadioGroup
        onChange={({ detail }) => {
          setVisualMode(detail.value as Mode);
          if (detail.value === Mode.Dark) {
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.SET_DARK_MODE);
          } else {
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.SET_LIGHT_MODE);
          }
        }}
        value={visualMode}
        items={[
          {
            value: Mode.Dark,
            label: t(
              "toolbar.item.preference.content.tab.general.visualMode.dark"
            ),
          },
          {
            value: Mode.Light,
            label: t(
              "toolbar.item.preference.content.tab.general.visualMode.light"
            ),
          },
        ]}
      />
    </FormField>
  );
};

const ToolbarModeFormField = (): JSX.Element => {
  const { t } = useTranslation();

  const toolbarMode = useToolbarPreferenceStore((store) => store.toolbarMode);

  const setToolbarMode = useToolbarPreferenceStore(
    (store) => store.setToolbarMode
  );

  const closeAllToolbarContent = useToolbarContentItemIdStore(
    (store) => store.clearExpandedItemId
  );

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.general.toolbarMode.label")}
    >
      <RadioGroup
        onChange={({ detail }) => {
          setToolbarMode(detail.value as ToolbarMode);
          closeAllToolbarContent();
          resetToolbarTutorialStore();
          if (detail.value === ToolbarMode.Floating) {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SET_FLOATING_TOOLBAR_MODE
            );
          } else {
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SET_DOCKED_TOOLBAR_MODE
            );
          }
        }}
        value={toolbarMode}
        items={[
          {
            value: ToolbarMode.Floating,
            label: t(
              "toolbar.item.preference.content.tab.general.toolbarMode.detached.label"
            ),
            description: t(
              "toolbar.item.preference.content.tab.general.toolbarMode.detached.description"
            ),
          },
          {
            value: ToolbarMode.Docked,
            label: t(
              "toolbar.item.preference.content.tab.general.toolbarMode.docked.label"
            ),
            description: t(
              "toolbar.item.preference.content.tab.general.toolbarMode.docked.description"
            ),
          },
        ]}
      />
    </FormField>
  );
};

const KeyboardInputMethodButton = ({ appStreamSdk }: AppStreamSdkProps) => {
  const { t } = useTranslation();

  const setApplications = useAppStreamApplications(
    (store) => store.setApplications
  );
  const keyboardInputToggleApplicationName = useAppStreamApplications(
    (store) => store.keyboardInputToggleApplicationName
  );
  const [loading, setLoading] = useState(false);
  const disabled = !loading && keyboardInputToggleApplicationName == null;

  useEffect(() => {
    if (keyboardInputToggleApplicationName == null) {
      setLoading(true);
      appStreamSdk
        .getApplications()
        .then(setApplications)
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const addPreferenceNotification = usePreferenceNotificationStore(
    (store) => store.addNotification
  );
  const removePreferenceNotification = usePreferenceNotificationStore(
    (store) => store.removeNotification
  );
  const toggleImeNotification = useNotification({
    header: t("notification.fail.toggleIme.content"),
    removeNotification: removePreferenceNotification,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.general.keyboardInputMethod.label"
      )}
      description={t(
        "toolbar.item.preference.content.tab.general.keyboardInputMethod.description"
      )}
      errorText={
        disabled ? t("notification.fail.fetchApplication.content") : undefined
      }
    >
      <Button
        loading={loading}
        disabled={disabled}
        onClick={() => {
          setLoading(true);
          try {
            // If `keyboardInputToggleApplicationName` is empty, the button should be disabled.
            appStreamSdk.launchApp(keyboardInputToggleApplicationName);
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.IME_TOGGLE_SUCCESS);
          } catch {
            addPreferenceNotification(toggleImeNotification);
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.IME_TOGGLE_ERROR);
          }

          /*
           * We don't know when the `launchApp` function completed the action, but on average,
           * it takes about 2 seconds to actually toggle the IME toolbar in the remote session.
           * So we will manually make the button loading state for 2 seconds.
           */
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }}
      >
        <Trans
          i18nKey={
            "toolbar.item.preference.content.tab.general.keyboardInputMethod.button.label"
          }
        />
      </Button>
    </FormField>
  );
};

const DisplayResolutionFormField = ({ appStreamSdk }: AppStreamSdkProps) => {
  const { t } = useTranslation();

  const displayResolution = useToolbarPreferenceStore(
    (store) => store.displayResolution
  );
  const setDisplayResolution = useToolbarPreferenceStore(
    (store) => store.setDisplayResolution
  );

  const addNotification = usePreferenceNotificationStore(
    (store) => store.addNotification
  );

  const removeNotification = usePreferenceNotificationStore(
    (store) => store.removeNotification
  );

  const SET_RESOLUTION_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.setResolution.content"),
    removeNotification: removeNotification,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.general.displayResolution.label"
      )}
    >
      <RadioGroup
        onChange={async ({ detail }) => {
          const screenResolution = detail.value as AppStreamEmbedConstant.Resolutions;
          try {
            await appStreamSdk.performActionPromise(
              AppStreamEmbedConstant.METHOD_SET_SCREEN_RESOLUTION,
              {
                screenResolution,
              }
            );
            setDisplayResolution(screenResolution);
          } catch {
            addNotification(SET_RESOLUTION_ERROR);
          }
        }}
        value={displayResolution}
        items={[
          {
            value:
              AppStreamEmbedConstant.Resolutions.KEEP_REMOTE_SERVER_DISPLAY,
            label: t(
              "toolbar.item.preference.content.tab.general.displayResolution.keepRemoteServerDisplay.label"
            ),
          },
          {
            value: AppStreamEmbedConstant.Resolutions.MATCH_LOCAL_DISPLAY,
            label: t(
              "toolbar.item.preference.content.tab.general.displayResolution.matchLocalDisplay.label"
            ),
          },
          {
            value: AppStreamEmbedConstant.Resolutions.SIZE_800_X_600,
            label: "800 x 600",
          },
          {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1024_X_768,
            label: "1024 x 768",
          },
          {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1280_X_720,
            label: "1280 x 720",
          },
          {
            value: AppStreamEmbedConstant.Resolutions.SIZE_1920_X_1080,
            label: "1920 x 1080",
          },
          {
            value: AppStreamEmbedConstant.Resolutions.SIZE_2560_X_1440,
            label: "2560 x 1440",
          },
        ]}
      />
    </FormField>
  );
};

const StreamingModeFormField = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  const { t } = useTranslation();

  const streamingMode = useToolbarPreferenceStore(
    (store) => store.streamingMode
  );
  const setStreamingMode = useToolbarPreferenceStore(
    (store) => store.setStreamingMode
  );

  const addNotification = usePreferenceNotificationStore(
    (store) => store.addNotification
  );

  const removeNotification = usePreferenceNotificationStore(
    (store) => store.removeNotification
  );

  const SET_STREAMING_MODE_ERROR: NotificationItem = useNotification({
    header: t("notification.fail.setStreamingMode.content"),
    removeNotification: removeNotification,
  });

  return (
    <FormField
      label={t(
        "toolbar.item.preference.content.tab.general.streamingMode.label"
      )}
    >
      <RadioGroup
        onChange={async ({ detail }) => {
          const selected = detail.value as AppStreamEmbedConstant.StreamingMode;
          try {
            await appStreamSdk.performActionPromise(
              AppStreamEmbedConstant.METHOD_SET_STREAMING_MODE,
              {
                streamingMode: selected,
              }
            );
            setStreamingMode(selected);
            if (
              selected === AppStreamEmbedConstant.StreamingMode.BEST_QUALITY
            ) {
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.STREAMING_BEST_QUALITY_SUCCESS
              );
            } else {
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.STREAMING_BEST_RESPONSIVE_SUCCESS
              );
            }
          } catch {
            addNotification(SET_STREAMING_MODE_ERROR);
            if (
              selected === AppStreamEmbedConstant.StreamingMode.BEST_QUALITY
            ) {
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.STREAMING_BEST_QUALITY_ERROR
              );
            } else {
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.STREAMING_BEST_RESPONSIVE_ERROR
              );
            }
          }
        }}
        value={streamingMode}
        items={[
          {
            value: AppStreamEmbedConstant.StreamingMode.BEST_RESPONSIVENESS,
            label: t(
              "toolbar.item.preference.content.tab.general.streamingMode.bestResponsiveness.label"
            ),
            description: t(
              "toolbar.item.preference.content.tab.general.streamingMode.bestResponsiveness.description"
            ),
          },
          {
            value: AppStreamEmbedConstant.StreamingMode.BEST_QUALITY,
            label: t(
              "toolbar.item.preference.content.tab.general.streamingMode.bestQuality.label"
            ),
            description: t(
              "toolbar.item.preference.content.tab.general.streamingMode.bestQuality.description"
            ),
          },
        ]}
      />
    </FormField>
  );
};

const ToolbarIconStringToggle = (): JSX.Element => {
  const { t } = useTranslation();

  const toolbarIconLabelsEnabled = useToolbarIconLabelStore(
    (store) => store.toolbarIconLabelsEnabled
  );
  const setToolbarIconLabelsEnabled = useToolbarIconLabelStore(
    (store) => store.setToolbarIconLabelsEnabled
  );

  return (
    <FormField
      label={t("toolbar.item.preference.content.tab.general.iconLabel.label")}
    >
      <Toggle
        onChange={({ detail }) => {
          setToolbarIconLabelsEnabled(detail.checked);
          setToolbarIconLabelsEnabledForCookie(detail.checked);
        }}
        checked={toolbarIconLabelsEnabled}
      >
        {toolbarIconLabelsEnabled
          ? t("toolbar.item.preference.content.tab.general.iconLabel.enable")
          : t("toolbar.item.preference.content.tab.general.iconLabel.disable")}
      </Toggle>
    </FormField>
  );
};

/**
 * Preferences - General tab content
 */
export const GeneralTabContent = ({
  appStreamSdk,
}: AppStreamSdkProps): JSX.Element => {
  const isDefaultDockedToolbar = isFeatureEnabled(
    "defaultDockedToolbarExperience"
  );
  const isMobileFlagOn = getMobileDeviceFlag();

  return (
    <ColumnLayout columns={2}>
      <SpaceBetween direction={"vertical"} size={"m"}>
        <Header>
          <Trans
            i18nKey={
              "toolbar.item.preference.content.tab.general.experience.label"
            }
          />
        </Header>
        <VisualModeFormField />
        {!isMobileFlagOn && !isDefaultDockedToolbar && <ToolbarModeFormField />}
        {!isMobileFlagOn && <ToolbarIconStringToggle />}
        <KeyboardInputMethodButton appStreamSdk={appStreamSdk} />
      </SpaceBetween>
      <SpaceBetween direction={"vertical"} size={"m"}>
        <Header>
          <Trans
            i18nKey={
              "toolbar.item.preference.content.tab.general.display.label"
            }
          />
        </Header>
        <StreamingModeFormField appStreamSdk={appStreamSdk} />
        {!isMobileFlagOn && (
          <DisplayResolutionFormField appStreamSdk={appStreamSdk} />
        )}
      </SpaceBetween>
    </ColumnLayout>
  );
};
