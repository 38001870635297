// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { getCognitoConfig } from "../../../../configurations";

interface Props {
  onConfigured: () => void;
}

const AmplifyConfigurator = ({ onConfigured }: Props): JSX.Element => {
  useEffect(() => {
    const { amplifyConfig } = getCognitoConfig();

    Amplify.configure({
      Auth: {
        identityPoolId: amplifyConfig.aws_cognito_identity_pool_id,
        region: amplifyConfig.aws_cognito_region,
        userPoolId: amplifyConfig.aws_user_pools_id,
        userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id,
        mandatorySignIn: true,
        // Call Cognito FIPS endpoints if the portal is FIPS enabled
        ...(amplifyConfig.aws_cognito_endpoint
          ? {
              // Override cognito-idp endpoint
              endpoint: amplifyConfig.aws_cognito_endpoint,
              // Use this special region to make Amplify call FIPS cognito-identity endpoint
              identityPoolRegion: `fips-${amplifyConfig.aws_cognito_region}`,
            }
          : {}),
      },
      oauth: amplifyConfig.oauth,
      storage: sessionStorage,
    });
    onConfigured();
  }, [onConfigured]);
  return null;
};

export default AmplifyConfigurator;
