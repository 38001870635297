// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { ICredentials } from "@aws-amplify/core";
import React, { useMemo, useState } from "react";
import { getAuthType, isFeatureEnabled } from "../../../configurations";
import { AuthType, BrowserType } from "../../../enums";
import OidcBrowserCreator from "../browserCreators/OidcBrowserCreator";
import Sigv4BrowserCreator from "../browserCreators/Sigv4BrowserCreator";
import log, { METRIC_NAME } from "../../../logging";
import { ERROR_STATE } from "../../../constants";
import ErrorAlert from "../../general/ErrorAlert";
import { useTranslation } from "react-i18next";
import { loadClient } from "../../../utils";
import { AWSError } from "aws-sdk";
import {
  getDeviceBrowser,
  getDeviceModel,
  getDeviceOSName,
  getDeviceTouchInfo,
  getDeviceType,
  isInternalTestTraffic,
} from "../../../utils/userAgentUtils";
import {
  getDirectImportFlag,
  getLegacyFlag,
} from "../../../utils/toolbarItemUtils";
import { CookieResult } from "../../../types/cookies";
import {
  fetchStreamingURLCache,
  refreshStreamingURLCache,
} from "../browserCreators/StreamingURLCache";
import PresessionErrorModal from "../PresessionErrorModal";
import { getRebrandedTranslation } from "../../../i18n";

interface Props {
  onBrowserCreation: (browserType: BrowserType, endpoint: string) => void;
  sigv4Credentials?: ICredentials;
  cookieResult: CookieResult;
  onRedirectToLogin: (redirectToLogin: boolean) => void;
}

const BrowserFactory = (props: Props): JSX.Element => {
  const { onBrowserCreation, sigv4Credentials, cookieResult } = props;
  const authType = getAuthType();
  const isUnificationEnabled = isFeatureEnabled("unification");
  const cachedStreamingURL = fetchStreamingURLCache();
  const { t } = useTranslation();
  const isLegacy = useMemo(() => getLegacyFlag(), []);

  const [errorState, setErrorState] = useState({
    errorState: ERROR_STATE.NONE,
    errorHeader: "lease.errorAlert.serverError.header",
    errorMessage: "lease.errorAlert.serverError.message",
    requestId: undefined,
  });

  const onBrowserCreationError = (
    err: AWSError,
    metricName: METRIC_NAME,
    requestId: string
  ) => {
    const { name, statusCode, message } = err;
    log.publishHttpStatusMetric(metricName, statusCode);
    switch (name) {
      case "ResourceNotReadyException":
        setErrorState({
          errorState: ERROR_STATE.INFO,
          errorHeader: "lease.errorAlert.pendingPortal.header",
          errorMessage: "lease.errorAlert.pendingPortal.message",
          requestId,
        });
        break;
      case "AccessDeniedException":
        if (
          message?.includes(
            "Cannot access resource because account is deleted."
          )
        ) {
          setErrorState({
            errorState: ERROR_STATE.INFO,
            errorHeader: "lease.errorAlert.suspendedPortal.header",
            errorMessage: "lease.errorAlert.suspendedPortal.message",
            requestId,
          });
        } else {
          setErrorState({
            errorState: ERROR_STATE.ERROR,
            errorHeader: errorState.errorHeader,
            errorMessage: errorState.errorMessage,
            requestId,
          });
        }
        break;
      case "InternalServerException":
        if (message?.includes("V750620258")) {
          setErrorState({
            errorState: ERROR_STATE.WARNING,
            errorHeader: "lease.errorAlert.userAccessLoggingBug.header",
            errorMessage: "lease.errorAlert.userAccessLoggingBug.message",
            requestId,
          });
        } else {
          setErrorState({
            errorState: ERROR_STATE.ERROR,
            errorHeader: errorState.errorHeader,
            errorMessage: errorState.errorMessage,
            requestId,
          });
        }
        break;
      default:
        setErrorState({
          errorState: ERROR_STATE.ERROR,
          errorHeader: errorState.errorHeader,
          errorMessage: errorState.errorMessage,
          requestId,
        });
    }
  };

  const loadEmbedAppstreamClient = async (
    browserType: BrowserType,
    endpoint: string
  ) => {
    switch (browserType) {
      case BrowserType.APPSTREAM: {
        if (getLegacyFlag()) {
          await loadClient(`${process.env.PUBLIC_URL}/appstream_embed.js`);
        } else if (getDirectImportFlag()) {
          // Do nothing. Will import AppStreamEmbed directly
        } else {
          await loadClient(`${process.env.PUBLIC_URL}/appstream_embed_v2.js`);
        }
        onBrowserCreation(browserType, endpoint);
        break;
      }
      default: {
        setErrorState({
          errorState: ERROR_STATE.ERROR,
          errorHeader: errorState.errorHeader,
          errorMessage: errorState.errorMessage,
          requestId: undefined,
        });
        throw new Error("Invalid Browser created");
      }
    }
  };

  /**
   * Publishes a successful streaming URL fetch metrics along side caching
   * the streaming URL and initializing the load Appstream
   * Embed client instance.
   * @param browserType browser type corresponding to the streaming URL
   * @param endpoint streamingURL
   * @param metricName for the DP API used to get the streaming URL
   */
  const consumeFreshStreamingURL = async (
    browserType: BrowserType,
    endpoint: string,
    metricName: METRIC_NAME
  ) => {
    log.publishHttpStatusMetric(metricName, 200);

    if (!isInternalTestTraffic()) {
      publishDeviceMetrics();
    }

    // Refill cache and log warning in case it failed
    const isCacheWriteSuccess = refreshStreamingURLCache(endpoint, browserType);
    if (isCacheWriteSuccess === false) {
      log.warn("StreamingURL session cache write back failed.");
    }

    await loadEmbedAppstreamClient(browserType, endpoint);
  };

  /**
   * Logs a cache hit and then starts Appstream Embed client initialization
   * @param browserType browser type corresponding to the streaming URL
   * @param endpoint streamingURL
   */
  const consumeCachedStreamingURL = async (
    browserType: BrowserType,
    endpoint: string
  ) => {
    log.publishCounterMetric(METRIC_NAME.STREAMING_URL_CACHE_HIT);
    await loadEmbedAppstreamClient(browserType, endpoint);
  };

  /** Trigger the streaming URL fetch and AS2 Embed
   * initialization.
   *
   * The Session cache is first checked for valid cached StreamingURLs. If
   * there are no valid streaming URLs, we get the latest cookies if they haven't
   * been fetched yet.
   *
   * Since CookieResult is a useState param, when it gets updated, the element
   * is re-rendered, calling the backend to get a new streaming URL.
   *
   * Irrespective of if we have the streaming URL cached or not, we do a
   * credential fetch before we reach this flow.
   */
  if (errorState.errorState === ERROR_STATE.NONE) {
    if (cachedStreamingURL !== undefined) {
      const { streamingURL, browserType } = cachedStreamingURL;
      consumeCachedStreamingURL(browserType as BrowserType, streamingURL);
      return null;
    } else {
      switch (authType) {
        case AuthType.STANDARD: {
          return isUnificationEnabled ? (
            <OidcBrowserCreator
              onBrowserCreation={consumeFreshStreamingURL}
              onBrowserCreationError={onBrowserCreationError}
              cookies={cookieResult.cookies}
            />
          ) : (
            <Sigv4BrowserCreator
              onBrowserCreation={consumeFreshStreamingURL}
              onBrowserCreationError={onBrowserCreationError}
              sigv4Credentials={sigv4Credentials!}
              cookies={cookieResult.cookies}
            />
          );
        }
        case AuthType.IAM_IDENTITY_CENTER: {
          return (
            <OidcBrowserCreator
              onBrowserCreation={consumeFreshStreamingURL}
              onBrowserCreationError={onBrowserCreationError}
              cookies={cookieResult.cookies}
            />
          );
        }
        default: {
          log.error("Invalid authentication type");
          throw Error("Invalid authentication type");
        }
      }
    }
  }

  if (isLegacy) {
    return (
      <div className="awsui On-top">
        <ErrorAlert
          type={errorState.errorState}
          header={getRebrandedTranslation(errorState.errorHeader, t)}
          message={getRebrandedTranslation(errorState.errorMessage, t)}
          refreshButtonText={t("refresh.button.label")}
          onRefreshButtonClick={() => window.location.reload()}
          onDismissErrorAlert={() => {}}
        />
      </div>
    );
  }

  return (
    <PresessionErrorModal
      title={getRebrandedTranslation(errorState.errorHeader, t)}
      message={getRebrandedTranslation(errorState.errorMessage, t)}
      requestId={errorState.requestId}
      onDismiss={() => props.onRedirectToLogin(true)}
    />
  );
};

function publishDeviceMetrics(): void {
  log.publishMetricWithDimension(
    METRIC_NAME.DEVICE_BROWSER,
    getDeviceBrowser()
  );
  log.publishMetricWithDimension(METRIC_NAME.DEVICE_TYPE, getDeviceType());
  log.publishMetricWithDimension(METRIC_NAME.DEVICE_MODEL, getDeviceModel());
  log.publishMetricWithDimension(METRIC_NAME.DEVICE_OS, getDeviceOSName());
  log.publishMetricWithDimension(
    METRIC_NAME.DEVICE_TOUCHSCREEN,
    getDeviceTouchInfo()
  );
}

export default BrowserFactory;
