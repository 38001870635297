/**
 * Higher order function that takes an the identity provider name and returns a urlOpener function.
 * This function is used to intercept Amplify's OAuth navigation to the Cognito Hosted UI and replace
 * specific parameters (IdP name) needed to redirect to a specific Identity Provider directly for login
 * @param identityProviderName
 */
export function getCognitoBypassUrlOpener(
  identityProviderName: string
): (url: string) => Promise<void> {
  return async (url) => {
    const urlObj = new URL(url);

    const urlSearchParams = new URLSearchParams(urlObj.search);
    urlSearchParams.set("identity_provider", identityProviderName);

    urlObj.search = urlSearchParams.toString();

    window.location.href = urlObj.toString();
  };
}
