import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonStatefulModalProps,
  IToolbarButtonType,
  NotificationList,
  ToolbarActiveStateContentProps,
  ToolbarContainerItemContent,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { memo, useEffect, useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { Button, Header, SpaceBetween } from "@cloudscape-design/components";
import { ToolbarItemId, ToolbarMode } from "../../../constants/Toolbar";
import { Trans, useTranslation } from "react-i18next";
import { AppStreamEmbedConstant } from "../../../constants";
import { useAppStreamApplications } from "../../../hooks/useAppStreamApplications";
import { AppStreamSdkProps } from "../../../types/Toolbar";
import { NotificationItem } from "@amzn/aws-euc-ui/dist/notification-list/types";
import { useWindowsNotificationStore } from "../../../hooks/useNotificationStore";
import { useNotification } from "./notification/useNotification";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";

const WindowToolbarItemActiveContent = (
  toolbarState: string,
  { appStreamSdk }: AppStreamSdkProps
): JSX.Element => {
  const chromeBrowserApplicationName = useAppStreamApplications(
    (store) => store.chromeBrowserApplicationName
  );
  const setApplications = useAppStreamApplications(
    (store) => store.setApplications
  );
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const notificationList = useWindowsNotificationStore(
    (store) => store.notificationList
  );

  const addNotification = useWindowsNotificationStore(
    (store) => store.addNotification
  );

  const removeNotification = useWindowsNotificationStore(
    (store) => store.removeNotification
  );

  const LAUNCH_CHROME_ERROR: NotificationItem = useNotification({
    removeNotification: removeNotification,
    header: t("notification.fail.launchChrome.content"),
  });

  const SWITCH_WINDOW_ERROR: NotificationItem = useNotification({
    removeNotification: removeNotification,
    header: t("notification.fail.switchWindow.content"),
  });

  useEffect(() => {
    if (chromeBrowserApplicationName == null) {
      setLoading(true);
      appStreamSdk
        .getApplications()
        .then(setApplications)
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <SpaceBetween size={"s"}>
      {toolbarState === ToolbarMode.Floating && (
        <Header variant={"h2"}>
          <Trans i18nKey={"toolbar.item.windows.content.header"} />
        </Header>
      )}
      {notificationList.length > 0 && (
        <NotificationList
          items={notificationList}
          ariaLabel={t("notification.list.ariaLabel")}
        />
      )}
      <SpaceBetween direction={"horizontal"} size={"xl"}>
        <Button
          variant={"primary"}
          onClick={async () => {
            try {
              await appStreamSdk.performActionPromise(
                AppStreamEmbedConstant.METHOD_SWITCH_WINDOWS
              );
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.CYCLE_TO_NEXT_WINDOW_SUCCESS
              );
            } catch {
              addNotification(SWITCH_WINDOW_ERROR);
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.CYCLE_TO_NEXT_WINDOW_ERROR
              );
            }
          }}
        >
          <Trans
            i18nKey={
              "toolbar.item.windows.content.button.cycleToNextWindow.label"
            }
          />
        </Button>
        <Button
          loading={loading}
          disabled={!loading && chromeBrowserApplicationName == null}
          onClick={() => {
            try {
              appStreamSdk.launchApp(chromeBrowserApplicationName);
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.LAUNCH_CHROME_WINDOW_SUCCESS
              );
            } catch {
              addNotification(LAUNCH_CHROME_ERROR);
              log.publishCounterMetric(
                TOOLBAR_METRIC_NAME.LAUNCH_CHROME_WINDOW_ERROR
              );
            }
          }}
        >
          <Trans
            i18nKey={
              "toolbar.item.windows.content.button.launchChromeWindow.label"
            }
          />
        </Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};

const WindowsIcon = (): JSX.Element => {
  return (
    <Icon
      svg={
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9.5" cy="5.5" r="0.5" fill="currentColor" />
          <circle cx="11.5" cy="5.5" r="0.5" fill="currentColor" />
          <circle cx="13.5" cy="5.5" r="0.5" fill="currentColor" />
          <path d="M3.5 8H15" stroke="currentColor" stroke-width="1.5" />
          <rect
            x="3"
            y="3"
            width="13"
            height="13"
            stroke="currentColor"
            stroke-width="2"
          />
        </svg>
      }
    />
  );
};

export const useWindows = (
  appStreamSdk: AppStreamSDK,
  toolbarState: string
): IToolbarButtonProps | IToolbarButtonStatefulModalProps => {
  const { t } = useTranslation();

  if (toolbarState === ToolbarMode.Docked) {
    return {
      type: IToolbarButtonType.STATEFUL,
      id: ToolbarItemId.WINDOWS,
      label: t("toolbar.item.windows.label"),
      icon: <WindowsIcon />,
      onClick: () => {},
      activeStateContentType: IToolbarButtonActiveContentType.MODAL,
      ActiveStateContent: memo(() =>
        WindowToolbarItemActiveContent(toolbarState, { appStreamSdk })
      ),
      ariaLabelCloseModal: t("toolbar.item.windows.content.close.ariaLabel"),
    };
  }

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.CONTAINER,
    id: ToolbarItemId.WINDOWS,
    label: t("toolbar.item.windows.label"),
    ariaLabel: t("toolbar.item.windows.ariaLabel"),
    icon: <WindowsIcon />,
    ActiveStateContent: memo((props: ToolbarActiveStateContentProps) => (
      <ToolbarContainerItemContent
        close={props.close}
        closeButtonAriaLabel={t("toolbar.item.windows.content.close.ariaLabel")}
        closeButtonTitle={t("toolbar.item.content.close.title")}
      >
        {WindowToolbarItemActiveContent(toolbarState, { appStreamSdk })}
      </ToolbarContainerItemContent>
    )),
  };
};
