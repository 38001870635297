// Copyright 2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";
import { ICredentials } from "@aws-amplify/core";
import { BrowserType } from "../../../../enums";
import { AWSError } from "aws-sdk";
import { StartStreamingResponse } from "@amzn/awserminecontrolplaneserviceinternal/clients/workspacesweb";
import log, { METRIC_NAME } from "../../../../logging";
import {
  getEndpoint,
  WorkSpacesWeb as ErmineControlPlaneServiceInternal,
} from "@amzn/awserminecontrolplaneserviceinternal";
import {
  DEFAULT_CLIENT_CONFIG,
  getEnvInfo,
  getPortalConfig,
} from "../../../../configurations";
import {
  getBrowserLanguage,
  getBrowserTimeZone,
  getDeepLink,
} from "../../../../utils";
import browser from "webextension-polyfill";

interface Props {
  onBrowserCreation: (
    browserType: BrowserType,
    endpoint: string,
    metricName: METRIC_NAME
  ) => void;
  onBrowserCreationError: (
    err: AWSError,
    metricName: METRIC_NAME,
    requestId: string
  ) => void;
  sigv4Credentials: ICredentials;
  cookies: browser.Cookies.Cookie[];
}

const Sigv4BrowserCreator = (props: Props): JSX.Element => {
  const {
    onBrowserCreation,
    onBrowserCreationError,
    sigv4Credentials,
    cookies,
  } = props;
  const { stage, region } = getEnvInfo();
  const portalConfig = getPortalConfig();
  const portalArn = portalConfig.portalArn;
  const serviceEndpoint = getEndpoint(
    stage,
    region,
    portalConfig.isFipsEnabled
  );

  useEffect(() => {
    const ermineService = new ErmineControlPlaneServiceInternal({
      ...DEFAULT_CLIENT_CONFIG,
      region: region,
      credentials: {
        accessKeyId: sigv4Credentials.accessKeyId,
        secretAccessKey: sigv4Credentials.secretAccessKey,
        sessionToken: sigv4Credentials.sessionToken,
      },
      endpoint: serviceEndpoint,
    });

    ermineService.startStreaming(
      {
        portalArn,
        sessionConfig: {
          supportedLanguage: getBrowserLanguage(),
          timeZone: getBrowserTimeZone(),
          deepLink: getDeepLink(),
        },
        cookies: cookies?.length ? JSON.stringify(cookies) : null,
      },
      function (err: AWSError, data: StartStreamingResponse) {
        if (err) {
          log.error(
            `Sigv4BrowserCreator ${err.statusCode} | ${err.code}: ${err.message}`
          );
          onBrowserCreationError(
            err,
            METRIC_NAME.START_STREAMING,
            this.requestId
          );
        } else {
          const { streamingUrl, browserType } = data;
          onBrowserCreation(
            browserType as BrowserType,
            streamingUrl,
            METRIC_NAME.START_STREAMING
          );
        }
      }
    );
  }, [
    portalArn,
    serviceEndpoint,
    onBrowserCreation,
    sigv4Credentials,
    region,
    stage,
    onBrowserCreationError,
    cookies,
  ]);

  return null;
};

export default Sigv4BrowserCreator;
