// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { AuthStatus } from "../../../../enums";
import log from "../../../../logging";
import { METRIC_NAME } from "../../../../logging";
import { ICredentials } from "@aws-amplify/core";

interface Props {
  onAuthCheck: (authState: AuthStatus, credentials?: ICredentials) => void;
}

const AuthChecker = (props: Props): JSX.Element => {
  const { onAuthCheck } = props;

  useEffect(() => {
    const getAuthState = async () => {
      try {
        log.publishCounterMetric(METRIC_NAME.COGNITO_CALL);
        const user = await Auth.currentAuthenticatedUser();
        window.userName = user.username;
        window.userSub = user.attributes["sub"];
        const credentials = await Auth.currentUserCredentials();
        log.publishCounterMetric(METRIC_NAME.COGNITO_LOGIN_SUCCESS);

        // Remove the refresh token immediately since it lasts a minimum of 1 hour
        sessionStorage.removeItem(
          Object.keys(user?.storage ?? {}).find((key) =>
            key?.includes("refreshToken")
          )
        );

        if ((window.location.pathname ?? "/") !== "/") {
          window.location.href = window.location.origin;
        }

        onAuthCheck(AuthStatus.SIGNED_IN, credentials);
      } catch (error) {
        if (error.code) {
          /*
            Auth.currentAuthenticatedUser() will throw an error when there is no authenticated user OR there is a
            server error. Using error.code we infer there was a server error.

            Relevant issue on github - https://github.com/aws-amplify/amplify-js/issues/5985
          */
          log.publishCounterMetric(METRIC_NAME.COGNITO_FAILURE);
        }
        onAuthCheck(AuthStatus.SIGNED_OUT);
      }
    };

    getAuthState();
  }, [onAuthCheck]);

  return null;
};

export default AuthChecker;
