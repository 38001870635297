// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React from "react";
import { Button } from "@amzn/awsui-components-react/polaris";
import { useTranslation } from "react-i18next";
import log from "../../../../logging";
import { getPortalEndpoint, isLocalhost } from "../../../../configurations";

const AuthButton = (): JSX.Element => {
  const { t } = useTranslation();

  const onClick = () => {
    log.logMessage("SignIn button clicked.");
    const portalEndpoint = getPortalEndpoint();

    if (isLocalhost()) {
      window.location.href = `${window.location.origin}/authenticate${window.location.search}`;
    } else {
      window.location.href = `${portalEndpoint}/authenticate${window.location.search}`;
    }
  };

  return (
    <Button className="Button-form" onClick={onClick}>
      <span id="span-text">{t("login.button.label")}</span>
    </Button>
  );
};

export default AuthButton;
