// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
import React, { useRef, useEffect } from "react";
import { isHardwareCompatible } from "../../../../utils/canvasUtils";

interface Props {
  onChromeNotCompatible: () => void;
}

const WebGLCanvas = (props: Props): JSX.Element => {
  const { onChromeNotCompatible } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    // https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
    if (canvas == null) return;
    if (!isHardwareCompatible(canvas)) {
      onChromeNotCompatible();
    }
  }, [onChromeNotCompatible]);

  return (
    <canvas
      data-testid="webgl-canvas"
      id="canvas"
      ref={canvasRef}
      width="0"
      height="0"
    ></canvas>
  );
};

export default WebGLCanvas;
