// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React, { useState } from "react";
import { AuthConfigurationStatus, AuthStatus } from "../../../../enums";
import { ICredentials } from "@aws-amplify/core";
import logo from "../../../../resources/WorkSpaces_Web_logo.png";
import { useTranslation } from "react-i18next";
import { Icon } from "@amzn/awsui-components-react";
import { AuthState, AuthStrategy } from "../../../../types";
import ErrorAlert from "../../../general/ErrorAlert";
import { ERROR_STATE } from "../../../../constants";
import WebGLCanvas from "../WebGLCanvas";
import LegalFooter from "../../../Footer";
import {
  shouldDisplayWebcamWarning,
  setBannerDeliveryWebcamWarningFlag,
} from "../../../../utils/bannerUtils";
import { getRebrandedTranslation } from "../../../../i18n";

interface Props {
  onSignIn: (authState: AuthState) => void;
  authStrategy: AuthStrategy;
  errorState?: Record<string, any>;
}

const SignInPage = (props: Props): JSX.Element => {
  const { onSignIn, authStrategy, errorState } = props;
  const { t } = useTranslation();

  const [authConfigurationStatus, setAuthConfigurationStatus] = useState(
    AuthConfigurationStatus.LOADING
  );
  const [authStatus, setAuthStatus] = useState(AuthStatus.LOADING);
  const [chromeCompatible, setChromeCompatible] = useState(true);
  const [renderAuthError, setRenderAuthError] = useState(
    errorState !== undefined
  );

  const onAuthConfigured = () => {
    setAuthConfigurationStatus(AuthConfigurationStatus.CONFIGURED);
  };

  const onAuthCheck = (
    currentAuthStatus: AuthStatus,
    credentials?: ICredentials
  ) => {
    setAuthStatus(currentAuthStatus);
    if (currentAuthStatus === AuthStatus.SIGNED_IN) {
      onSignIn({
        authStatus: currentAuthStatus,
        credentials,
      });
    }
  };

  if (authConfigurationStatus === AuthConfigurationStatus.LOADING) {
    return authStrategy.configure(onAuthConfigured);
  } else if (authStatus === AuthStatus.LOADING) {
    return authStrategy.checkAuth(onAuthCheck);
  }

  return (
    <div>
      <div className="awsui">
        {/* Banner for Webcam support on non-chromium browsers */}
        <ErrorAlert
          type={ERROR_STATE.WARNING}
          header={t("login.warningAlert.webcamIncompatibleWarn.header")}
          message={t("login.warningAlert.webcamIncompatibleWarn.message")}
          dismissible={true}
          onDismissErrorAlert={() => {
            setBannerDeliveryWebcamWarningFlag(true);
          }}
          visible={shouldDisplayWebcamWarning()}
        />
      </div>
      <div className="awsui">
        <ErrorAlert
          type={ERROR_STATE.WARNING}
          header={t("authentication.errorAlert.error.header")}
          message={errorState !== undefined ? errorState["errorMessage"] : ""}
          dismissible={true}
          onDismissErrorAlert={() => {
            setRenderAuthError(false);
          }}
          visible={renderAuthError}
        />
      </div>
      <div className="awsui On-top">
        <ErrorAlert
          type={ERROR_STATE.ERROR}
          header={getRebrandedTranslation(
            "login.errorAlert.chromeCompatibleError.header",
            t
          )}
          message={t("login.errorAlert.chromeCompatibleError.message")}
          dismissible={false}
          onDismissErrorAlert={() => {}}
          visible={!chromeCompatible}
        />
      </div>
      <WebGLCanvas onChromeNotCompatible={() => setChromeCompatible(false)} />
      <div
        className={
          "sign-in-wrapper awsui " +
          (!chromeCompatible ? "sign-in-wrapper-small-margin" : "")
        }
      >
        <div className="awsui-util-alert-ontop">
          {authStrategy.incomplete()}
        </div>
        <div className="awsui-util-help-panel">
          {/* auth button has text 3 */}
          <div className="awsui-util-container-header">
            <img alt="Amazon WorkSpaces Web" src={logo} />
            <br />
            <br />
            <p className="sign-in-to-workspacesweb">
              {getRebrandedTranslation("login.header", t)}
            </p>
            <br />
            {authStrategy.authButton()}
          </div>
          <p className="login-product-description">
            {getRebrandedTranslation("login.productDescription", t)}
          </p>
          <p className="login-learn-more">
            <a
              target="_blank"
              href={`${process.env.PUBLIC_URL}/LICENSE-THIRD-PARTY.txt`}
              rel="noreferrer"
            >
              {t("login.openSourceAttributions.link")}{" "}
              <Icon name={"external"} />
            </a>
          </p>
        </div>
      </div>
      <div>
        <LegalFooter
          privacyText={t("browser.footer.privacyPolicy.label")}
          siteTermText={t("browser.footer.siteTerms.label")}
          cookiePreferenceText={t("browser.footer.cookiePreferences.label")}
          awsAffiliatesText={t("browser.footer.awsAffiliates.label")}
          allRightsReservedText={t("browser.footer.allRightsReserved.label")}
        />
      </div>
    </div>
  );
};

export default SignInPage;
