import React from "react";
import {
  Icon,
  Link,
  SpaceBetween,
  TutorialPanelProps,
} from "@cloudscape-design/components";
import { Trans, useTranslation } from "react-i18next";
import { getDeviceBrowser } from "../../../utils/userAgentUtils";
import { ArrowUpIcon, PermissionIcon } from "../../icon";
import { useSkipTutorialStore } from "../../../hooks/useSkipTutorialStore";
import { ToolbarMode } from "../../../constants/Toolbar";
import { CustomHotspotIds } from "@amzn/aws-euc-ui";

export const useTutorial = (
  toolbarState: ToolbarMode
): TutorialPanelProps.Tutorial => {
  const { t } = useTranslation();
  const { setTutorialSkipped } = useSkipTutorialStore();

  const floatingChromeTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step1.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <SpaceBetween direction="vertical" size="s">
                  <Trans i18nKey={"toolbar.tutorial.step1.content"} />
                  <Link
                    variant="info"
                    onFollow={() => {
                      setTutorialSkipped(true);
                    }}
                  >
                    <Trans i18nKey={"toolbar.tutorial.step1.skipButton"} />
                  </Link>
                </SpaceBetween>
              </div>
            ),
            hotspotId: "hotspot1",
          },
          {
            title: t("toolbar.tutorial.step2.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step2.content"}>
                  {[<ArrowUpIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot2",
          },
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.chrome"}>
                  {[<PermissionIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot3",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot4",
          },
        ],
      },
    ],
  };

  const dockedChromeTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.chrome"}>
                  {[<PermissionIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot5",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: CustomHotspotIds.PREFERENCES,
          },
        ],
      },
    ],
  };

  const floatingEdgeTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step1.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <SpaceBetween direction="vertical" size="s">
                  <Trans i18nKey={"toolbar.tutorial.step1.content"} />
                  <Link
                    variant="info"
                    onFollow={() => {
                      setTutorialSkipped(true);
                    }}
                  >
                    <Trans i18nKey={"toolbar.tutorial.step1.skipButton"} />
                  </Link>
                </SpaceBetween>
              </div>
            ),
            hotspotId: "hotspot1",
          },
          {
            title: t("toolbar.tutorial.step2.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step2.content"}>
                  {[<ArrowUpIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot2",
          },
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.edge"}>
                  {[<PermissionIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot3",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot4",
          },
        ],
      },
    ],
  };

  const dockedEdgeTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.edge"}>
                  {[<PermissionIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot5",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: CustomHotspotIds.PREFERENCES,
          },
        ],
      },
    ],
  };

  const floatingSafariTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step1.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <SpaceBetween direction="vertical" size="s">
                  <Trans i18nKey={"toolbar.tutorial.step1.content"} />
                  <Link
                    variant="info"
                    onFollow={() => {
                      setTutorialSkipped(true);
                    }}
                  >
                    <Trans i18nKey={"toolbar.tutorial.step1.skipButton"} />
                  </Link>
                </SpaceBetween>
              </div>
            ),
            hotspotId: "hotspot1",
          },
          {
            title: t("toolbar.tutorial.step2.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step2.content"}>
                  {[<ArrowUpIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot2",
          },
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.safari"} />
              </div>
            ),
            hotspotId: "hotspot3",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot4",
          },
        ],
      },
    ],
  };

  const dockedSafariTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step3.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step3.content.safari"} />
              </div>
            ),
            hotspotId: "hotspot5",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: CustomHotspotIds.PREFERENCES,
          },
        ],
      },
    ],
  };

  const floatingFirefoxTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step1.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <SpaceBetween direction="vertical" size="s">
                  <Trans i18nKey={"toolbar.tutorial.step1.content"} />
                  <Link
                    variant="info"
                    onFollow={() => {
                      setTutorialSkipped(true);
                    }}
                  >
                    <Trans i18nKey={"toolbar.tutorial.step1.skipButton"} />
                  </Link>
                </SpaceBetween>
              </div>
            ),
            hotspotId: "hotspot1",
          },
          {
            title: t("toolbar.tutorial.step2.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step2.content"}>
                  {[<ArrowUpIcon />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot2",
          },
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: "hotspot4",
          },
        ],
      },
    ],
  };

  const dockedFirefoxTutorial = {
    title: null,
    description: null,
    completed: false,
    completedScreenDescription: null,
    tasks: [
      {
        title: null,
        steps: [
          {
            title: t("toolbar.tutorial.step4.title"),
            content: (
              <div style={{ fontSize: "12px" }}>
                <Trans i18nKey={"toolbar.tutorial.step4.content"}>
                  {[<Icon name="settings" />]}
                </Trans>
              </div>
            ),
            hotspotId: CustomHotspotIds.PREFERENCES,
          },
        ],
      },
    ],
  };

  const deviceBrowserType = getDeviceBrowser();
  switch (deviceBrowserType) {
    case "Chrome":
      return toolbarState === ToolbarMode.Docked
        ? dockedChromeTutorial
        : floatingChromeTutorial;
    case "Edge":
      return toolbarState === ToolbarMode.Docked
        ? dockedEdgeTutorial
        : floatingEdgeTutorial;
    case "Safari":
      return toolbarState === ToolbarMode.Docked
        ? dockedSafariTutorial
        : floatingSafariTutorial;
    case "Firefox":
      return toolbarState === ToolbarMode.Docked
        ? dockedFirefoxTutorial
        : floatingFirefoxTutorial;
    default:
      return toolbarState === ToolbarMode.Docked
        ? dockedChromeTutorial
        : floatingChromeTutorial;
  }
};
