import moment from "moment";
import React from "react";
import "../../styles/Footer.css";
import { cookieManagerInstance } from "../../cookies";

interface Props {
  privacyText?: string;
  privacyHref?: string;
  siteTermText?: string;
  siteTermHref?: string;
  cookiePreferenceText?: string;
  buildVersion?: string;
  contactHref?: string;
  allRightsReservedText?: string;
  awsAffiliatesText?: string;
}

const LegalFooter = (props: Props) => {
  const {
    privacyText,
    privacyHref,
    siteTermText,
    siteTermHref,
    cookiePreferenceText,
    contactHref,
    allRightsReservedText,
    awsAffiliatesText,
  } = props;

  const privacy = privacyText && (
    <span>
      <a id="privacy" href={privacyHref} target="_blank" rel="noreferrer">
        {privacyText}
      </a>
      <span className="footer-pipe">|</span>
    </span>
  );

  const siteTerm = siteTermText && (
    <span>
      <a id="terms-of-use" href={siteTermHref} target="_blank" rel="noreferrer">
        {siteTermText}
      </a>
      <span className="footer-pipe">|</span>
    </span>
  );
  const cookiePreferences = cookiePreferenceText && (
    <span>
      <a
        id="shortbread-footer-link"
        onClick={cookieManagerInstance.customizeCookies}
      >
        {cookiePreferenceText}
      </a>
      <span className="footer-pipe">|</span>
    </span>
  );

  const copyRightNotice = (
    <span id="copyright-notice">
      &copy;{" "}
      {`${moment("01/01/2008").format("YYYY")} - ${moment().format("YYYY")}`}{" "}
      {awsAffiliatesText} {allRightsReservedText}
    </span>
  );

  return (
    <footer id="page-footer" className="awsui-util-font-size-0">
      <span className="awsui-util-f-l">
        {privacy}
        {siteTerm}
        {cookiePreferences}
        {copyRightNotice}
      </span>

      <span className="awsui-util-f-r footer-right">
        <a href={contactHref} title={contactHref}>
          Contact us
        </a>
      </span>
    </footer>
  );
};

LegalFooter.defaultProps = {
  privacyText: "Privacy Policy",
  siteTermText: "Site Terms",
  cookiePreferenceText: "Cookie Preferences",
  allRightsReservedText: "All rights reserved.",
  awsAffiliatesText: "Amazon Web Services, Inc. or its affiliates.",
  contactHref: "https://aws.amazon.com/contact-us",
  privacyHref: "https://aws.amazon.com/privacy",
  siteTermHref: "https://aws.amazon.com/terms",
};

export default LegalFooter;
