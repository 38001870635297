// Copyright 2022 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import { ErmineServerSideRenderingService } from "@amzn/ermine-ssr-sdk";
import {
  GetUserRequest,
  GetUserResponse,
} from "@amzn/ermine-ssr-sdk/clients/ermineserversiderenderingservice";
import { AWSError } from "aws-sdk";
import { useEffect } from "react";
import { DEFAULT_CLIENT_CONFIG, getEnvInfo } from "../../../../configurations";
import { AuthStatus } from "../../../../enums";
import log from "../../../../logging";

interface Props {
  onAuthCheck: (authState: AuthStatus) => void;
}

const AuthChecker = (props: Props): JSX.Element => {
  const { onAuthCheck } = props;
  const { region } = getEnvInfo();

  useEffect(() => {
    const ermineService = new ErmineServerSideRenderingService({
      ...DEFAULT_CLIENT_CONFIG,
      region: region,
      endpoint: `${window.location.origin}`,
    });

    ermineService.makeUnauthenticatedRequest(
      "getUser",
      {} as GetUserRequest,
      (err: AWSError, data: GetUserResponse) => {
        if (err) {
          log.info(
            `SSOAuthChecker ${err.statusCode} | ${err.code}: ${err.message}`
          );
          onAuthCheck(AuthStatus.SIGNED_OUT);
        } else {
          const { username, userId } = data;
          window.userName = username;
          window.userSub = userId;
          onAuthCheck(AuthStatus.SIGNED_IN);
        }
      }
    );
  }, [onAuthCheck, region]);

  return null;
};

export default AuthChecker;
