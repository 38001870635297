import { SessionMetric } from "../types/appStream";
import { create } from "zustand";
import { MAX_METRIC_COUNT } from "../constants/Toolbar";

interface SessionMetricStore {
  latestMetric: SessionMetric | null;
  traffics: number[];
  addTrafficFromRawData: (metric: SessionMetric) => void;
  updateLatestMetric: (metric: SessionMetric) => void;
  clearMetrics: () => void;
}

const roundTo1Decimal = (value: number): number =>
  Math.round((value + Number.EPSILON) * 10) / 10;

export const useSessionMetricStore = create<SessionMetricStore>((set) => ({
  latestMetric: null,
  traffics: [0],
  addTrafficFromRawData: (metric) => {
    set((state) => ({
      ...state,
      traffics: [...state.traffics, roundTo1Decimal(metric.traffic)].slice(
        -MAX_METRIC_COUNT
      ),
      latestMetric: metric,
    }));
  },
  updateLatestMetric: (metric) => {
    set((state) => ({
      ...state,
      latestMetric: metric,
    }));
  },
  clearMetrics: () => {
    set((state) => ({
      ...state,
      latestMetric: null,
      traffics: [],
    }));
  },
}));
