// Copyright 2023 Amazon.com, Inc. or its affiliates. All Rights Reserved.

import React, { useState } from "react";
import { AuthConfigurationStatus, AuthStatus } from "../../../../enums";
import { ICredentials } from "@aws-amplify/core";
import logo from "../../../../resources/WSW_logo.png";
import wsbLogo from "../../../../resources/WSB_logo_white.png";
import mobile_logo from "../../../../resources/WorkSpaces_Web_logo.png";
import mobile_wsbLogo from "../../../../resources/WSB_logo_black.png";
import background from "../../../../resources/WSW_login_background.png";
import { useTranslation } from "react-i18next";
import { AuthState, AuthStrategy } from "../../../../types";
import { EUCErrorAlert } from "../../../general/ErrorAlert/EUCErrorAlert";
import { ERROR_STATE } from "../../../../constants";
import WebGLCanvas from "../WebGLCanvas";
import { BrandedHorizontalSplitLayout } from "@amzn/aws-euc-ui";
import EUCFooter from "../../../EUCSignInComponents/Footer";
import EUCHeader from "../../../EUCSignInComponents/Header";
import BrandedFooter from "../../../EUCSignInComponents/BrandedFooter";
import { Header } from "@cloudscape-design/components";
import useDisplayWebcamWarningFlag from "../../../../hooks/useDisplayWebcamWarningFlag";
import { useBreakpoint } from "@amzn/aws-euc-ui";
import "./style.css";
import { getRebrandedTranslation } from "../../../../i18n";
import { isFeatureEnabled } from "../../../../configurations";

interface Props {
  onSignIn: (authState: AuthState) => void;
  authStrategy: AuthStrategy;
  errorState?: Record<string, any>;
}

const desktopOrLaptopImageSource = `linear-gradient(131.3deg, rgb(85,178,135,0.9) -7.33%,rgb(32,88,118,0.9) 61.91%), url(${background})`;
const mobileOrTabletImageSource =
  "linear-gradient(159.41deg, rgba(255, 255, 255, 0.15) 45.12%, rgba(31, 115, 213, 0.15) 74.59%, rgba(85,178,135, 0.15) 96.27%), linear-gradient(197.81deg, rgba(255, 255, 255, 0.2) 45.72%, rgba(32,88,118, 0.2) 67.45%)";

const EUCSignInPage = (props: Props): JSX.Element => {
  const { onSignIn, authStrategy, errorState } = props;
  const { t } = useTranslation();

  const [authConfigurationStatus, setAuthConfigurationStatus] = useState(
    AuthConfigurationStatus.LOADING
  );
  const [authStatus, setAuthStatus] = useState(AuthStatus.LOADING);
  const [chromeCompatible, setChromeCompatible] = useState(true);
  const [renderAuthError, setRenderAuthError] = useState(
    errorState !== undefined
  );

  const [
    displayWebcamWarningFlag,
    updateDisplayWebcamWarningFlag,
  ] = useDisplayWebcamWarningFlag();

  const { isDesktopOrLaptop } = useBreakpoint();

  const onAuthConfigured = () => {
    setAuthConfigurationStatus(AuthConfigurationStatus.CONFIGURED);
  };

  const onAuthCheck = (
    currentAuthStatus: AuthStatus,
    credentials?: ICredentials
  ) => {
    setAuthStatus(currentAuthStatus);
    if (currentAuthStatus === AuthStatus.SIGNED_IN) {
      onSignIn({
        authStatus: currentAuthStatus,
        credentials,
      });
    }
  };

  if (authConfigurationStatus === AuthConfigurationStatus.LOADING) {
    return authStrategy.configure(onAuthConfigured);
  } else if (authStatus === AuthStatus.LOADING) {
    return authStrategy.checkAuth(onAuthCheck);
  }

  const isRebrandFeatureFlagEnabled = isFeatureEnabled("rebrand");
  const desktopLogoToUse = isRebrandFeatureFlagEnabled ? wsbLogo : logo;
  const mobileLogoToUse = isRebrandFeatureFlagEnabled
    ? mobile_wsbLogo
    : mobile_logo;

  return (
    <div className="euc-container">
      <BrandedHorizontalSplitLayout
        brandLogoSrc={isDesktopOrLaptop && desktopLogoToUse}
        backgroundImageSrc={
          isDesktopOrLaptop
            ? desktopOrLaptopImageSource
            : mobileOrTabletImageSource
        }
        brandLogoAlt={getRebrandedTranslation("euc.signIn.logo.alt", t)}
        brandDescription={t("euc.signIn.product.description")}
        footerComponent={<EUCFooter />}
        headerComponent={<EUCHeader />}
        brandFooterComponent={<BrandedFooter />}
      >
        <div className="euc-main-container-style">
          <div
            className={
              isDesktopOrLaptop
                ? "euc-sign-in-parent-container-style"
                : "euc-sign-in-parent-container-mobile-style"
            }
          >
            {/* Banner for Webcam support on non-chromium browsers */}
            <EUCErrorAlert
              type={ERROR_STATE.WARNING}
              message={t(
                "euc.signIn.warningAlert.webcamIncompatibleWarn.message"
              )}
              onDismissErrorAlert={() => {
                updateDisplayWebcamWarningFlag(true);
              }}
              visible={!authStrategy.incomplete() && displayWebcamWarningFlag}
            />

            <EUCErrorAlert
              type={ERROR_STATE.ERROR}
              header={t("authentication.errorAlert.error.header")}
              message={
                errorState !== undefined ? errorState["errorMessage"] : ""
              }
              dismissible={true}
              onDismissErrorAlert={() => {
                setRenderAuthError(false);
              }}
              visible={renderAuthError}
            />

            <EUCErrorAlert
              type={ERROR_STATE.ERROR}
              message={t("euc.signIn.errorAlert.chromeCompatibleError.message")}
              dismissible={false}
              onDismissErrorAlert={() => {}}
              visible={!authStrategy.incomplete() && !chromeCompatible}
            />

            <WebGLCanvas
              onChromeNotCompatible={() => setChromeCompatible(false)}
            />
            <div className="euc-sign-in-container-style">
              <div className="euc-sign-in-container-child-style">
                {!isDesktopOrLaptop && (
                  <img
                    src={mobileLogoToUse}
                    className="euc-sign-in-container-mobile-brand-logo-style"
                  />
                )}
                {authStrategy.incomplete() ? (
                  <div className="euc-incomplete-header">
                    <Header
                      description={t(
                        "euc.signIn.portalStatus.alert.incomplete.message"
                      )}
                    >
                      {t("euc.signIn.portalStatus.alert.incomplete.header")}
                    </Header>
                  </div>
                ) : (
                  <>
                    <div className="euc-sign-in-header">
                      <Header
                        description={getRebrandedTranslation(
                          "euc.signIn.header.description",
                          t
                        )}
                      >
                        {t("euc.signIn.header.label")}
                      </Header>
                    </div>
                    <div className="euc-btn-container-style">
                      {authStrategy.authButton()}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </BrandedHorizontalSplitLayout>
    </div>
  );
};

export default EUCSignInPage;
