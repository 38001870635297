import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
  useToolbarContentItemIdStore,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import React, { useState } from "react";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import {
  NotificationId,
  ToolbarItemId,
  ToolbarPreferenceTabId,
} from "../../../constants/Toolbar";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";
import { useMediaDeviceStore } from "../../../hooks/useMediaDeviceStore";
import useMicrophoneDevices from "../../../hooks/useMicrophoneDevices";
import { useToolbarPreferenceTabIdStore } from "../../../hooks/useToolbarPreferenceTabIdStore";
import "../toolbar.css";
import { Box } from "@cloudscape-design/components";

export const useMicrophoneDropdown = (
  appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
  const { t } = useTranslation();
  const microphoneEnabled = useToolbarPreferenceStore(
    (store) => store.microphoneEnabled
  );
  const setMicrophoneEnabled = useToolbarPreferenceStore(
    (store) => store.setMicrophoneEnabled
  );

  const selectedMicrophone = useMediaDeviceStore(
    (store) => store.selectedMicrophone
  );
  const setSelectedMicrophone = useMediaDeviceStore(
    (store) => store.setSelectedMicrophone
  );

  const [loading, setLoading] = useState(false);

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const microphoneNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotEnabled.header"),
    content: t("notification.microphoneNotEnabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_ENABLED_ERROR,
  });

  const microphoneNotDisabledError = useFloatingAndToolbarNotification({
    header: t("notification.microphoneNotDisabled.content"),
    notificationId: NotificationId.MICROPHONE_NOT_DISABLED_ERROR,
  });

  const openToolbarItem = useToolbarContentItemIdStore(
    (store) => store.setExpandedItemId
  );
  const openPreferenceTab = useToolbarPreferenceTabIdStore(
    (store) => store.setActiveId
  );

  const microphones = useMicrophoneDevices(appStreamEmbed);

  // Microphone devices
  const micOptions = microphones
    .filter((mic) => mic.deviceId !== "")
    .map((option) => ({
      name: option.label,
      value: option.deviceId,
      checked: option.deviceId === selectedMicrophone?.deviceId,
      onClick: async () => {
        if (microphoneEnabled) {
          try {
            await appStreamEmbed.performAction(
              AppStreamEmbedConstant.METHOD_SET_MICROPHONE,
              {
                microphoneEnabled: microphoneEnabled,
                microphoneDeviceId: option.deviceId,
              }
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_EXPANDED_VIEW_SUCCESS
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SELECT_MICROPHONE_DEVICE_SUCCESS
            );
            setSelectedMicrophone({
              label: option.label,
              deviceId: option.deviceId,
            });
          } catch {
            addFloatingNotification(
              microphoneNotEnabledError.floatingToolbarNotification
            );
            addToolbarNotification(
              microphoneNotEnabledError.toolbarNotification
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_ERROR
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SELECT_MICROPHONE_DEVICE_ERROR
            );
          }
        } else {
          setSelectedMicrophone({
            label: option.label,
            deviceId: option.deviceId,
          });
        }
      },
    }));

  const enableDisableOptions = [
    {
      name: t("toolbar.mediaDevice.disable.yes"),
      value: true,
      checked: !microphoneEnabled,
      onClick: async () => {
        // call appstreamEmbed with disable
        try {
          await appStreamEmbed.performActionPromise(
            AppStreamEmbedConstant.METHOD_SET_MICROPHONE,
            {
              microphoneEnabled: false,
            }
          );
          setMicrophoneEnabled(false);
        } catch {
          addFloatingNotification(
            microphoneNotDisabledError.floatingToolbarNotification
          );
          addToolbarNotification(
            microphoneNotDisabledError.toolbarNotification
          );
          log.publishCounterMetric(
            TOOLBAR_METRIC_NAME.DISABLE_MICROPHONE_ERROR
          );
        }
      },
    },
    {
      name: t("toolbar.mediaDevice.disable.no"),
      value: false,
      checked: microphoneEnabled,
      onClick: async () => {
        // call appstream embed with enable and deviceId from store
        try {
          await appStreamEmbed.performActionPromise(
            AppStreamEmbedConstant.METHOD_SET_MICROPHONE,
            selectedMicrophone
              ? {
                  microphoneEnabled: !microphoneEnabled,
                  microphoneDeviceId: selectedMicrophone.deviceId,
                }
              : {
                  microphoneEnabled: !microphoneEnabled,
                }
          );
          setMicrophoneEnabled(true);
        } catch {
          addFloatingNotification(
            microphoneNotEnabledError.floatingToolbarNotification
          );
          addToolbarNotification(microphoneNotEnabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.ENABLE_MICROPHONE_ERROR);
        }
      },
    },
  ];

  const selectGroups = [
    {
      label: t("toolbar.item.selectMenu.microphone.label"),
      items: micOptions,
      empty: (
        <Box margin={{ bottom: "s", left: "xl" }}>
          {t("toolbar.mediaDevice.emptyDeviceList.message")}
        </Box>
      ),
    },
    {
      label: t("toolbar.item.selectMenu.disableMicrophoneLabel"),
      items: enableDisableOptions,
      empty: undefined,
    },
  ];

  const menuItems = [
    {
      name: t("toolbar.item.selectMenu.audioVideoSettings"),
      onClick: () => {
        // Opens Preferences: Audio & Video tab upon clicking
        openToolbarItem(ToolbarItemId.PREFERENCES);
        openPreferenceTab(ToolbarPreferenceTabId.AUDIO_VIDEO);
      },
    },
  ];

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.SELECTABLE_DROPDOWN,
    isLoading: loading,
    testId: "toolbar-dropdown-microphone",
    icon: (
      <>
        <Icon data-testid={"angle-down"} name={"angle-down"} size="inherit" />
      </>
    ),
    selectGroups: selectGroups,
    menuItems: menuItems,
  };
};
