import { AppStreamEmbedConstant } from "../constants";
import { SessionErrorType } from "../types/appStream";

interface AppStreamEmbedErrorEvent {
  errorCode: number;
  errorTitle: string;
  errorMessage: string;
}

// Those error titles are from https://code.amazon.com/packages/PhotonPortalAssets/blobs/mainline/--/photon/app/scripts/commons/constants.ts
export enum AppStreamErrorTitle {
  DISCONNECT_WARNING = "Lost connectivity due to inactivity",
  RESERVE_SESSION_ERROR = "Failed to reserve a session",
  NO_APPLICATION_AVAILABLE = "No applications available",
  CONNECTION_ERROR = "Connection error",
  CONNECTION_PROBLEM = "Connection problem",
  DEPLOY_APPLICATION_ERROR = "There was an issue deploying your apps",
}

export const convertErrorTypeFromEmbedEvent = (
  event: AppStreamEmbedErrorEvent
): SessionErrorType => {
  const errorTitle = event.errorTitle;
  //this is workaround to detect the no instances available error while we figure out the V2 error code approach.
  if (
    event.errorCode === 404 &&
    event.errorMessage.indexOf("Code: 404") !== -1 &&
    event.errorMessage.indexOf("ctrl/get-instance") !== -1
  ) {
    return "InstancesUnavailableError";
  }

  switch (errorTitle) {
    case AppStreamErrorTitle.DISCONNECT_WARNING:
      return "DisconnectWarning";
    case AppStreamErrorTitle.RESERVE_SESSION_ERROR:
      return "ReserveSessionError";
    case AppStreamErrorTitle.NO_APPLICATION_AVAILABLE:
      return "NoApplicationError";
    case AppStreamErrorTitle.CONNECTION_ERROR:
      return "ConnectionError";
    case AppStreamErrorTitle.CONNECTION_PROBLEM:
      return "ConnectionError";
    case AppStreamErrorTitle.DEPLOY_APPLICATION_ERROR:
      return "DeployApplicationError";
    // If error title is empty, will not show error modal.
    case "":
      return "None";
    // All other cases will show the generic connection error.
    default:
      return "ConnectionError";
  }
};

export enum ToolbarVersion {
  APPSTREAM = "AppStream",
  WORKSPACES_WEB = "WorkSpacesWeb",
}

// Get which toolbar version customer is using based on user interface config: AppStream toolbar or WorkSpacesWeb toolbar
// If no hidden element, return null (which indicates this event is not related to toolbar version)
export const getToolbarVersionFromUserInterface = (event: any): string => {
  const hiddenElements = event.hidden;
  if (hiddenElements) {
    if (hiddenElements?.includes(AppStreamEmbedConstant.ELEMENT_TOOLBAR_MAIN)) {
      return ToolbarVersion.WORKSPACES_WEB;
    }
    return ToolbarVersion.APPSTREAM;
  }
  return null;
};
