import { z } from "zod";
import { AppStreamEmbedConstant } from "../constants";
import { Mode } from "@cloudscape-design/global-styles";
import { ToolbarMode } from "../constants/Toolbar";

/**
 * Schema for toolbar settings cookie.
 * We will validate against this schema on ingestion and
 * invalidate the cookie if it does not have valid schema.
 */
export const ToolbarSettingsCookieSchema = z.object({
  visualMode: z.nativeEnum(Mode).optional(),
  toolbarMode: z.nativeEnum(ToolbarMode).optional(),
  streamingMode: z.nativeEnum(AppStreamEmbedConstant.StreamingMode).optional(),
  displayResolution: z
    .nativeEnum(AppStreamEmbedConstant.Resolutions)
    .optional(),
  showFunctionKeysToolbarItem: z.boolean().optional(),
  useOptionKeyAsAltKey: z.boolean().optional(),
  useCommandAsControlKey: z.boolean().optional(),
  toolbarIconLabelsEnabled: z.boolean().optional(),
});

/**
 * Derived type for the wsw-toolbar-settings cookie
 */
export type ToolbarSettingsCookie = z.infer<typeof ToolbarSettingsCookieSchema>;
