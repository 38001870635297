import React, { useState } from "react";
import {
  IToolbarButtonActiveContentType,
  IToolbarButtonProps,
  IToolbarButtonType,
  useToolbarContentItemIdStore,
} from "@amzn/aws-euc-ui";
import Icon from "@cloudscape-design/components/icon";
import { AppStreamSDK } from "../../../utils/AppStreamSDK";
import { AppStreamEmbedConstant } from "../../../constants";
import { useTranslation } from "react-i18next";
import { useToolbarPreferenceStore } from "../../../hooks/useToolbarPreferenceStore";
import {
  useNotificationStore,
  useNotificationToolbarItemStore,
} from "../../../hooks/useNotificationStore";
import { useFloatingAndToolbarNotification } from "./notification/useNotification";
import {
  NotificationId,
  ToolbarItemId,
  ToolbarPreferenceTabId,
} from "../../../constants/Toolbar";
import log, { TOOLBAR_METRIC_NAME } from "../../../logging";
import { useMediaDeviceStore } from "../../../hooks/useMediaDeviceStore";
import { useToolbarPreferenceTabIdStore } from "../../../hooks/useToolbarPreferenceTabIdStore";
import useCameraDevices from "../../../hooks/useCameraDevices";
import { Box } from "@cloudscape-design/components";

export const useCameraDropdown = (
  appStreamEmbed: AppStreamSDK
): IToolbarButtonProps => {
  const { t } = useTranslation();
  const webcamEnabled = useToolbarPreferenceStore(
    (store) => store.webcamEnabled
  );
  const setWebcamEnabled = useToolbarPreferenceStore(
    (store) => store.setWebcamEnabled
  );

  const selectedWebcam = useMediaDeviceStore((store) => store.selectedWebcam);
  const setSelectedWebcam = useMediaDeviceStore(
    (store) => store.setSelectedWebcam
  );

  const addFloatingNotification = useNotificationStore(
    (store) => store.addNotification
  );

  const addToolbarNotification = useNotificationToolbarItemStore(
    (store) => store.addNotification
  );

  const cameraNotEnabledError = useFloatingAndToolbarNotification({
    header: t("notification.cameraNotEnabled.header"),
    content: t("notification.cameraNotEnabled.content"),
    notificationId: NotificationId.CAMERA_NOT_ENABLED_ERROR,
  });

  const cameraNotDisabledError = useFloatingAndToolbarNotification({
    header: t("notification.cameraNotDisabled.content"),
    notificationId: NotificationId.CAMERA_NOT_DISABLED_ERROR,
  });

  const [loading, setLoading] = useState(false);

  const openToolbarItem = useToolbarContentItemIdStore(
    (store) => store.setExpandedItemId
  );
  const openPreferenceTab = useToolbarPreferenceTabIdStore(
    (store) => store.setActiveId
  );

  const cameras = useCameraDevices(appStreamEmbed);

  // Camera devices
  const cameraOptions = cameras
    .filter((camera) => camera.deviceId !== "")
    .map((option) => ({
      name: option.label,
      value: option.deviceId,
      checked: option.deviceId === selectedWebcam?.deviceId,
      onClick: async () => {
        if (webcamEnabled) {
          try {
            // first turn off the previous selected camera
            await appStreamEmbed.performActionPromise(
              AppStreamEmbedConstant.METHOD_SET_WEBCAM,
              {
                webcamEnabled: false,
              }
            );
            // then enable the selected camera
            await appStreamEmbed.performActionPromise(
              AppStreamEmbedConstant.METHOD_SET_WEBCAM,
              {
                webcamEnabled: true,
                webcamDeviceId: option.deviceId,
              }
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_EXPANDED_VIEW_SUCCESS
            );
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SELECT_WEBCAM_DEVICE_SUCCESS
            );
            setSelectedWebcam({
              label: option.label,
              deviceId: option.deviceId,
            });
          } catch {
            addFloatingNotification(
              cameraNotEnabledError.floatingToolbarNotification
            );
            addToolbarNotification(cameraNotEnabledError.toolbarNotification);
            log.publishCounterMetric(TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_ERROR);
            log.publishCounterMetric(
              TOOLBAR_METRIC_NAME.SELECT_WEBCAM_DEVICE_ERROR
            );
          }
        } else {
          setSelectedWebcam({ label: option.label, deviceId: option.deviceId });
        }
      },
    }));

  const enableDisableOptions = [
    {
      name: t("toolbar.mediaDevice.disable.yes"),
      value: true,
      checked: !webcamEnabled,
      onClick: async () => {
        try {
          await appStreamEmbed.performActionPromise(
            AppStreamEmbedConstant.METHOD_SET_WEBCAM,
            {
              webcamEnabled: false,
            }
          );
          setWebcamEnabled(false);
        } catch {
          addFloatingNotification(
            cameraNotDisabledError.floatingToolbarNotification
          );
          addToolbarNotification(cameraNotDisabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.DISABLE_WEBCAM_ERROR);
        }
      },
    },
    {
      name: t("toolbar.mediaDevice.disable.no"),
      value: false,
      checked: webcamEnabled,
      onClick: async () => {
        try {
          await appStreamEmbed.performActionPromise(
            AppStreamEmbedConstant.METHOD_SET_WEBCAM,
            selectedWebcam
              ? {
                  webcamEnabled: !webcamEnabled,
                  webcamDeviceId: selectedWebcam.deviceId,
                }
              : {
                  webcamEnabled: !webcamEnabled,
                }
          );
          setWebcamEnabled(true);
        } catch {
          addFloatingNotification(
            cameraNotEnabledError.floatingToolbarNotification
          );
          addToolbarNotification(cameraNotEnabledError.toolbarNotification);
          log.publishCounterMetric(TOOLBAR_METRIC_NAME.ENABLE_WEBCAM_ERROR);
        }
      },
    },
  ];

  const selectGroups = [
    {
      label: t("toolbar.item.selectMenu.camera.label"),
      items: cameraOptions,
      empty: (
        <Box margin={{ bottom: "s", left: "xl" }}>
          {t("toolbar.mediaDevice.emptyDeviceList.message")}
        </Box>
      ),
    },
    {
      label: t("toolbar.item.selectMenu.disableCameraLabel"),
      items: enableDisableOptions,
      empty: undefined,
    },
  ];

  const menuItems = [
    {
      name: t("toolbar.item.selectMenu.audioVideoSettings"),
      onClick: () => {
        // Opens Preferences: Audio & Video tab upon clicking
        openToolbarItem(ToolbarItemId.PREFERENCES);
        openPreferenceTab(ToolbarPreferenceTabId.AUDIO_VIDEO);
      },
    },
  ];

  return {
    type: IToolbarButtonType.STATEFUL,
    activeStateContentType: IToolbarButtonActiveContentType.SELECTABLE_DROPDOWN,
    isLoading: loading,
    testId: "toolbar-dropdown-camera",
    icon: (
      <Icon data-testid={"angle-down"} name={"angle-down"} size="inherit" />
    ),
    selectGroups: selectGroups,
    menuItems: menuItems,
  };
};
