import { CookieSyncSupportedBrowserType } from "../types/cookies";

export const CookieSyncExtensionLinks: {
  [k in CookieSyncSupportedBrowserType]: string;
} = {
  Chrome:
    "https://chromewebstore.google.com/detail/amazon-workspaces-secure/mfmlbfjmlojmpkgoilfnfeefmgigdjjj",
  Edge:
    "https://chromewebstore.google.com/detail/amazon-workspaces-secure/mfmlbfjmlojmpkgoilfnfeefmgigdjjj",
  Firefox:
    "https://extension.workspaces-web.com/firefox/AmazonWorkSpacesWeb.xpi",
};

// TODO: Update doc link once ready https://sim.amazon.com/issues/LOWA-12464
export const CookieSyncAdminGuideUrl =
  "https://docs.aws.amazon.com/workspaces-web/latest/adminguide/user-guide";
