import browser from "webextension-polyfill";
import { getDeviceBrowser } from "../../utils/userAgentUtils";
import {
  ExtensionResponse,
  GetCookies,
} from "@amzn/aws-low-angle-cookie-synchronizer";
import log, { METRIC_NAME } from "../../logging";
import { CookieSyncSupportedBrowserType } from "../../types/cookies";

export type CookieSynchronizationError =
  | "Timeout"
  | "AuthenticationError"
  | "ErrorResponse"
  | "InvalidResponse";

export const getCookiesWithTimeout = async (
  request: GetCookies,
  timeoutMs: number
): Promise<
  | {
      type: "cookies";
      cookies: browser.Cookies.Cookie[];
    }
  | {
      type: "error";
      error: CookieSynchronizationError;
    }
> => {
  const t0 = performance.now();
  try {
    const cookies = await Promise.race([
      getCookies(request),
      timeoutAfter(timeoutMs),
    ]);
    const t1 = performance.now();
    log.publishNumericMetric(
      METRIC_NAME.COOKIE_EXTENSION_RESPONSE_TIME,
      t1 - t0
    );
    log.publishHttpStatusMetric(METRIC_NAME.COOKIE_EXTENSION_RESPONSE, 200);
    return {
      type: "cookies",
      cookies,
    };
  } catch (error) {
    const t1 = performance.now();
    log.publishNumericMetric(
      METRIC_NAME.COOKIE_EXTENSION_RESPONSE_TIME,
      t1 - t0
    );
    log.publishHttpStatusMetric(METRIC_NAME.COOKIE_EXTENSION_RESPONSE, 500);
    return {
      type: "error",
      error,
    };
  }
};

export const getCookies = async (
  request: GetCookies
): Promise<browser.Cookies.Cookie[]> => {
  const browser = getDeviceBrowser() as CookieSyncSupportedBrowserType;
  switch (browser) {
    case "Edge":
    case "Chrome": {
      return requestCookiesPostMessage(request);
    }
    case "Firefox": {
      return requestCookiesPostMessage(request, (cookie) => {
        // Firefox cookie store IDs look like `firefox-default`
        // which differs from what Chrome expects
        cookie.storeId = "0";
        // Firefox only has 3 different SameSite values, but Chrome has 4.
        // The spec for SameSite says that "if SameSite=None then the Secure attribute must also be set".
        // Because of this, we must translate Firefox's "no_restriction" to Chrome's "unspecified".
        // Firefox SameSite: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/cookies/SameSiteStatus
        // Chrome SameSite: https://developer.chrome.com/docs/extensions/reference/cookies/#type-SameSiteStatus
        if (!cookie.secure && cookie.sameSite === "no_restriction") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (cookie as any).sameSite = "unspecified";
        }
        return cookie;
      });
    }
    default: {
      // The SSR tests manipulate the userAgent in a browser with the
      // extension installed. In general, since we can't guarantee cookie
      // sync to function properly in what could actually be a different
      // browser with a manipulated userAgent, we just return no cookies.
      log.logMessage(
        `Cookie sync not supported in ${browser} | ${window.navigator?.userAgent}`
      );
      return Promise.resolve([]);
    }
  }
};

export const isCookieSynchronizationExtensionInstalled = (): boolean => {
  return Boolean(
    document.documentElement.getAttribute(
      "cookie-synchronization-extension-installed"
    )
  );
};

function timeoutAfter(timeoutMs: number): Promise<never> {
  return new Promise(
    (_resolve, reject: (error: CookieSynchronizationError) => void) => {
      setTimeout(() => {
        reject("Timeout");
      }, timeoutMs);
    }
  );
}

function requestCookiesPostMessage(
  request: GetCookies,
  cookieModifier?: (cookie: browser.Cookies.Cookie) => browser.Cookies.Cookie
) {
  return new Promise<browser.Cookies.Cookie[]>(
    (resolve, reject: (error: CookieSynchronizationError) => void) => {
      window.addEventListener("message", async function (event) {
        if ((event.data as ExtensionResponse)?.responseType) {
          handleExtensionResponse(
            event.data as ExtensionResponse,
            resolve,
            reject,
            cookieModifier
          );
        }
      });
      window.postMessage(request, "*");
    }
  );
}

function handleExtensionResponse(
  response: ExtensionResponse,
  resolve: (result: browser.Cookies.Cookie[]) => void,
  reject: (error: CookieSynchronizationError) => void,
  cookieModifier?: (cookie: browser.Cookies.Cookie) => browser.Cookies.Cookie
) {
  switch (response.responseType) {
    case "getCookies": {
      resolve(
        cookieModifier ? response.cookies.map(cookieModifier) : response.cookies
      );
      break;
    }
    case "error": {
      log.error(
        `Error occurred in extension: ${response.errorType} | ${response.error}`
      );
      switch (response.errorType) {
        case "getCookies": {
          reject("ErrorResponse");
          break;
        }
        case "authentication": {
          reject("AuthenticationError");
          break;
        }
        case undefined: {
          // This case exists for backwards compatibility and can be removed
          // after the extension is deployed and starts returning this field.
          reject("ErrorResponse");
          break;
        }
      }
      break;
    }
    default: {
      log.error(`Unexpected extension response: ${JSON.stringify(response)}`);
      reject("InvalidResponse");
    }
  }
}
